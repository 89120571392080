import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from "react-hook-form";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import BitsInfoTable from './BitsInfoTable';
import { types } from '../../../constants/G5MessagesTypes';

const useStyles = makeStyles({
  root: {
    // padding: '0 4px',
    // display: 'grid',
    // gridTemplateColumns: 'auto auto',
  },
  formContainer: {
    margin: '5px 0',
    backgroundColor: 'skyblue',
    padding: '4px',
    width: '100%'
  },
  form: {
    '& label': {
      margin: '0 3px 0 10px'
    },
    '& input[type="number"]': {
      width: '80px',
    },
    '& input[type="text"]': {
      width: '80px',
    }
  },
});

function AssamblyLayout(props, context) {
  // const [selectedDevices, setSelectedDevices] = React.useState({});
  const { selectedItems } = props;
  const classes = useStyles();

  const { register, handleSubmit } = useForm();

  // function setDeviceChecked(item) {
  //   return (event) => {
  //     const checked = event.target.checked;
  //     const checkedDevicesDictionary = {...selectedDevices};
  //     checkedDevicesDictionary[item.DeviceID] = checked;
  //     setSelectedDevices(checkedDevicesDictionary);
  //   };
  // }

  function sendSchedulerMessageToDevices(formData) {
    // const devicesList = [];
    // Object.entries(selectedDevices).forEach((entry) => {
    //   if (entry[1]) {
    //     devicesList.push(entry[0]);
    //   }
    // });
    // if (devicesList.length === 0) {
    //   console.log('NO DEVICES TO WORK WITH');
    // } else {
    //   props.selectMultipleDevices(devicesList);
    //   props.updateConfig(formData, types.Scheduler, props.notify);
    // }

    if (selectedItems.length === 0) {
      // console.log('NO DEVICES TO WORK WITH');
    } else {
      props.selectMultipleDevices(selectedItems);
      props.updateConfig(formData, types.Scheduler, props.notify);
    }
  }

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item classes={{root: classes.formContainer}}>
        <form className={classes.form} onSubmit={handleSubmit(sendSchedulerMessageToDevices)}>
          <label>{context.t('corr_interval')}</label>
          <input ref={register} name='corrInterval' type='number' defaultValue='5' />
          <label>{context.t('corr_num_samples')}</label>
          <input ref={register} name='corrNumSamples' type='number' defaultValue='3' />
          <label>{context.t('noise_interval')}</label>
          <input ref={register} name='noiseInterval' type='number' defaultValue='5' />
          <label>{context.t('noise_num_samples')}</label>
          <input ref={register} name='noiseNumSamples' type='number' defaultValue='3' />
          <label>{context.t('noise_tx_time')}</label>
          <input ref={register} name='noiseTxTime' type='text' defaultValue='2' />
          <label>{context.t('ref_time')}</label>
          <input ref={register} name='refTime' type='time' defaultValue='00:00' />
          <Button
            variant='contained'
            color='primary'
            type='submit'
            style={{float: 'right'}}
          >{context.t('send_scheduler_message')}</Button>
        </form>
      </Grid>

      <Grid item>
        <BitsInfoTable
          items={props.bitsInfo}
          local={props.timeZone}
          selectedItems={props.selectedItems}
          reloadDevices={props.reloadDevices}
          // selectedDevices={selectedDevices}
          // setDeviceChecked={setDeviceChecked}
          // setSelectedDevices={setSelectedDevices}
        />
      </Grid>
    </Grid>
  );
}

AssamblyLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

AssamblyLayout.propTypes = {
  bitsInfo: PropTypes.array.isRequired,
  notify: PropTypes.func,
  reloadDevices: PropTypes.func.isRequired,
  selectMultipleDevices: PropTypes.func,
  selectedItems: PropTypes.array,
  timeZone: PropTypes.string,
  updateConfig: PropTypes.func,
  // selectedItems: PropTypes.array.isRequired,
};

export default AssamblyLayout;
