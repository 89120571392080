import PropTypes from 'prop-types';
import React from 'react';
import TabsContainer from '../Custom/Tabs/TabsContainer';

import AdvancedGraphs from './AdvancedGraphs';
import AnalysisDetails from './AnalysisDetails';
import OutputInfo from './OutputInfo';
import PreviousGraps from './PreviousGraps';
import Signals from './Signals';

export default function SampleAdvancedInfoTabs(props, context) {
  const advancedGraphsClass = props.show
    ? 'advanced-graphs show'
    : 'advanced-graphs';
  const tabs = [
    {
      style: { height: 'inherit' },
      label: context.t('previous_samples'),
      component: (
        <PreviousGraps
          displayType={props.displayType}
          bias={props.bias}
          historyData={props.historyData}
          axisDistances={props.axisDistances}
          graphParams={props.graphParams}
          graphsDistancesForAxis={props.graphsDistancesForAxis}
          historyNoiseData={props.historyNoiseData}
          timeZone={props.timeZone}
          correlationAve={props.correlationAve}
          histogramAve={props.histogramAve}
        />
      ),
    },
    // {
    //   style: { height: 'inherit' },
    //   label: context.t('Audio'),
    //   component: (
    //     <div>
    //       <h1>Audio</h1>
    //       {props.audioData[0] &&
    //         // <AudioComponent lable={'audio lable'} audioSrc={props.audioData[0].waveUrl}/>
    //         <AudioView audioSrc={props.audioData[0].waveUrl} />
    //       }
    //     </div>
    //   )}
  ];

  if (props.user.analysisExtends) {
    tabs.push(
      {
        style: { height: '46vh', overflowY: 'auto' },
        label: context.t('advanced'),
        component: (
          <AdvancedGraphs
            maxProbabilityGraphs={props.maxProbabilityGraphs}
            graphParams={props.graphParams}
            distancesForAxis={props.graphsDistancesForAxis}
            maxProbabilityPositions={props.maxProbabilityPositions}
            sensorsPositions={props.sensorsPositions}
            metaData={props.rawData}
          />
        ),
      },
      {
        style: { height: 'inherit' },
        label: context.t('signals'),
        component: (
          <Signals
            radio={props.rawData.radio_cor_sliced_data}
            dual={{
              a: props.rawData.sliced_intensity_sig1_raw,
              b: props.rawData.sliced_intensity_sig2_raw,
            }}
            correlationInstance={props.correlationInstance}
            histogramInstance={props.histogramInstance}
            metaData={props.rawData}
            graphParams={props.graphParams}
            distancesForAxis={props.graphsDistancesForAxis}
            maxProbabilityPositions={props.maxProbabilityPositions}
            sensorsPositions={props.sensorsPositions}
          />
        ),
      },
      {
        style: { height: 'inherit' },
        label: context.t('details'),
        component: (
          <OutputInfo
            {...props.rawData}
            tensor={props.analysisData?.[0]}
            audioData={props.audioData}
            selectedSample={props.selectedSample}
            probabilityData={props.probabilityData}
          />
        ),
      },
      {
        style: { height: 'inherit' },
        label: context.t('tensors'),
        component: (
          <AnalysisDetails
            analysisData={props.analysisData}
            distanceFactor={props.distanceFactor}
          />
        ),
      }
    );
  }

  return (
    <div className={advancedGraphsClass}>
      {props.show && <TabsContainer tabs={tabs} />}
    </div>
  );
}

SampleAdvancedInfoTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};
