import * as allTypes from '../constants/ActionTypes';
const types = allTypes.DEVICE_CONFIGURATION;

const initialState = {
  deviceList: [],
  devicesForDisplay: [],
  bitsInfo: [],
  selection: [],
  fwVersions: [],
  fwModemVersions: [],
  messagesLogs: [],
  defaultProjectsValues: {},
  sort: {
    field: 'time',
    direction: 'desc'
  },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case allTypes.SELECT_PROJECT:
      return initialState;

    case types.REQUEST_DEVICE_LIST:
      return {
        ...state,
        deviceList: [],
        devicesForDisplay: []
      };

    case types.RECEIVE_DEVICE_LIST:
      const devices = action.payload;
      const selection = state.selection;
      let displayItems = [];

      if (selection.length > 0 && devices.some((device) => selection.includes(device.DeviceID))) {
        // if all selections devices include the received items - save the
        // devicesForDisplay value as is.
        displayItems = devices.filter((d) => selection.includes(d.DeviceID));
      } else {
        displayItems = [...devices];
      }

      return {
        ...state,
        deviceList: action.payload,
        devicesForDisplay: displayItems,
      };

    case types.SORT_G5_DEVICES: {
      const field = action.payload;
      const { sort } = state;
      let direction;
      if (sort.field === field) {
        direction = (sort.direction === 'asc') ? 'desc' : 'asc'
      } else {
        direction = 'desc'
      }

      const devices = state.devicesForDisplay.sort((a, b) => {
        let item1, item2;
        if (direction === 'asc') { item1 = a; item2 = b; }
        else { item1 = b; item2 = a; }

        const value1 = item1[field]; // === null ? Number.NEGATIVE_INFINITY : item1[property];
        const value2 = item2[field]; // === null ? Number.NEGATIVE_INFINITY : item2[property];

        if (value1 > value2 || (value2 === null && value1 !== null)) return -1;
        if (value1 < value2 || (value1 === null && value2 !== null)) return 1;
        return 0;
      });

      return {
        ...state,
        devicesForDisplay: devices,
        sort: { field, direction }
      };
    }

    case types.SET_DEVICES_SELECTION: {
      // console.log({action});
      // const devices = action.payload.map((deviceId) => {
      //   const deviceObj = state.deviceList.find((d) => d.DeviceID === deviceId);
      //   return deviceObj.DeviceID;
      // });

      const selection = action.payload;

      let filteredDevices;
      if (selection.length === 0) {
        filteredDevices = state.deviceList;
      } else {
        filteredDevices = state.deviceList.filter((d) => selection.includes(d.DeviceID));
      }

      return {
        ...state,
        selection: selection,
        devicesForDisplay: filteredDevices,
      };
    }

    // case types.SELECT_MULTIPLE_DEVICES: {
    //   return { ...state, selection: action.payload };
    // }

    case types.REQUEST_DEVICE_BIT_INFO:
      return { ...state, bitsInfo: [] };
    case types.RECEIVE_DEVICE_BIT_INFO:
      return { ...state, bitsInfo: action.payload };

    case types.RECEIVE_G5_VERSIONS:
      return { ...state, fwVersions: action.payload };

    case types.RECEIVE_G5_ULTRA_VERSIONS:
      return { ...state, fwUltraVersions: action.payload };

    case types.RECEIVE_G5_MODEM_VERSIONS:
      return { ...state, fwModemVersions: action.payload };

    case types.RECEIVE_G5_CONFIGURATION_LOGS:
      return { ...state, messagesLogs: action.payload };

    case types.SET_DEFAULT_PROJECT_VALUES:
      return { ...state, defaultProjectsValues: action.payload };

    default:
      return state;
  }
}
