import React from 'react';
import { layer as alertsLayer } from './Alerts';
import { zIndexs, featuresTypes } from './Options';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');

const classNames = require('classnames');

export const style =  new Style({
    image: new Icon({
        src: require('../../../../images/map/alerts/assessments/assessment-non.png'), anchor: [0.5, 1]
    })
});

const source = new VectorSource({
  features: []
});

export const layer = new VectorLayer({
    name: 'layerAssessments',
    style: style,
    source: source,
    zIndex: zIndexs.alerts,
});

  export const setSource = (nextIconFeatures, props) => {
    let iconFeatures = [];
    Object.keys(nextIconFeatures).forEach((key) => {
        const itrAlert = nextIconFeatures[key];
        if (!props.displayAssessmentsOnlyRealLeaks || [3,6].indexOf(itrAlert.stateNum) != -1) {
            const iconFeature = new Feature({
                geometry: new Point(
                    proj.transform(
                        nextIconFeatures[key].position,
                        'EPSG:4326',
                        'EPSG:3857'
                    )
                ),
                type: featuresTypes.ASSESSMENT,
                id: itrAlert.id,
                priority: itrAlert.priority,
                state: itrAlert.state,
                stateNum: itrAlert.stateNum,
                alertTypeNum: itrAlert.alertTypeNum,
                intensity: itrAlert.intensity,
            });
            iconFeature.setStyle(getStyle(iconFeature));
            iconFeatures.push(iconFeature);
        }
    });
    layer.getSource().addFeatures(iconFeatures);
};

export const getStyle = (feature, selected) => {
    let assessmentStyle = false;
    let iconStatus = false;

    const attr = feature.getProperties();
    selected = selected ? '-selected' : '';

    switch (attr.stateNum) {
        case 5: // Closed
            iconStatus = 'non';
            break;
        case 6: // Fixed
            iconStatus = 'v';
            break;
        case 7: // Not detected
            iconStatus = 'x';
            break;
        default:
            assessmentStyle = alertsLayer.getStyle(feature, selected);
            break;
    }

    if (iconStatus) {
        assessmentStyle = new Style({
            image: new Icon({
                src: require('../../../../images/map/alerts/assessments/assessment-' + iconStatus + selected + '.png'), anchor: [0.5, 1]
            })
        });
        return assessmentStyle;
    }
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: featuresTypes.ASSESSMENT,
        id: attr.id,
    };
    return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
    const tooltipClasses = classNames(
        'tooltip',
        'priority-' + itemInfo.priority
    );

    return (
        <span className={tooltipClasses}>
            <div className="tooltip-row">{context.t('assessment_param', {id: itemInfo.id})}</div>
            <div className="tooltip-row">{context.t('type_param', {type: itemInfo.type})}</div>
        </span>
    );
};

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

