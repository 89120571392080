import * as types from "../constants/ActionTypes";
import * as endPoints from "../constants/EndPoints";
import {fetchMiddleware} from "./MiddlewareActions";
import * as reduxFormActions from "redux-form";

export function setEditMode(userData) {
    return {
        type: types.SET_EDIT_MODE,
        editMode: userData.canEdit,
        userEditMode: userData.canEditUsers,
        canAddAlert: userData.canAddAlert,
        isAQS: userData.isAQS,
        viewWaterMeter: userData.waterMeterAccess,
        analysesExpert: userData.graphsLables,
        installerPlanner: userData.installerPlanner,
        advancedMapIndications: userData.advancedMapIndications,
        uid: userData.userID,
        customerId: userData.customerId,
        isPCA: userData.isPCA,
        operatePcaModule: userData.operatePcaModule,
        technicianAdvanced: userData.technicianAdvanced,
        g5Operator: userData.g5Operator,
        g5Admin: userData.g5Admin,
        g5AqsAdmin: userData.g5AqsAdmin,
        mobile: userData.mobile,
        analysisExtends: userData.analysisExtends,
        valveAlerts: userData.valveAlerts
    };
}

export const clearManageItem = () => {
    return {
        type: types.CLEAR_MANAGE_ITEM,
    };
}; //--------------------------------
// Settings
//--------------------------------
export const fetchCustomDetails = () => {
    return (dispatch, getState) => {
        const path = endPoints.END_POINT + '/customerDetails';
        return fetchMiddleware(path, {}, getState).then((json) => {
            // console.log(json);
            return dispatch(receiveCustomDetails(json.data));
        });
    };
};
const receiveCustomDetails = (logo) => {
    return {
        type: types.RECEIVE_CUSTOM_DETAILS,
        logo,
    };
};

export function invalidateProject(project) {
    return {
        type: types.INVALIDATE_PROJECT,
        project
    };
}

export const setFieldText = (form, field, text) => {
    return {
        type: '@@redux-form/CHANGE',
        meta: {
            form: form,
            field: field,
            touch: false,
            persistentSubmitErrors: false,
        },
        payload: text,
    };
};
export const resetForm = (form) => {
    return reduxFormActions.reset(form);
};
export const setCouplePathReady = (isCouplePathReady) => {
    return {
        type: types.SET_COUPLE_PATH_READY,
        isCouplePathReady
    };
};
export const clearCouplePath = () => {
    return (dispatch, getState) => {
        const action = {
            type: types.CLEAR_COUPLE_PATH
        };
        dispatch(setCouplePathReady(false));
        return dispatch(action);
    };
};
export const createNewCouple = (active) => {
    const isActive = active;
    const newCouple = active ? {} : null;
    return {
        type: types.CREATE_NEW_COUPLE,
        isActive,
        newCouple,
    };
};
export const fetchImages = (getState, projectId, itemId, itemType) => {
    const path =
        endPoints.END_POINT +
        `/images/${projectId}?itemId=${itemId}&itemType=${itemType}`;
    return fetchMiddleware(path, {}, getState);
};
export const setMapDrawMode = (isDraw, drawType = "Circle", points) => {
    return {
        type: types.SET_MAP_DRAW_MODE,
        isDraw,
        drawType,
        points
    };
};
