/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { makeStyles } from '@mui/styles';
import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';

require('./style.scss');

const getDateString = (timeMillis) => {
  let strVal = '--';
  if (timeMillis) {
    strVal = UIDateFormater(timeMillis, DateType.DATE_AND_TIME_WITH_SECONDS);
  }
  return strVal;
};

const fontSizeStyle = '1.5rem';
const labelShift = '4px';

const useStyles = makeStyles({
  root: {
    margin: '40px 50px',
  },
  margin: {
    margin: 0.75,
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '4px',
  },
  label: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'teal !important',
    top: labelShift,
    left: labelShift,
  },
  input: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'black !important',
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    width: 'fit-content',
    margin: '20px auto',
  },
});

const G5MessageStrip = (props, context) => {
  const classes = useStyles();

  const { item } = props;

  const renderField = (key, label) => {
    const value = item[key] || '';
    return (
      <TextField
        className={classes.margin}
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.label }}
        variant='outlined'
        type={'text'}
        name={key}
        value={value}
        label={label}
        disabled
      />
    );
  };

  const { channels, bandsData } = item;
  const barsData = {
    ...bandsData,
    text: bandsData.y.map(String),
    textposition: 'auto',
    type: 'bar',
    hoverinfo: 'none',
    marker: {
      color: 'rgb(158,202,225)',
      opacity: 0.6,
      line: {
        color: 'rgb(8,48,107)',
        width: 1.5,
      },
    },
  };

  const plotData = [barsData];
  const plotLayout = {
    autosize: false,
    width: 360,
    height: 250,
    margin: { l: 30, r: 10, b: 30, t: 30 },
    xaxis: {
      tickmode: 'array',
      tickvals: barsData.x,
      title: {
        text: context.t('power'),
        font: {
          color: '#8d8d8d',
        },
      },
    },
    yaxis: {
      title: {
        text: props.yTitle,
        font: {
          color: '#8d8d8d',
        },
      },
    },
  };

  const closePanel = () => {
    props.handleClosePannel('g5msg');
  };

  return (
    <div className='sample-info g5-bit-msg'>
      <i className='icon-close' onClick={closePanel} />
      <div style={{ float: 'left', textAlign: 'center' }}>
        <h4>{context.t('radio_frequency_data')}</h4>
        <table className='bit-msg-freq-data'>
          <tbody>
            <tr>
              <th>{context.t('freq')}</th>
              {channels.map((item, i) => (
                <th key={i}>{(item.freq / 100).toFixed(2)}</th>
              ))}
            </tr>
            <tr>
              <th>{context.t('rssi')}</th>
              {channels.map((item, i) => (
                <td key={i}>{item.RSSI}</td>
              ))}
            </tr>
            <tr>
              <th>{context.t('snr')}</th>
              {channels.map((item, i) => (
                <td key={i}>{item.SNR}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <div
        style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}
      >
        <h4>{context.t('noise_intensity_min_band_power')}</h4>
        <Plot data={plotData} layout={plotLayout} />
      </div>
      <div style={{ display: 'inline-block' }}>
        <Box className={classes.fields}>
          {renderField('AudioDataSize', context.t('audio_data_size'))}
          {renderField('RequestTimeStamp', context.t('request_time'))}
          {renderField('ResponseTimeStamp', context.t('response_time'))}
          {renderField(
            'SelectedFMChannel_10k',
            context.t('selected_fm_channel')
          )}
        </Box>
      </div>
    </div>
  );
};

G5MessageStrip.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default G5MessageStrip;
