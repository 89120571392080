import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../../Custom/Tabs/TabsContainer';

let pipesList1 = [];
let pipesList2 = [];

let coupleCoverageDataList = [];
let pipeAssessmentsDataList = [];

const presuresData1 = [
  {
    range: '620 - 675',
    r: 0,
    g: 255,
    b: 255,
  },
  {
    range: '710 - 715',
    r: 255,
    g: 0,
    b: 255,
  },
  {
    range: '730 - 740',
    r: 127,
    g: 255,
    b: 0,
  },
  {
    range: '745 - 775',
    r: 0,
    g: 190,
    b: 190,
  },
  {
    range: '795 - 813',
    r: 0,
    g: 160,
    b: 0,
  },
];

const presuresData2 = [

  {
    range: '813 - 815',
    r: 255,
    g: 180,
    b: 0,
  },
  {
    range: '815 - 836',
    r: 0,
    g: 190,
    b: 255,
  },
  {
    range: '840 - 865',
    r: 255,
    g: 0,
    b: 0,
  },
  {
    range: '890 -',
    r: 0,
    g: 80,
    b: 110,
  },
  {
    range: '-',
    r: 0,
    g: 0,
    b: 0,
  },
]

const diameterData1 = [
  {
    range: '0 - 1.9',
    r: 0,
    g: 0,
    b: 0,
  },
  {
    range: '2 - 3.9',
    r: 249,
    g: 0,
    b: 0,
  },
  {
    range: '4 - 5.9',
    r: 249,
    g: 178,
    b: 0,
  },
  {
    range: '6 - 7.9',
    r: 249,
    g: 82,
    b: 0,
  },
  {
    range: '8 - 9.9',
    r: 249,
    g: 228,
    b: 0,
  },
  {
    range: '10 - 11.9',
    r: 195,
    g: 249,
    b: 0,
  },
  {
    range: '12 - 13.9',
    r: 99,
    g: 249,
    b: 0,
  },
  {
    range: '14 - 15.9',
    r: 0,
    g: 255,
    b: 178,
  },
  {
    range: '16 - 17.9',
    r: 0,
    g: 242,
    b: 255,
  },
];

const diameterData2 = [
  {
    range: '18 - 19.9',
    r: 0,
    g: 148,
    b: 255,
  },
  {
    range: '20 - 21.9',
    r: 0,
    g: 55,
    b: 255,
  },
  {
    range: '22 - 23.9',
    r: 78,
    g: 50,
    b: 188,
  },
  {
    range: '24 - 25.9',
    r: 144,
    g: 50,
    b: 188,
  },
  {
    range: '26 - 29.9',
    r: 226,
    g: 183,
    b: 209,
  },
  {
    range: '30 - 33.9',
    r: 158,
    g: 91,
    b: 109,
  },
  {
    range: '34 - 37.9',
    r: 102,
    g: 38,
    b: 38,
  },
  {
    range: '38 -',
    r: 28,
    g: 1,
    b: 1,
  },
];

const placementYearData = [
  {
    range: '> 75',
    color: 'red'
  },
  {
    range: '50 - 75',
    color: 'deeppink'
  },
  {
    range: '40 - 50',
    color: 'darkorange'
  },
  {
    range: '30 - 40',
    color: 'turquoise'
  },
  {
    range: '20 - 30',
    color: 'blue'
  },
  {
    range: '10 - 20',
    color: 'green'
  }, {
    range: ' - 10',
    color: 'lawngreen'
  }, {
    range: ' Unknown ',
    color: 'black'
  }
];
class PipeMaterialsLegend extends React.Component {

  componentWillMount() {
    pipesList1 = [
      { label: this.context.t('metal'), class: 'metal' },
      { label: this.context.t('pvc'), class: 'pvc' },
      { label: this.context.t('azbest_cement'), class: 'azbest-cement' },
      { label: this.context.t('poly_ethelen'), class: 'poly-ethelen' },
      { label: this.context.t('mdpe'), class: 'mdpe' },
      { label: this.context.t('concrete'), class: 'concrete' },
      { label: this.context.t('cast_iron'), class: 'cast-iron' },

    ];
    pipesList2 = [
      { label: this.context.t('brass'), class: 'brass' },
      { label: this.context.t('ductile_iron'), class: 'ductile-iron' },
      { label: this.context.t('copper'), class: 'copper' },
      { label: this.context.t('lead'), class: 'lead-type' },
      { label: this.context.t('large_diameter'), class: 'large-diameter' },
      { label: this.context.t('other'), class: 'other' },

    ];

    coupleCoverageDataList = [
      { label: this.context.t('a_valid_segment'), color: [0, 255, 0, 1] },
      { label: this.context.t('sync_problem'), color: [255, 255, 0, 1] },
      { label: this.context.t('communication_problem'), color: [255, 0, 0, 1] },
    ];

    pipeAssessmentsDataList = [
      { label: this.context.t('no_leaks'), color: [0, 255, 0, 1] },
      { label: this.context.t('low_severity_1_leak'), color: [255, 255, 0, 1] },
      { label: this.context.t('low_severity_with_fast_development_alert'), color: [255, 150, 0, 1] },
      { label: this.context.t('medium_severity_2_4_leaks'), color: [255, 20, 147, 1] },
      { label: this.context.t('high_severity_more_than_4_leaks'), color: [255, 0, 0, 1] },

    ];
  }

  render() {
    const style = { float: 'left' };
    return (
      <div>
        <TabsContainer
          tabs={[
            {
              style: { height: 'inherit' },
              label: this.context.t('pipes_by_materials'),
              component: (<div className="pipe-topic">
                <div style={style}>
                  <table style={style}>
                    <tbody>
                      {
                        pipesList1 && pipesList1.map((pipe, index) => {
                          return (
                            <tr className="table-row" key={index}>
                              <th>{pipe.label}</th>
                              <td>
                                <div className={"pipe-color " + pipe.class}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                  <table style={style}>
                    <tbody>
                      {
                        pipesList2 && pipesList2.map((pipe, index) => {
                          return (
                            <tr className="table-row" key={index}>
                              <th>{pipe.label}</th>
                              <td>
                                <div className={"pipe-color " + pipe.class}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table >
                </div>
              </div>)
            },
            {
              style: { height: 'inherit' },
              label: this.context.t('pipes_by_presure'),
              component: (<div className="pipe-topic">
                <div style={style}>

                  <table style={style}>
                    <tbody>
                      {
                        presuresData1 && presuresData1.map((presure, index) => {
                          const style = {
                            background: 'rgb(' + presure.r + ',' + presure.g + ',' + presure.b + ')'
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th>{presure.range}</th>
                              <td>
                                <div className="pipe-color" style={style}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                  <table style={style}>
                    <tbody>
                      {
                        presuresData2 && presuresData2.map((presure, index) => {
                          const style = {
                            background: 'rgb(' + presure.r + ',' + presure.g + ',' + presure.b + ')'
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th>{presure.range}</th>
                              <td>
                                <div className="pipe-color" style={style}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>)
            },
            {
              style: { height: 'inherit' },
              label: this.context.t('pipes_by_diameter'),
              component: (<div className="pipe-topic">
                <div style={style}>

                  <table style={style}>
                    <tbody>
                      {
                        diameterData1 && diameterData1.map((diameter, index) => {
                          const style = {
                            background: 'rgb(' + diameter.r + ',' + diameter.g + ',' + diameter.b + ')'
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th>{diameter.range}</th>
                              <td>
                                <div className="pipe-color" style={style}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                  <table style={style}>
                    <tbody>
                      {
                        diameterData2 && diameterData2.map((diameter, index) => {
                          const style = {
                            background: 'rgb(' + diameter.r + ',' + diameter.g + ',' + diameter.b + ')'
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th>{diameter.range}</th>
                              <td>
                                <div className="pipe-color" style={style}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>)
            },
            {
              style: { height: 'inherit' },
              label: this.context.t('pipes_by_placement_year'),
              component: (<div className="pipe-topic">
                <div style={style}>

                  <table style={style}>
                    <tbody>
                      {
                        placementYearData && placementYearData.map((ageColor, index) => {
                          const style = {
                            background: ageColor.color
                          };
                          return (
                            <tr className="table-row" key={index}>
                              <th>{ageColor.range}</th>
                              <td>
                                <div className="pipe-color" style={style}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>)
            },
            {
              style: { height: 'inherit' },
              label: this.context.t('General'),
              component: (<div className="pipe-topic">
                <div className="general-pipes-legend">
                  {/* Couple Coverage */}
                  <h4><strong>{this.context.t('couple_coverage')}</strong></h4>
                  <table style={style}>
                    <tbody>
                      {
                        coupleCoverageDataList && coupleCoverageDataList.map((pipe, index) => {
                          const color = pipe.color;
                          const colorGRB = 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + color[3] + ')';
                          const pipeStyle = {
                            width: '100px',
                            height: '15px',
                            backgroundColor: colorGRB,
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th style={{ textAlign: 'left', paddingLeft: '20px' }}>{pipe.label}</th>
                              <td>
                                <div style={pipeStyle}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="general-pipes-legend">
                  {/* Pipe Assessment */}
                  <h4><strong>{this.context.t('pipe_assessments')}</strong></h4>
                  <table style={style}>
                    <tbody>
                      {
                        pipeAssessmentsDataList && pipeAssessmentsDataList.map((pipe, index) => {
                          const color = pipe.color;
                          const colorGRB = 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + color[3] + ')';
                          const pipeStyle = {
                            width: '100px',
                            height: '15px',
                            backgroundColor: colorGRB,
                          }
                          return (
                            <tr className="table-row" key={index}>
                              <th style={{ textAlign: 'left', paddingLeft: '20px' }}>{pipe.label}</th>
                              <td>
                                <div style={pipeStyle}></div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>)
            },
          ]}
        />
      </div>
    );
  }
}

PipeMaterialsLegend.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PipeMaterialsLegend;
