import * as types from '../constants/ActionTypes';
import {
  DELETE_DISTRIBUTION_LIST_FOR_PROJECT,
  DISTRIBUTION_LISTS_RECEIVE_USERS_OF_PROJECT
} from "../constants/ActionTypes";

const initialState = {
  lists: [],
  projectsDLists: [],
  selectedList: {},
  usersList: [],
  usersInEdit: [],
  customerUsers: [],
  projectUsers: [],
};

const distributionLists = (state = initialState, action) => {
  switch (action.type) {
    case types.DISTRIBUTION_LIST_SELECT_LIST:
      return Object.assign({}, state, {
        selectedList: action.list
      });
    case types.REQUEST_DISTRIBUTION_LISTS:
      return Object.assign({}, state, {
        lists: []
      });

    case types.RECEIVE_DISTRIBUTION_LISTS:
      return Object.assign({}, state, {
        lists: action.lists
      });

    case types.REQUEST_DISTRIBUTION_LIST_FOR_PROJECT:
      return Object.assign({}, state, {
        projectsDLists: []
      });

    case types.RECEIVE_DISTRIBUTION_LIST_FOR_PROJECT:
      return Object.assign({}, state, {
        projectsDLists: action.payload || []
      });

    case types.DISTRIBUTION_LISTS_RECEIVE_USERS_OF_CUSTOMER:
      return {
        ...state,
        customerUsers: action.payload.users
      };

    case types.DISTRIBUTION_LISTS_RECEIVE_USERS_OF_PROJECT:
      return {
        ...state,
        projectUsers: action.payload.users
      };

      case types.RECEIVE_DISTRIBUTION_LIST_USERS:
        const { users } = action;
        const usersList = users.map((item) => ({
          ...item,
          RegisterMail: item.register_Mail || item.RegisterMail,
          RegisterSMS: item.register_SMS || item.RegisterSMS,
        }));

        return Object.assign({}, state, {
          usersList: usersList,
          usersInEdit: usersList,
        });

      case types.DISTRIBUTION_LIST_ADD_USER: {
        const currentUsersList = Object.assign([], state.usersInEdit);
        const newUser = {
          UserID: action.userId,
          RegisterMail: false,
          RegisterSMS: false,
        };
        currentUsersList.push(newUser)
        return Object.assign({}, state, {
          usersInEdit: currentUsersList
        });
      }
      case types.DISTRIBUTION_LIST_REMOVE_USER: {
        const currentUsersList = Object.assign([], state.usersInEdit);
        const userIndex = currentUsersList.findIndex((user) => user.UserID == action.userId);
        if (userIndex === -1) {
          return state;
        } else {
          currentUsersList.splice(userIndex, 1);
          return Object.assign({}, state, {
            usersInEdit: currentUsersList
          });
        }
      }

      case types.DISTRIBUTION_LIST_SET_USER_ATTRIBUTE: {
        const currentUsersList = Object.assign([], state.usersInEdit);
        const userIndex = currentUsersList.findIndex((user) => user.UserID == action.userId);
        if (userIndex === -1) {
          return state;
        } else {
          currentUsersList[userIndex][action.attrName] = action.value;
          return Object.assign({}, state, {
            usersInEdit: currentUsersList
          });
        }
      }

      case types.DISTRIBUTION_LIST_SET_LIST_ANNOUNCES_STATUS_CHANGE: {
        const { listId, isAnnounce } = action.payload;
        const newLists = [...state.lists];
        newLists.forEach((list) => {
          if (list.ID === listId) {
            list.AnnouncesStatusChange = isAnnounce;
          }
        });
        return {
          ...state,
          lists: newLists
        };
      }
      case types.DISTRIBUTION_LIST_SET_IS_VALVE_MONITORING_CHANGE: {
        const { listId, isValveMonitoring } = action.payload;
        const newLists = [...state.lists];
        newLists.forEach((list) => {
          if (list.ID === listId) {
            list.IsValveMonitoring = isValveMonitoring;
          }
        });
        return {
          ...state,
          lists: newLists
        };
      }
    case types.DELETE_DISTRIBUTION_LIST_FOR_PROJECT: {
      const { listId } = action.payload;
      const newLists = state.projectsDLists.filter((list) => list.id !== listId);

      // console.log({ newLists });
      return {
        ...state,
        projectsDLists: newLists
      };
    }


    default:
      return state;
  }
};

export default distributionLists;
