import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { TENSOR_DAYS_BACK } from '../constants/Misc';
import * as graphsHelper from './Utilities/prod4PlotlyHelper';

import { fetchMiddleware } from './MiddlewareActions';

export const getSampleOutputData = (sample) => {
  return (dispatch, getState) => {
    const state = getState();

    const projectId = state.leaksList.selectedProject;
    const projectsList = state.leaksList.projectsList;
    const projectsObj = projectsList.items[projectsList.projectIndexMap[projectId]];
    const customerId = projectsObj.CustomerID;

    const prod4graphPath = `${endPoints.END_POINT}/graph/corr/${sample.CoupleID}/${sample.SampleDateAndTime}?customer=${customerId}&sampleId=${sample.ID}`;

    dispatch(requestSampleOutputData(sample.ID));
    dispatch({ type: types.GRAPHS.CLEAN });
    return fetchMiddleware(prod4graphPath, {}, getState).then((json) => {
      if (json.status) {

        const graphData = json.data;

        return (graphData);
      }
    }).then(async (graphData) => {
      if (graphData != null) {
        const factor = 40;
        const resultsParams = await graphsHelper.setParameters(graphData, factor);
        await graphsHelper.calcDistancesInSamplesAxis(
          graphData.pipe_section_in_samples_from_start, graphData.pipe_section_distances_m,
          graphData.pipe_len_m, graphData.ave_locks_in_samples, graphData.ave_locks_in_m,
          (graphData.total_time_div2 * 2), graphData.fs, graphData.max_delay, factor
        );
        const graphResult = await graphsHelper.generateGraph(graphData, graphData['inst_pks'], graphData['inst_locs'], factor);
        const avsGraphResult = await graphsHelper.generateGraph(graphData, graphData['ave_last_pks'], graphData['ave_last_locs'], factor);
        const signals = await graphsHelper.calcAudioSignals(graphData.sliced_intensity_sig1_raw, graphData.sliced_intensity_sig2_raw);
        const history = await graphsHelper.generateHistoryHistograms(graphData.historyData, graphData, factor);

        dispatch({
          type: types.GRAPHS.SET_RAW_DATA, payload: {
            rawData: graphData,
            params: resultsParams,
            bias: graphData.bias
          }
        });

        // const sortedHistoryData = graphData.historyOutputsData.sort((a, b) => b[0] - a[0]);
        dispatch({ type: types.GRAPHS.SET_HISTORY_NOISE_DATA, payload: graphData.historyOutputsData });
        dispatch({ type: types.GRAPHS.SET_INSTANCE_CORRELATION, payload: graphResult.correlation });
        dispatch({ type: types.GRAPHS.SET_MAX_PROBABILITIES, payload: graphResult.probabilityData });
        dispatch({ type: types.GRAPHS.SET_INSTANCE_HISTOGRAM, payload: graphResult.histogram });
        dispatch({ type: types.GRAPHS.SET_GRAPH_DISTANCES_DATA, payload: graphResult.distancesValuesArr });
        dispatch({ type: types.GRAPHS.SET_GRAPH_DISTANCES_DATA_SENSORS_ONLY, payload: graphResult.distancesValuesArrSensorsOnly });
        dispatch({ type: types.GRAPHS.SET_SENSORS_POSITIONS, payload: graphResult.sensorsPositions });
        dispatch({ type: types.GRAPHS.SET_AVE_CORRELATION, payload: avsGraphResult.correlation });
        dispatch({ type: types.GRAPHS.SET_AVE_HISTOGRAM, payload: avsGraphResult.histogram });
        dispatch({ type: types.GRAPHS.SET_SIGNALS, payload: [signals.wav1, signals.wav2] });
        dispatch({ type: types.GRAPHS.SET_HISTORY, payload: history });

        for (let i = 0; i < 3; i++) {
          if (graphData['max_prob_bands_pks'] && graphData['max_prob_bands_pks'][0] && graphData['max_prob_bands_pks'][0][i]) {
            const maxProbData = await graphsHelper.generateGraph(graphData, [graphData['max_prob_bands_pks'][0][i]], [graphData['max_prob_bands_locs'][0][i]], factor);
            // console.log(i, maxProbData);
            dispatch({ type: types.GRAPHS.SET_MAX_PROB_GRAPH, payload: { index: i, data: maxProbData } });
          }
        }
      }
    });
  };
};

const requestSampleOutputData = (sampleId) => {
  return {
    type: types.REQUEST_SAMPLE_OUTPUT_DATA,
    sampleId
  };
};
const receiveSampleOutputData = (sampleId, rawData) => {
  return {
    type: types.RECEIVE_SAMPLE_OUTPUT_DATA,
    sampleId,
    rawData
  };
};

export const getDeviceMessageData = (sampleId) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedSample = state.samples.selectedSample;
    if (selectedSample && selectedSample.ID == sampleId) {
      const coupleId = selectedSample.CoupleID;
      const sampleTime = selectedSample.SampleDateAndTime;
      const path = `${endPoints.END_POINT}/couple/deviceMsgData/${coupleId}/${sampleTime}`;
      dispatch(requestDeviceMessageData(sampleId));
      return fetchMiddleware(path, {}, getState).then(async (json) => {
        if (json.status) {
          const { data } = json;

          // if (data && data.length > 0) {
          //   if (data[0]) {
          //     const radioBody1 = await getFileBufferArray(data[0].radioUrl);
          //     const sampleBody1 = await getFileBufferArray(data[0].waveUrl);
          //     data[0].radioBody = radioBody1;
          //     data[0].sampleBody = sampleBody1;
          //   }
          //   if (data[1]) {
          //     const radioBody2 = await getFileBufferArray(data[1].radioUrl);
          //     const sampleBody2 = await getFileBufferArray(data[1].waveUrl);
          //     data[1].radioBody = radioBody2;
          //     data[1].sampleBody = sampleBody2;
          //   }

          //   // console.log(`radio device: ${data[0].DeviceID}, ${radioBody1.slice(0, 44)}`)
          //   // console.log(`sample device: ${data[0].DeviceID}, ${sampleBody1.slice(0, 44)}`)
          //   // console.log(`radio device: ${data[1].DeviceID}, ${radioBody2.slice(0, 44)}`)
          //   // console.log(`sample device: ${data[1].DeviceID}, ${sampleBody2.slice(0, 44)}`)
          // }

          dispatch(receiveDeviceMessageData(sampleId, data));
        }
      });
    }
  };
};

const requestDeviceMessageData = (sampleId) => {
  return {
    type: types.REQUEST_DEVICE_MESSAGE_DATA,
    sampleId
  };
};

const receiveDeviceMessageData = (sampleId, data) => {
  return {
    type: types.RECEIVE_DEVICE_MESSAGE_DATA,
    sampleId,
    data
  };
};

export const getTensorData = (sample) => {
  return (dispatch, getState) => {
    if (sample != null) {
      const url = `${endPoints.TENSORS_ENDPOINT}/distances/${sample.CoupleID}/${sample.SampleDateAndTime}?days=${TENSOR_DAYS_BACK}`;
      dispatch(requestTensorData(sample));
      return fetchMiddleware(url, {}, getState).then((json) => {
        if (!json.status) {
          console.error(json);
        } else {
          const tensor = json.data;

          const trendData = {
            distances: [],
            intensities: [],
            dates: [],
          };
          Object.keys(tensor).forEach((keyDate) => {
            trendData.distances.push(tensor[keyDate][0].distance);
            trendData.intensities.push(tensor[keyDate][0].intensity);
            trendData.dates.push(keyDate);
          });
          dispatch(receiveTensorData({ tensor, trendData }));
        }
      });
    }
  };
};

const requestTensorData = (sample) => {
  return {
    type: types.REQUEST_TENSOR_DATA,
    payload: sample
  };
};

const receiveTensorData = (data) => {
  return {
    type: types.RECEIVE_TENSOR_DATA,
    payload: data
  };
};

export function getTensorProbabilityData(sampleId) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedSample = state.samples.selectedSample;
    if (selectedSample && selectedSample.ID == sampleId) {
      const coupleId = selectedSample.CoupleID;
      const sampleTime = selectedSample.SampleDateAndTime;
      const path = `${endPoints.TENSORS_ENDPOINT}/${coupleId}/${sampleTime}`;

      dispatch(requestDeviceProbability(sampleId, sampleTime));
      return fetchMiddleware(path, {}, getState).then((json) => {
        dispatch(receiveDeviceProbability(json.data));
      });
    }
  };
}

function requestDeviceProbability(sample, time) {
  return {
    type: types.REQUEST_DEVICE_PROBABILITY_DATA,
    sample,
    time
  };
}

function receiveDeviceProbability(data) {
  return {
    type: types.RECEIVE_DEVICE_PROBABILITY_DATA,
    payload: data
  };
}

export function setAudioClassification(sampleId, classification) {
  return (dispatch, getState) => {
    const url = `${endPoints.END_POINT}/sensorsamples/waves/classify/${sampleId}`;
    return fetchMiddleware(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ value: classification })
    }, getState).then((json) => {
      if (json.status) {
        dispatch(updateAudioClassification(sampleId, classification));
      }
    });
  };
}

const updateAudioClassification = (sampleId, classification) => {
  return {
    type: types.UPDATE_AUDIO_CLASSIFICATION,
    sampleId,
    classification
  };
};
