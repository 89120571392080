import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
// import Img from 'react-fix-image-orientation';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { downloadFileFromURl } from "../../../actions/selectors";
import "./ImagesTab.scss";

const ImagesTab = ({ images }, context) => {
  return (
    <Carousel
      showArrows
      dynamicHeight
      useKeyboardArrows
      showThumbs
      verticalSwipe='natural'
      onClickItem={(index) => {
        // console.log('onClickItem', index);
        downloadFileFromURl(images[index])
      }}
    // width={600}
    >
      {
        images && images.map((image, index) => (
          <div
            key={index}
            style={{
              height: '80vh',
              overflowY: 'auto',
            }}
          >
            <img src={image} alt={context.t('img')} />
            <p className='legend'>{context.t('click_to_download')}</p>
          </div>
        ))
      }
    </Carousel>
  );
}

// function downloadFileFromURl(url) {
//   // script that download file from url:
//   if (url) {
//     const tempElement = document.createElement('a');
//     tempElement.href = url;
//     tempElement.target = '_blank';
//     tempElement.download = url.substring(
//       url.lastIndexOf('/') + 1,
//       url.lastIndexOf('?')
//     );
//     document.body.appendChild(tempElement);
//     tempElement.click();
//     setTimeout(() =>
//     {
//       document.body.removeChild(tempElement);
//     }, 100); // Adjust the delay as needed
//   }
// }

ImagesTab.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ImagesTab;
