import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';
import { sqlGeographyToPointsArray } from './Utilities/geoFunctions';
// import fetch from 'isomorphic-fetch';

//--------------------------------
// Receive & Request
//--------------------------------

function requestCouplesCoveragePaths(project) {
    return {
        type: types.REQUEST_COUPLES_COVERAGE_PATHS,
        project,
    };
}

function receiveCouplesCoveragePaths(project, data) {
    return {
        type: types.RECEIVE_COUPLES_COVERAGE_PATHS,
        project,
        data
    };
}

//--------------------------------
// Fetch
//--------------------------------


export function FetchCouplesCoveragePathsIfNeeded(force = false) {
  return (dispatch, getState) => {
    const state = getState();
    const projectID = state.leaksList.selectedProject;
    if (force || shouldFetchCouplesCoveragePaths(state, projectID)) {
      return dispatch(FetchCouplesCoveragePaths(projectID));
    }
  };
}

function shouldFetchCouplesCoveragePaths(state, projectID) {
    // TODO: check if needed.
    return true;
}

function FetchCouplesCoveragePaths(project) {
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.COUPLES_COVERAGE_PATHS_ENDPOINT;

    return (dispatch, getState) => {
        dispatch(requestCouplesCoveragePaths(project));
        return fetchMiddleware(path, {}, getState)
            .then((json) => {
                let data = [];
                if (json.status) {
                    const state = getState();
                    const projectsBundle = state.leaksList.projectsList;
                    const selectedProject = projectsBundle.items[projectsBundle.projectIndexMap[project]];
                    const couplesState = state.leaksList.leaksByProject[project].couples;
                    data = json.data;
                    data.forEach((segment) => {
                        const couple = couplesState.couples[couplesState.indexMap[segment.CoupleID]];
                        if (couple) {
                            segment.diameter = couple.MultiDiameter || "";
                            segment.coupleLength = couple.PathLengthM || "";
                            segment.material = couple.Material || "";
                        }
                    });
                    processData(data, selectedProject);
                } else {
                    throw new Error("Failed to fetch data");
                }
                dispatch(receiveCouplesCoveragePaths(project, data));
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
}

export const processData = (data, project) => {
    if (data != null && project != null) {
        const nowInMillis = new Date().getTime();
        const oneDayInMillis = 1000 * 60 * 60 * 24;
        const sensorsThresholdInMillis = project.SensorActivityRatioDaysBack * oneDayInMillis;

        data.forEach((segment) => {
            const { LastActiveTime1, LastActiveTime2, LastSampleTime,
                Sensor1Nc1, Sensor1Nc2, Sensor1Nc3, Sensor2Nc1, Sensor2Nc2, Sensor2Nc3 } = segment;

            let isConnectionActive = false;
            let isCorrelationActive = false;

            const pathPoints = sqlGeographyToPointsArray(segment.CouplePath);

            const isRegularNoise = Math.max(Sensor1Nc1 || 0, Sensor1Nc2 || 0, Sensor1Nc3 || 0) >= project.NoiseOkThreshold
                                && Math.max(Sensor2Nc1 || 0, Sensor2Nc2 || 0, Sensor2Nc3 || 0) >= project.NoiseOkThreshold;

            if (LastActiveTime1 != null && LastActiveTime2 != null) {
                isConnectionActive = (nowInMillis - LastActiveTime1) < sensorsThresholdInMillis
                            && (nowInMillis - LastActiveTime2) < sensorsThresholdInMillis;
            }

            if (isConnectionActive) {
                if (LastSampleTime == null) {
                    // set TRUE for not athurized users:
                    isCorrelationActive = true;
                } else {
                    isCorrelationActive = (nowInMillis - LastSampleTime) < (project.DaysCorrelationIncative * oneDayInMillis);
                }
            }

            Object.assign(segment, {
                pathPoints,
                isConnectionActive,
                isCorrelationActive,
                isRegularNoise,
            });
        });
    }
};
