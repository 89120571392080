import React from 'react';
import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';

import './alertsTabs.scss';

// const actionsTypes = {
//   0: 'Close',
//   1: 'Create',
//   2: 'Update',
// };

const ValveAlertHistory = (props) => {
  return (
    <ul className='history-container'>
      {props.history.map((item, index) => {
        const user = item.UserName;
        const alertId = item.AlertID;

        const status = item.AlertState
          ? props.options.find((opt) => opt.value === item.AlertState).label
          : '--';
        const time = UIDateFormater(
          item.LoggingDate,
          DateType.DATE_AND_TIME_WITH_SECONDS,
          props.timeZone
        );
        let content;

        switch (item.ActionCode) {
          case 0:
            content = (
              <span>
                {`${props.context.t('close_valve_alert')}, ${props.context.t(
                  'alert_id'
                )} ${alertId}`}
              </span>
            );
            break;
          case 1:
            content = (
              <span>{`${props.context.t(
                'create_a_new_valve_alert'
              )}, ${props.context.t('alert_id')}: ${alertId}`}</span>
            );
            break;
          case 2:
            content = (
              <span>
                {props.context.t('update_status_to')}
                <strong>{status}</strong>
                {`, ${props.context.t('alert_id')}: ${alertId}`}
              </span>
            );
            break;
          case 3:
            content = (
              <span>
                {props.context.t('commented')}
                <i>{item.Comment}</i>
                {`, ${props.context.t('alert_id')}: ${alertId}`}
              </span>
            );
            break;

          default:
            content = null;
        }

        return (
          <li key={index} className='valve-history-item'>
            <strong>{user}</strong>
            {content}
            <span className='valve-history-time'>{time}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default ValveAlertHistory;
