import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import * as actionAssessments from './AssessmentsActions';
import * as actionSamplesOutput from './SamplesOutputDataActions';
import {setSelectedCoupleSample} from './CouplesActions'
import {fetchMiddleware} from './MiddlewareActions';
// import fetch from 'isomorphic-fetch';
const _ = require('lodash');

export const ALERT_SAMPLES_COUNT_FOR_PAGE = 30;

export function fetchSamplesIfNeeded(project, leak, couple, page) {
    return (dispatch, getState) => {
        if (shouldFetchSamples(getState(), project, couple)) {
            const sort = getState().leaksList.filters.samplesSort;
            return dispatch(fetchSamples(project, leak, couple, sort, page))
        }
    }
}

function shouldFetchSamples(state, project, leak) {
    const samples = state.leaksList.leaksByProject[project].samples[leak]

    if (!samples) {
        return true
    }
    return !samples.isFetching;

}

function fetchSamples(project, leak, couple, sort, page) {
    let orderBy = 'SampleDateAndTime';
    let dir = 'DESC';
    if (sort) {
        orderBy = sort.field;
        dir = sort.dir;
    }

    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/couple/" + couple + "/orderBy/" + orderBy + "/samples" + "?dir=" + dir + "&page=" + page;

    return (dispatch, getState) => {
        let state = getState();
        const projectInfo = state.leaksList.projectsList.items.find(element => element.ID.toString() == project);
        dispatch(requestSamples(project, leak));
        return fetchMiddleware(path, {}, getState).then(json => {
          let samples = json.data.rows;
          //let samples = json.data.rows.map(obj => {
          //      obj.SPAverage = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.SPAverage;
          //      obj.CORAverage = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.CORAverage;
          //      obj.trend = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.trend;
          //      obj.files = obj.files || [];
          //      obj.files = obj.files.map(file => {
          //          return "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/INPUT/Projects/" + projectInfo.FullName + "/" + file;
          //      });
          //      if (obj.originalFiles != null) {
          //          obj.originalFiles = obj.originalFiles.map(originalFile => {
          //              return "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/INPUT/Projects/" + projectInfo.FullName + "/" + originalFile;
          //          });
          //      }
          //      return obj;
          //  });
            dispatch(receiveSamples(project, leak, { data: samples, total: json.data.totalRows }, page));
        });

        // return fetchMiddleware(path, {}, getState)
        //   .then(json => dispatch(receiveSamples(project, leak, json)));
    };
}

function requestSamples(project, leak) {
    return {
        type: types.REQUEST_SAMPLES,
        project,
        leak
    };
}

function receiveSamples(project, leak, samples, pageIndex) {
    //@TODO: move to the right place
    // samples.data = formatObjectsDate(samples.data);
    return {
        type: types.RECEIVE_SAMPLES,
        project,
        leak,
        samples,
        pageIndex
    };
}

export function selectSample(project, leak, selectedSample) {
  return (dispatch, getState) => {
    dispatch(setSelectedCoupleSample(selectedSample));
    const state = getState();
    if (selectedSample == null || _.isEmpty(selectedSample)) {
      const isDisplayAssessments = state.leaksList.leaksByProject.displayAssessments;
      if (isDisplayAssessments) {
        dispatch(actionAssessments.displayAssessments(false));
      }
    } else {
      // const path = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';
      const selectedSampleId = selectedSample.ID; //(path === 'couples') ? selectedSample.ID : selectedSample.coID;
      dispatch(actionSamplesOutput.getSampleOutputData(selectedSample));
      dispatch(actionSamplesOutput.getDeviceMessageData(selectedSampleId));
      dispatch(actionSamplesOutput.getTensorProbabilityData(selectedSampleId));
      dispatch(actionSamplesOutput.getTensorData(selectedSample));
      // const index = (selectedSample == null) ? 0 : selectedSample.RANKS - 1;
      // const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/resources/" + selectedSample.ID;
      // // not for case that we clean the selected sample
      // dispatch(requestSampleResources(project, leak, index, selectedSample.ID));
      // return fetchMiddleware(path, {}, getState).then(json => {
      //   const projectId = state.leaksList.selectedProject;
      //   const projectsList = state.leaksList.projectsList;
      //   const projectsObj = projectsList.items[projectsList.projectIndexMap[projectId]];
      //   const customerId = projectsObj.CustomerID;

      //   const prod4graphPath = `${endPoints.END_POINT}/graph/${customerId}/${selectedSample.ID}`;

      //   return fetchMiddleware(prod4graphPath, {}, getState).then((jsonGraph) => {
      //     if (jsonGraph.err) {
      //       console.log(jsonGraph.err);
      //     } else {
      //       processProd4Data(jsonGraph).then(prod4data => {
      //         json.data["Spectrogram"] = prod4data;
      //         dispatch(receiveSampleResources(project, leak, index, selectedSample.ID, json.data));
      //       });
      //     }
      //   })
      // });
    }
  }
}

const requestSampleResources = (project, leak, index, selectedSample) => {
  return {
    type: types.REQUEST_SAMPLE_RESOURCES,
    project,
    leak,
    selectedSample,
    index,
  };
}

const receiveSampleResources = (project, leak, index, selectedSample, resources) => {
  return {
    type: types.RECEIVE_SAMPLE_RESOURCES,
    project,
    leak,
    selectedSample,
    index,
    resources,
  };
}

