export const types = {
  Audio: 'Audio',
  Correlation: 'Correlation',
  FmReceiver: 'FmReceiver',
  FmReceiver2: 'FmReceiver2',
  Scheduler: 'Scheduler',
  TimeZone: 'TimeZone',
  SetLTEMode: 'SetLteMode',
  SetFmScan: 'SetFMScanList',
  FirmwareUpdate: 'FirmwareUpdate',
  UltraFirmwareUpdate: 'UltraFirmwareUpdate',
  ModemFirmwareUpdate: 'ModemFirmwareUpdate',
  SetServer: 'SetServer',
  Cmd_SetApn: 'Cmd_SetApn',
  Cmd_SetTimeSourcePref: 'Cmd_SetTimeSourcePref',
  Cmd_SetAGCConfig: 'Cmd_SetAGCConfig',
  SET_FALLBACK_SERVER: 'Cmd_SetFallbackServer',
  SET_CAPABILITY_REGISTER: 'Cmd_SetCapabilityRegister',
  TPC_IP_PACKET_CONFIGURATION: 'TPC_IP_PACKET_CONFIGURATION',
  SetLTEConnectionTimeout: 'Cmd_SetLTEConnectionTimeout',
  Adhoc_Correlation: 'SetAdhocSchedulerConfiguration',
  SetLeakValveMonitor: 'SetLeakValveMonitorList'
};

export const commands = {
  RESET: 'Reset',
  GET_SCHEDULER_CONFIG: 'GetSchedulerConfiguration',
  GET_AUDIO_CONFIG: 'GetAudioConfiguration',
  GET_CORRELATION_CONFIG: 'GetCorrelationConfiguration',
  GET_TIME_ZOME: 'GetTimeZone',
  GET_LTE_MODE: 'GetLteMode',
  SET_FM_SCAN: 'SetFMScanList',
  START_FM_SCAN: 'StartFMScanList',
  GET_FM_CHANNEL: 'GetFmChannel',
  DEACTIVATE: 'Cmd_Deactivation',
  RESET: 'CmdReset',
  ACTIVATE_TOOLKIT: 'Cmd_ActivateToolKitNow',
  GET_SERVER: 'GetServer',
  GET_APN: 'Cmd_GetApn',
  GET_AGC: 'Cmd_GetAGCConfig',
  GET_FALLBACK_SERVER: 'Cmd_GetFallbackServer',
  GET_CAPABILITY_REGISTER: 'Cmd_GetCapabilityRegister',
  GET_LTE_CONFIGURATION: 'Cmd_GetLTEConnectionTimeout',
  HIGH_PRIORITY_BIT_REQUEST: 'HighPriorityBitRequest',
  // GET_SET_LEAK_VALVE_MONITOR_CONFIG: 'SetLeakValveMonitorList',
};
