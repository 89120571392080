export const zIndexs = {
  map: 1,
  pipes: 2,
  interference: 2,
  projectsCoverage: 3,
  couplesCovarage: 3,
  couplePathLine: 4,
  couplePathStroke: 5,
  sops: 7,
  couplesSensors: 7,
  sensors: 8,
  waterMeter: 9,
  mobileSamples: 10,
  noiseSamples: 10,
  mobileNoiseSamples: 11,
  alerts: 12,
  mobileAlerts: 12,
  raster: 13,
  scale: 90,
};

export const featuresTypes = {
  ALERT: 'alert',
  NOISE_ALERT: 'noise_alert',
  VALVE_ALERT: 'valve_alert',
  PRESSURE_ALERT: 'prs_alert',
  SENSOR: 'sensor',
  SENSOR_ON_COUPLE: 'coupleSensor',
  SOP: 'sop',
  NOISE: 'noise',
  WATER_METER: 'waterMeter',
  ASSESSMENT: 'assessment',
  IQUARIUS_PUBLISHED_SAMPLES: 'iQuariusSamplesLayer',
  MOBILE_SAMPLES: 'MOBILE_SAMPLES',
  MOBILE_ALERT: 'mobileAlert',
  INTERFERENCE: 'interference',
  COUPLE_PATH: 'couplePath',
  COUPLES_COVERAGE: 'couplesCoverage',
  PROJECTS_COVERAGE: 'projectsCoverage',
  MOBILE_ALERTS: 'mobileAlerts',
  DMAS: 'dmas',
  PIPES: 'pipes',
  INSPECT_ALERTS: 'inspect_alert',
  PIPES_CONDITION_ASSESSMENTS: 'pca',
};
