import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import TextCell from '../../components/TextCell/TextCell';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

const G5BitSampleMessages = (props, context) => {
  const { data, timeZone, selectedBitSampleMessage, uiInfo: { pageHeight, pageWidth } } = props;

  let pWidth = 0.395 * (pageWidth - 10);
  let pHeight = 0.494 * pageHeight;

  const rowClassNameGetter = (rowIndex) => (
    (selectedBitSampleMessage.id == data[rowIndex].id) ? 'active-row' : ''
  );

  const handleRowClick = (_event, rowIndex) => {
    const selectedItem = data[rowIndex];
    props.selectBitSampleMessageFunc(selectedItem);
  }

  return (
    <Table
      rowsCount={data?.length || 0}
      width={pWidth}
      onRowClick={handleRowClick}
      rowClassNameGetter={rowClassNameGetter}
      maxHeight={pHeight - 117}
      headerHeight={46}
      rowHeight={30}>

      <Column
        columnKey="time_stamp"
        header={<Cell>{context.t('date')}</Cell>}
        cell={<TextCell data={data} field="time_stamp" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
        width={120}
        flexGrow={1}
      />
      <Column
        columnKey="audio_duration"
        header={<Cell>{context.t('seconds')}</Cell>}
        cell={<TextCell data={data} field="audio_duration" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_fs"
        header={<Cell>{context.t('freq')}</Cell>}
        cell={<TextCell data={data} field="audio_fs" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_gain"
        header={<Cell>{context.t('gain')}</Cell>}
        cell={<TextCell data={data} field="audio_gain" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_nc3"
        header={<Cell>{context.t('noise')}</Cell>}
        cell={<TextCell data={data} field="audio_nc3" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_nc3f1"
        header={<Cell>{context.t('low')}</Cell>}
        cell={<TextCell data={data} field="audio_nc3f1" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_nc3f2"
        header={<Cell>{context.t('med')}</Cell>}
        cell={<TextCell data={data} field="audio_nc3f2" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="audio_nc3f3"
        header={<Cell>{context.t('high')}</Cell>}
        cell={<TextCell data={data} field="audio_nc3f3" />}
        width={40}
        flexGrow={1}
      />
      {/* <Column
        columnKey="File"
        header={<Cell>{context.t('File')}</Cell>}
        width={40}
        flexGrow={1}
        cell={(props) =>
          <Cell {...props} >
            <a
              key='File'
              className="btn-wav-download"
              title={data[props.rowIndex].AudioDataS3Key}
              onClick={() => {
                const item = data[props.rowIndex];
                downloadFile(item.AudioDataS3Key, 'file');
              }}
              href='javascript:void(0);'>
                {context.t('Download')}
            </a>
          </Cell>
        }
      /> */}
    </Table>
  )
}

G5BitSampleMessages.contextTypes = {
  t: PropTypes.func.isRequired
}

export default G5BitSampleMessages;
