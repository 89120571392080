import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';

import SensorsLegend from './LegendTabs/SensorsLegend';
import AlertsLegend from './LegendTabs/AlertsLegend';
import PipeMaterialsLegend from './LegendTabs/PipeMaterialsLegend';
import SopTypesLegend from './LegendTabs/SopTypesLegend';

const classNames = require('classnames');
require('./Legend.scss');

export default class LegendTabsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLegend: false,
        };

        this.toggleManagerDisplay = this.toggleManagerDisplay.bind(this);
    }

    toggleManagerDisplay() {
        this.setState({ showLegend: !this.state.showLegend });
    }

    render() {
        const panelClasses = classNames(
            'map-legend',
            { 'show': this.state.showLegend }
        );
        return (
            <div className={panelClasses}>
                <div className="actions">
                    <i className="icon-toggle" onClick={this.toggleManagerDisplay} />
                </div>
                <h2>{this.context.t('legend')}</h2>
                <div className="tab">
                  <TabsContainer
                    tabs={[
                      { style: { height: 'inherit' }, label: this.context.t('sensors'), component: <SensorsLegend /> },
                      { style: { height: 'inherit' }, label: this.context.t('alerts'), component: <AlertsLegend /> },
                      { style: { height: 'inherit' }, label: this.context.t('pipes'), component: <PipeMaterialsLegend /> },
                      { style: { height: 'inherit' }, label: this.context.t('installation_points'), component: <SopTypesLegend /> },
                    ]}
                  />
                </div>
            </div>
        );
    }
}

LegendTabsContainer.contextTypes = {
    t: PropTypes.func.isRequired
};
