import {SYSTEM_RESOURCE} from '../../constants/EndPoints';
import {connect} from 'react-redux';
import * as actions from '../../actions/ProjectsActions';

import ProjectDetails from '../../components/ProjectsTabs/ProjectDetails';
import {setMapDrawMode} from "../../actions/setters";

const _ = require('lodash');
const misc = require('../../constants/Misc');
const diff = require('deep-diff').diff;

const mapStateToProps = (state, ownProps) => {
  let initialValues = {};
  const editedProject = state.manage.selectedManageItem.item;
  const isNew = state.manage.selectedManageItem.id == -1;
  const customerOptions = state.leaksList.customers.optionsList;
  const projectOptions = state.leaksList.optionList.options.ProjectMode;
  const mapDrawMode = state.mapState.drawMode.isActive;
  const drawState = state.mapState.drawMode;
  const userEditMode = state.leaksList.user.userEditMode;
  const projectCurrentBounding = editedProject.Bounding;
  const projectEditedPoints = state.manage.polygon;

  if (editedProject != null && state.manage.selectedManageItem.id !== -1) {
    Object.assign(initialValues, editedProject, {
      Bounding: JSON.stringify(editedProject.Bounding),
    });
  }

  return Object.assign({}, {
    initialValues: initialValues,
    customerOptions: customerOptions,
    projectOptions: projectOptions,
    projectEditedPoints: projectEditedPoints,
    projectCurrentBounding: projectCurrentBounding,
    mapDrawMode: mapDrawMode,
    isNew: isNew,
    id: initialValues.ID,
    drawState,
    userEditMode,
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const initialValues = props.initialValues;
      const isNewProject = initialValues.ID == null;
      let res = [];

      const callback = (result) => {
        let level;
        let message;

        if (result.status) {
          if (props.isNew){
            level = 'success';
            message = 'Project created successfuly';
          }
          else {
            level = 'success';
            message = 'Project updated successfuly';
          }
        } else {
          const error = result.err;
          level = 'error';
          if (error) {
            message = error.message;
          } else {
            message = 'Project creation failed';
          }
        }

        if (message != null) {
          ownProps.notify(message, level);
        }
      };

      if (isNewProject) {
        if (SYSTEM_RESOURCE == 'trimble') {
          // for trimble the field of 'UseFeetInMatlabGraphs' is invisible. set to TRUE:
          values.UseFeetInMatlabGraphs = true;
        }

        values.ShortName = values.ShortName.trim();

        dispatch(actions.createProject(values, callback));
      } else {
        const differences = diff(values, initialValues);

        // here we can change the props.manage.polygon to props.drawState.data
        if (differences != null || !_.isEqual(props.projectEditedPoints, props.projectCurrentBounding)) {
          if (differences != null) {
            differences.forEach((diff) => {
              const field = diff.path[0];
              const value = diff.lhs;
              const param = { field, value };
              res.push(param);
            });
          }
          dispatch(actions.updateProject(initialValues.ID, res, callback));
        }
      }
    },
    setProjectCenter: () => {
      ownProps.setProjectCenter();
    },
    onDelete: (id) => {
      dispatch(actions.deleteProject(id, (result) => {
        if (result.status) {
          ownProps.notify('Project deleted', 'success');
        } else {
          ownProps.notify('Project not deleted', 'error');
        }
      }));
    },
    UpdateIquariusPipes: (id) => {
      dispatch(actions.UpdateIquariusPipes(id, (result) => {
        if (result) {
          ownProps.notify('iQuarius pipes updated', 'success');
        } else {
          ownProps.notify('iQuarius pipes not updated', 'error');
        }
      }));
    },
    changeDrawMode: (props) => {
      if(props.mapDrawMode){
        dispatch(setMapDrawMode(false));
      } else {
        dispatch(setMapDrawMode(true, misc.DRAW_TYPES.POLYGON, props.projectCurrentBounding));
      }

    }
  };
};

const CProjectDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetails);

export default CProjectDetailsTab;
