export const getDateForExcelExports = () => {

    const exportDate = new Date();
    const day = String(exportDate.getDate()).padStart(2, '0');
    const month = String(exportDate.getMonth() + 1).padStart(2, '0');
    const year = String(exportDate.getFullYear());
    const formattedDate = `${year}${month}${day}`;

    return formattedDate;

}