/* eslint-disable react/no-multi-comp */
import Plotly from 'plotly.js';
import PropTypes from 'prop-types';
import React from 'react';

import Plot from 'react-plotly.js';
import ReactSelect from 'react-select';
import { PuffLoader } from 'react-spinners';

import groupBy from 'json-groupby';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import AppModal from './AppModal';

import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { Checkbox } from '@mui/material';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const graphMargin = 200;

function getMonthDays(year, month) {
  const now = new Date();
  const date = new Date(year, month, 0); // trd' param: 0 = the last day of the month
  const daysOnMonth =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth()
      ? now.getDate()
      : date.getDate();
  const days = [];
  for (let i = 0; i < daysOnMonth; i++) {
    const day = new Date(year, month - 1, i + 1, 0, 0, 0, 0);
    days.push(day);
  }
  return days;
}

function generateTabelBodyForPdf(activityData, data, days) {
  const result = [];
  let totalActive = 0;
  let totalNonActive = 0;
  const totalDayActive = new Array(days.length).fill(0);

  data.forEach((sensorData) => {
    // const activeDays = sensorData.dates;//.map((activeDayMillis) => new Date(activeDayMillis));
    const activeDays = sensorData.sampleData.map(
      (_obj, index) => index + 1
    ).length; //.map((activeDayMillis) => new Date(activeDayMillis));

    let activeDaysCount = 0;
    let nonActiveDaysCount = 0;
    const daysActiveRow = new Array(days.length).fill('X');

    // const deviceActivityData = activityData.find((deviceData) => deviceData.DeviceID === sensorData.deviceId);
    let installDate;
    if (sensorData.DeviceInstallationDate) {
      installDate = new Date(sensorData.DeviceInstallationDate);
    }

    const sDays = sensorData.sampleData;
    sDays.forEach((day, index) => {
      switch (day) {
        case 1: {
          daysActiveRow[index] = 'V';
          totalDayActive[index] += 1;
          activeDaysCount += 1;
          break;
        }
        case -1: {
          daysActiveRow[index] = 'X';
          nonActiveDaysCount += 1;
          break;
        }
        case 0: {
          daysActiveRow[index] = '-';
          break;
        }
      }

      // if (activeDays.indexOf(day.getDate()) !== -1) {
      //   daysActiveRow[index] = 'V';
      //   totalDayActive[index] += 1;
      //   activeDaysCount += 1;
      // } else if (!installDate || days.find(findItemInSameDayCallback(installDate)) > day) {
      //   daysActiveRow[index] = '-';
      // } else {
      //   daysActiveRow[index] = 'X';
      //   nonActiveDaysCount += 1;
      // }

      // if (activeDays.indexOf(day.getTime()) !== -1) {
      //   daysActiveRow[index] = 'V';
      //   totalDayActive[index] += 1;
      // }
    });

    const row = [
      sensorData.DeviceID /* Device ID*/,
      sensorData.SensorID /* Sensor ID*/,
      activeDaysCount /* Active Days */,
      nonActiveDaysCount /* Non Active Days */,
      ...daysActiveRow,
      // ...days.map((day) => {
      //   if (activeDays.indexOf(day.getTime()) === -1) return 'X';
      //   else return 'V';
      // })
    ];

    totalActive += activeDaysCount;
    totalNonActive += nonActiveDaysCount;

    result.push(row);
  });
  result.unshift(['', 'Total', totalActive, totalNonActive, ...totalDayActive]);
  return result;
}

const plotRenderContainerId = 'plot-render-container';

const xlsColumns = [
  {
    title: 'Installation Date',
    width: { wch: 20 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //pixels width
  {
    title: 'Device ID',
    width: { wch: 20 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //char width
  {
    title: 'Sensor ID',
    width: { wch: 20 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //char width
  {
    title: 'SIM',
    width: { wch: 25 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Boundary',
    width: { wch: 25 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Installation Point ID',
    width: { wch: 22 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Days Worked',
    width: { wpx: 100 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Days not Worked',
    width: { wpx: 100 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Potential Active Days',
    width: { wch: 23 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: '%',
    width: { wpx: 70 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
];

export default function SensorsActivityModal(props, context) {
  const [selectedMonth, setSelectedMonth] = React.useState(
    new Date().toISOString().substring(0, 'YYYY-MM'.length)
  );
  const [xlsData, setXlsData] = React.useState({});
  const [selectedBoundary, setSelectedBoundary] = React.useState([]);
  const [isIncludeArchived, setIsIncludeArchived] = React.useState(false);

  React.useEffect(() => {
    // set the data for export to xlsx:
    if (props.data) {
      const data = [
        {
          columns: xlsColumns,
          data: props.data.map((item) => {
            const totalActiveDays = item.sampleData.filter(
              (d) => d === 1
            ).length;
            const unWorkedDaysInMonth = item.sampleData.filter(
              (d) => d === -1
            ).length;

            /*
             * "installDaysCount": for sensors that installed at the middel of the month:
             * acording installation date we got wrong value of Potential Days
             * so we take the MAX of "daysInstalled" - the TRUE value days & "installDaysCount"
             */
            const installDaysCount = Math.max(
              item.daysInstalled.filter(Boolean).length,
              totalActiveDays
            );
            // const unWorkedDaysInMonth = installDaysCount - totalActiveDays;
            const activePrecentage =
              totalActiveDays + unWorkedDaysInMonth === 0
                ? ''
                : (
                    (totalActiveDays /
                      (totalActiveDays + unWorkedDaysInMonth)) *
                    100
                  ).toFixed();

            return [
              {
                value: new Date(
                  item.DeviceInstallationDate
                ).toLocaleDateString(),
                style: { font: { sz: '12' } },
              },
              {
                value: Number(item.DeviceID),
                style: { font: { sz: '12' }, numFmt: '0' },
              },
              {
                value: Number(item.SensorID),
                style: { font: { sz: '12' }, numFmt: '0' },
              },
              { value: item.SIMnumber || '', style: { font: { sz: '12' } } },
              { value: item.DmaID || '', style: { font: { sz: '12' } } },
              { value: Number(item.SOPointID), style: { font: { sz: '12' } } },
              { value: Number(totalActiveDays), style: { font: { sz: '12' } } },
              {
                value: Number(unWorkedDaysInMonth),
                style: { font: { sz: '12' } },
              },
              { value: installDaysCount, style: { font: { sz: '12' } } },
              {
                value: Number(activePrecentage),
                style: { font: { sz: '12', numFmt: '0%' } },
              },
            ];
          }),
        },
      ];
      setXlsData(data);
    }
  }, [props.data]);

  const { month, year } = props;
  let pieData = [];

  let activityData = [];
  let daysActivityReport = [];
  let daysForPercent = [];

  // if (props.data && props.data.activityData) {
  //   activityData = props.data.activityData;
  // }
  if (props.data) {
    daysActivityReport = props.data;
    activityData = props.data.map((sensor) => ({
      DaysCount: sensor.sampleData.filter((d) => d === 1).length,
    }));
    daysForPercent = props.data.filter(
      (sensor) => sensor.SensorStatus !== 'Not Installed'
    );
  }

  const totalSampledDays = React.useMemo(
    () =>
      props.data?.reduce(
        (total, current) =>
          total + current.sampleData.filter((x) => x === 1).length,
        0
      ),
    [props.data]
  );

  const totalUnSampledDays = React.useMemo(
    () =>
      props.data?.reduce(
        (total, current) =>
          total + current.sampleData.filter((x) => x === -1).length,
        0
      ),
    [props.data]
  );

  const totalSampledDaysWithoutEmpty = React.useMemo(
    () =>
      daysForPercent?.reduce(
        (total, current) =>
          total + current.sampleData.filter((x) => x === 1).length,
        0
      ),
    [props.data]
  );

  const totalUnSampledDaysWithoutEmpty = React.useMemo(
    () =>
      daysForPercent?.reduce(
        (total, current) =>
          total + current.sampleData.filter((x) => x === -1).length,
        0
      ),
    [props.data]
  );

  if (activityData.length > 0) {
    const attrGroup = groupBy(activityData, ['DaysCount']);
    const keys = Object.keys(attrGroup).map(Number);
    const texts = keys.map((numVal) => {
      if (numVal > 5) return `${numVal}`;
      return '';
    });
    const values = Object.values(attrGroup).map((arr) => arr.length);

    pieData.push({
      type: 'pie',
      labels: keys,
      values: values,
      text: texts,
      // textinfo: "label+percent",
      texttemplate: '%{label} days, %{percent}',
      hovertemplate: '%{label} days - %{value} sensors',
      textposition: 'intside',
      textinfo: 'text',
      // hoverinfo: 'skip',
    });

    // for (var i = 0; i < pieData[0].values.length; i++) {
    //   if (pieData[0].values[i] < 5) { // if less then 5 days:
    //     pieData[0].text[i] = '';
    //   } else {
    //     pieData[0].text[i] = `${pieData[0].values[i]}`;
    //     pieData[0].textinfo = 'percent';
    //     pieData[0].texttemplate = '%{label} days, %{percent}';
    //   }
    // }
  }

  // console.log('PIE DATA VALUES', pieData);

  const pieLayout = {
    showlegend: false,
    height: 500,
    width: 600,
    margin: {
      t: graphMargin - graphMargin * 0.8,
      b: graphMargin,
      l: graphMargin,
      r: graphMargin,
    },
  };

  async function exportPdf() {
    const plotElement = await Plotly.newPlot(
      plotRenderContainerId,
      pieData,
      pieLayout
    );
    const plotImage = await Plotly.toImage(plotElement, { format: 'png' });

    const monthDays = getMonthDays(year, month);
    const tableBody = generateTabelBodyForPdf(
      daysActivityReport,
      daysActivityReport,
      monthDays
    );
    const tableHeader = [
      'Device ID',
      'Sensor ID',
      'Active',
      'Non Active',
      ...monthDays.map((d) => d.getDate()),
    ];

    const doc = new jsPDF({ orientation: 'landscape' });
    const horizontalPadding = 10;
    doc.setFontSize(16);
    doc.text(`Sensors Activity Report`, horizontalPadding, 10);
    doc.setFontSize(12);
    doc.text(`generate for ${month}/${year}`, horizontalPadding, 25);

    doc.addImage(
      plotImage,
      'PNG',
      horizontalPadding,
      30,
      120,
      100,
      'pie graph',
      'NONE',
      0
    );
    doc.autoTable({
      styles: {
        valign: 'center',
        fontSize: 5,
      },
      startX: 0,
      startY: 110,
      columns: tableHeader,
      body: tableBody,
      minCellWidth: 5,
      cellPadding: 2,
      didParseCell: (data) => {
        if (data.section === 'body') {
          data.cell.cellPadding = 5;

          switch (data.cell.raw) {
            case 'V':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'green';
              break;
            case 'X':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'red';
              break;

            default:
              break;
          }
        }
      },
    });

    doc.save('Sensors Activity Report');
  }

  function selectMonth(e) {
    // console.log(e.target.value);
    setSelectedMonth(e.target.value);
  }

  function loadData() {
    const [sYear, sMonth] = selectedMonth.split('-').map(Number);
    const boundaries = selectedBoundary.map((option) => option.value);
    props.loadData(sYear, sMonth, boundaries, isIncludeArchived);
  }

  const loadingView = (
    <div style={{ margin: 'auto', width: 'fit-content' }}>
      <PuffLoader size={100} loading />
    </div>
  );
  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth='xl'
      open={props.isVisible}
      title={context.t('sensors_activity')}
      onClose={props.close}
      content={
        <div
          style={{
            height: '80vh',
          }}
        >
          {props.isFetching && loadingView}
          {!props.isFetching && props.selectedProject && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-around',
                  width: '45%',
                }}
              >
                <div style={{ float: 'left', marginRight: '10px' }}>
                  <Typography component='p' variant='p'>
                    {context.t('select_month')}
                  </Typography>
                  <input
                    type='month'
                    min='2016-01'
                    onChange={selectMonth}
                    value={selectedMonth}
                  />
                </div>
                {JSON.parse(
                  process.env.REACT_APP_FILTER_SENSORS_REPORT_BOUNDARIES
                ) && (
                  <div
                    style={{
                      float: 'left',
                      maxHeight: '50px',
                      minWidth: '250px',
                      marginRight: '10px',
                    }}
                  >
                    <Typography component='p' variant='p'>
                      {context.t('boundary')}
                    </Typography>
                    <ReactSelect
                      isMulti
                      placeholder={context.t('all_boundaries_selected')}
                      menuShouldScrollIntoView={false}
                      value={selectedBoundary}
                      onChange={(options) => {
                        setSelectedBoundary(options);
                      }}
                      options={props.dmasList.map(({ DmaID, Comment }) => ({
                        label: `${Comment} ${DmaID}`,
                        value: DmaID,
                      }))}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'flex-end',
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    padding: '2px 10px',
                  }}
                >
                  <Typography
                    component='label'
                    variant='label'
                    htmlFor='checkbox-include-archived'
                    style={{ fontWeight: 'normal' }}
                  >
                    {context.t('include_archived_sensors')}
                  </Typography>

                  <Checkbox
                    id='checkbox-include-archived'
                    color='primary'
                    checked={isIncludeArchived}
                    onChange={(e) => setIsIncludeArchived(e.target.checked)}
                  />
                </div>
                <Button
                  style={{ margin: 'auto' }}
                  variant='contained'
                  color='primary'
                  onClick={loadData}
                >
                  {context.t('generate_report')}
                </Button>
              </div>

              <div style={{ display: 'inline-flex' }}>
                <div id={plotRenderContainerId} style={{ display: 'none' }}>
                  {context.t('hidden_div_for_generate_plotly_image')}
                </div>
                <Plot data={pieData} layout={pieLayout} />
                <SensorsActivityReportTable
                  context={context}
                  data={daysActivityReport}
                  activityData={daysActivityReport}
                  date={new Date(year, month, 0)} // trd' param: 0 = the last day of the month
                  year={year}
                  month={month}
                />
              </div>

              {props.user.technicianAdvanced && (
                <div
                  style={{
                    width: 'fitContent',
                    padding: '10px',
                    margin: '3px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    position: 'absolute',
                    bottom: '10%',
                    left: '17%',
                  }}
                >
                  <p>
                    <span>
                      {(
                        (totalSampledDaysWithoutEmpty /
                          (totalSampledDaysWithoutEmpty +
                            totalUnSampledDaysWithoutEmpty)) *
                        100
                      ).toFixed(1) || ''}
                      %
                    </span>
                    {props.user.isAQS && (
                      <span style={{ margin: '15px' }}>
                        [{' '}
                        {(
                          (totalSampledDaysWithoutEmpty /
                            (getMonthDays(year, month).length *
                              daysForPercent.length)) *
                          100
                        ).toFixed(1) || ''}
                        % ]
                      </span>
                    )}
                  </p>
                  <p>
                    {context.t('v_x2', {
                      totalSampledDaysWithoutEmpty,
                      totalUnSampledDaysWithoutEmpty,
                    })}
                    {/* Install: {daysForPercent.reduce((total, current) => total + current.daysInstalled.filter((x) => x).length, 0)} */}
                    <p>{context.t('not_include_not_installed_sensors')}</p>
                  </p>

                  <hr />

                  <p>
                    <span>
                      {(
                        (totalSampledDays /
                          (totalSampledDays + totalUnSampledDays)) *
                        100
                      ).toFixed(1) || ''}
                      %
                    </span>
                    {props.user.isAQS && (
                      <span style={{ margin: '15px' }}>
                        [{' '}
                        {(
                          (totalSampledDays /
                            (getMonthDays(year, month).length *
                              daysForPercent.length)) *
                          100
                        ).toFixed(1) || ''}
                        % ]
                      </span>
                    )}
                  </p>
                  <p>
                    {context.t('v_x', { totalSampledDays, totalUnSampledDays })}
                    {/* Install: {daysForPercent.reduce((total, current) => total + current.daysInstalled.filter((x) => x).length, 0)} */}
                  </p>
                </div>
              )}
              <div>
                <Button
                  style={{ width: 'max-content' }}
                  variant='contained'
                  color='primary'
                  size='small'
                  startIcon={<SaveIcon />}
                  onClick={exportPdf}
                >
                  {context.t('save_as_pdf')}
                </Button>

                <ExcelFile
                  filename={`${year}${month} Sensors Activity - ${props.selectedProject?.Name}`}
                  element={
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      startIcon={<SaveIcon />}
                    >
                      {context.t('save_as_excel')}
                    </Button>
                  }
                >
                  <ExcelSheet dataSet={xlsData} name={'Sensors Activity'} />
                </ExcelFile>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}

SensorsActivityModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

SensorsActivityModal.propTypes = {
  close: PropTypes.func,
  data: PropTypes.objectOf({
    activityData: PropTypes.arrayOf({
      DaysCount: PropTypes.number,
      DeviceID: PropTypes.string,
    }),
    daysReport: PropTypes.arrayOf({
      deviceId: PropTypes.string,
      dates: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  loadData: PropTypes.func,
  month: PropTypes.number,
  selectedProject: PropTypes.object,
  year: PropTypes.number,
};

SensorsActivityModal.defaultProps = {
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
};

/**
 *
 * @param {*} props
 * @returns {React.FC}
 */

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '600px',
  },
  cell: {
    borderLeft: '1px solid #eee',
    '&.$nth-of-type(odd)': {
      background: '#aaa',
    },
  },
  select: {
    width: '130px',
  },
});

function findItemInSameDayCallback(checkedDay) {
  return (sensorActiveDay) => {
    let isSensorWorked = false;
    if (
      checkedDay.getFullYear() === sensorActiveDay.getFullYear() &&
      checkedDay.getMonth() === sensorActiveDay.getMonth() &&
      checkedDay.getDate() === sensorActiveDay.getDate()
    ) {
      isSensorWorked = true;
    }
    return isSensorWorked;
  };
}

function SensorsActivityReportTable(props, context) {
  const classes = useStyles();
  const { year, month, activityData } = props;

  const days = getMonthDays(year, month);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell}>
              {context.t('sensor_id')}
            </TableCell>
            <TableCell className={classes.cell}>
              {context.t('current_device_id')}
            </TableCell>
            <TableCell className={classes.cell}>
              {context.t('current_status')}
            </TableCell>
            <TableCell className={classes.cell}>{context.t('total')}</TableCell>
            {days.map((day) => (
              <TableCell
                className={classes.cell}
                padding='none'
                key={day.getDate()}
              >
                {day.getDate()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((sensorData, index) => {
            const activeDays = sensorData.sampleData; //.map((activeDayMillis) => new Date(activeDayMillis));

            return (
              <TableRow hover key={index}>
                <TableCell className={classes.cell}>{index + 1}</TableCell>
                <TableCell className={classes.cell}>
                  {sensorData.SensorID}
                </TableCell>
                <TableCell className={classes.cell}>
                  {sensorData.DeviceID}
                </TableCell>
                <TableCell className={classes.cell}>
                  {sensorData.SensorStatus}
                </TableCell>
                <TableCell className={classes.cell}>
                  {sensorData.sampleData.filter((x) => x === 1).length}
                </TableCell>
                {days.map((day, index) => {
                  let char;
                  let style;

                  switch (activeDays[index]) {
                    case 1:
                      char = <span>&#x2714;</span>; // V
                      style = { color: 'green' };
                      break;
                    case -1:
                      char = <span>&#x2718;</span>; // X
                      style = { color: 'red' };
                      break;
                    case 0:
                      char = <span>-</span>; // -
                      style = { color: 'gray' };
                      break;
                  }

                  return (
                    <TableCell
                      className={classes.cell}
                      style={style}
                      padding='none'
                      key={day.getTime()}
                    >
                      {char}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SensorsActivityReportTable.propTypes = {
  activityData: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.objectOf({
      deviceId: PropTypes.number,
      dates: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  date: PropTypes.objectOf(Date),
  month: PropTypes.number,
  year: PropTypes.number,
};
SensorsActivityReportTable.contextTypes = {
  t: PropTypes.func,
};
