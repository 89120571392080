import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Select from '../Select/Select';
import Input from '../Input/Input';
import AddressField from '../Custom/AddressField';

const classNames = require('classnames');



const isValidCoordinate = (value) => {
  // console.log('Coordinate value:', value);

  if (value) {
    const coords = Array.isArray(value) ? value : value.split(',').map(coord => coord.trim());
    console.log('coords', coords);


    if (coords.length !== 2) {
      return 'Invalid coordinates format';
    }

    const [lat, lon] = coords.map(coord => parseFloat(coord));

    if (
      isNaN(lat) || isNaN(lon) ||
      lat < -90 || lat > 90 ||
      lon < -180 || lon > 180
    ) {
      return 'Invalid coordinates';
    }
  }

  return undefined;
};



const AddAlertForm = (props, context) => {

  const { isOpen, doClose, user, options, onCreateNewValue, couplesIdsData, submitting, handleSubmit, getAddressByLatLong } = props;

  const panelClasses = classNames(
    'add-alert-form',
    { 'show': isOpen }
  );

  const formClass = 'form-group auto-center';
  const labelsClass = 'col-xs-3 control-label';
  const fieldsClass = 'col-xs-8 control-input';
  const divStyle = { float: 'left', width: '100%' };

  const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
  const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
  const maxValue = max => value => value && value > max ? `Must be at most ${max}` : undefined;
  const minValue0 = minValue(0);
  const maxValue100 = maxValue(100);


  return (
    <div className={panelClasses}>
      <div className="new-alert-header">
        <div className="actions">
          <i className="icon-close" onClick={doClose} />
        </div>
        <h2>{context.t('add_new_alert')}</h2>
      </div>
      <form
        className="form-horizontal sticky-actions"
        onSubmit={(e) => {
          handleSubmit(e)
        }}>
        <div style={{ display: 'flex' }}>
          <div style={divStyle}>
            {/* Intensity */}
            {/*
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Intensity')}</label>
              <div className={fieldsClass}>
                <Field name="Intensity"
                  component="input"
                  type="number"
                  className="form-control" />
              </div>
            </div>
            */}

            {/* Priority */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('priority')}</label>
              <div className={fieldsClass}>
                <Field name="ABC"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={context.t('select')}
                    options={options.ABC}
                  />)
                  }
                />
              </div>
            </div>

            {/* Probability */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('probability')}</label>
              <div className={fieldsClass}>
                <Field name="Probability"
                  component={Input}
                  type="number"
                  className="form-control"
                  placeholder='0'
                  initialValues={props.initialValues}
                  validate={[number, minValue0, maxValue100]}
                  disabled={!user.editMode}
                />
              </div>
            </div>

            {/* Detection Date */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('detection_date')}:</label>
              <div className={fieldsClass}>
                <Field name="DetectionDateTime"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Repair Date */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('repair_date')}:</label>
              <div className={fieldsClass}>
                <Field name="fixDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Type */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('type')}</label>
              <div className={fieldsClass}>
                <Field name="AlertType"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={context.t('select')}
                    options={options.AlertType}
                  />)
                  }
                />
              </div>
            </div>

            {/* Status */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('status')}</label>
              <div className={fieldsClass}>
                <Field name="AlertState"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={context.t('select')}
                    options={options.AlertState}
                  />)
                  }
                />
              </div>
            </div>
          </div>

          {/* RIGHT COLUMN */}
          <div style={divStyle}>

            {/* Repair Details */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('repair_details')}:</label>
              <div className={fieldsClass}>
                <Field name="RepairCode"
                  disabled={!user.editMode}
                  component={(props) =>
                  (<Select {...props}
                    key={"RepairCode"}
                    placeholderTitle={context.t('select')}
                    options={options.PCARepairCodesEnum}
                    onCreateNewValue={onCreateNewValue}
                  />)
                  }
                />
              </div>
            </div>

            {/* Work Area */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('work_area')}:</label>
              <div className={fieldsClass}>
                <Field name="WorkArea"
                  disabled={!user.editMode}
                  creatable={true}
                  component={(props) =>
                  (<Select {...props}
                    key={"WorkArea"}
                    placeholderTitle={context.t('select')}
                    options={options.WorkAreas}
                  />)
                  }
                />
              </div>
            </div>

            {/* Asset ID */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('asset_id')}:</label>
              <div className={fieldsClass}>
                <Field name="KavMaim"
                  component={Input}
                  type="number"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Couple ID */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('couple_id')}:</label>
              <div className={fieldsClass}>
                <Field name="CoupleID"
                  disabled={!user.editMode}
                  component={(props) =>
                  (<Select {...props}
                    key={"CoupleID"}
                    placeholderTitle={context.t('select')}
                    options={couplesIdsData}
                  />)
                  }
                />
              </div>
            </div>
            {/* Length */}
            {/* <div className={formClass}>
              <label className={labelsClass}>{context.t('Length')}:</label>
              <div className={fieldsClass}>
                <Field name="Length"
                       component={Input}
                       type="number"
                       className="form-control"
                       disabled={!user.editMode} />
              </div>
            </div> */}

          </div>
        </div>

        {/* X, Y */}
        {/* Latitude, Longitude */}
        <div className="form-group">
          <label className={'col-xs-2 control-label'}>{context.t('coordinate')}:</label>
          <div className={fieldsClass}>
            <Field name="Coordinate"
              placeholder={context.t('click_on_map_to_set_coordinate')}
              component={Input}
              type="text"
              className="form-control"
              // validate={[isValidCoordinates(value[0], value[1])]}
              validate={[isValidCoordinate]}
            />
          </div>
        </div>

        {/* Address */}
        <AddressField
          className="form-group"
          fieldClassName={fieldsClass}
          user={user}
          fieldName="StreetAddress"
          onGetAddress={getAddressByLatLong} />

        {/* Comment */}
        <div className="form-group">
          <label className={'col-xs-2 control-label'}>{context.t('comment')}:</label>
          <div className={fieldsClass}>
            <Field name="Comment"
              component={Input}
              type="text"
              className="form-control"
              disabled={!user.editMode} />
          </div>
        </div>

        <div className="form-actions text-right">
          <a
            className="btn btn-info"
            onClick={doClose}>{context.t('cancel')}</a>
          <button
            className="btn btn-success"
            type="submit"
            disabled={submitting}>{context.t('save')}</button>
        </div>
      </form>
    </div>
  );
}

// validators:
const validate = (values) => {
  const errors = {};

  // check Required fields:
  const requiredFields = ['Priority', 'DetectionDateTime', 'AlertType', 'AlertState', 'Coordinate', 'fixDate'];
  requiredFields.forEach((fieldKey) => {
    if (values[fieldKey] == null || values[fieldKey] === '') {
      errors[fieldKey] = 'Required';
    }
  });

  if (values.Intensity && values.Intensity <= 0 || values.Intensity > 32767) {
    errors.Intensity = 'Illegal Value';
  }

  return (errors);
};

AddAlertForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const addAlertForm = reduxForm({
  form: 'add-alert', // a unique name for this form
  enableReinitialize: true,
  validate,
})(AddAlertForm);

export default addAlertForm;
