// @flow

import React from 'react';
import { Provider } from 'react-redux';

// import { Router } from 'react-router';
// import DevTools from "./DevTools"
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import CookieConsentComponent from '../components/CookieConsent/CookieConsentComponent';

import configureStore from './store/configureStore';

import { userLogin, userLogout, loadDataOnLoggedIn } from '../actions/LoginActions';

import Routes from './routes';

import * as fb from '../services/appAuth';
// import { SENTRY_URL } from './config';

// If you use React Router, make this component
// render <Router> with your routes. Currently,
// only synchronous routes are hot reloaded, and
// you will see a warning from <Router> on every reload.
// You can ignore this warning. For details, see:
// https://github.com/reactjs/react-router/issues/2182

//window.Raven && Raven.config(SENTRY_URL).install();
const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);

const listener = {
  onAuthStateChanged: (user) => {
    if (user) {
      //console.log(user);
    } else {
      // console.log('no user');
    }
  },
  logout: () => {
    store.dispatch(userLogout());
  },
  loggingIn: (user) => {
    store.dispatch(userLogin(user.refreshToken, user.uid, user));
    store.dispatch(loadDataOnLoggedIn());
  }
};

// fb.init(listener);
fb.default.setListener(listener);
const Root = () => (
  <Provider store={store}>
    <CookieConsentComponent />
    <Routes history={history} />
  </Provider>
);

export default Root;
