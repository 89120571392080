import { routerActions } from 'react-router-redux';
import locationHelperBuilder from 'redux-auth-wrapper/history3/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';

import I18nLoading from "../components/Loading/I18nLoading";

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => state.user.user !== null && !state.user.isLoadingData,
  authenticatingSelector: (state) => state.user.user !== null && state.user.isLoadingData,
  AuthenticatingComponent: I18nLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
});

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.user.isAdmin,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAdmin'
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/alerts',
  allowRedirectBack: false,
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: (state) => state.user.user === null,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsNotAuthenticated'
});
