import {connect} from 'react-redux';
import * as actionsInspect from '../../actions/InspectActions';
import * as actionsAlerts from '../../actions/AlertsActions';
import {setIndexes} from '../../actions/MiddlewareActions';
import InspectDataTabs from '../../components/Inspect/InspectDataTabs';
import {getUIinfo} from '../../containers/UIhelper/UIhelper';
import {getPcaForAlert} from "../../actions/actions";

// Attributes to be shown in pibottable
let alertsFields = ['PipeDiameterInch', 'Event Year', 'Material', 'PlacementYear'];

const mapStateToProps = (state, ownProps) => {
    const selectedProject = state.leaksList.selectedProject;
    const alertsDataSet = state.leaksList.inspect.alerts.data;
    const selectedAlerts = state.leaksList.inspect.selectedAlerts;
    const alertsState = state.leaksList.optionList.options.AlertState;
    const alertsType = state.leaksList.optionList.options.AlertType;

    const selectedProjectId = state.leaksList.selectedProject;
    const selectedProjectItem = state.leaksList.projectsList.items[state.leaksList.projectsList.projectIndexMap[selectedProjectId]];
    const isDisplayCityColumn = selectedProjectItem != null ? selectedProjectItem.IsDisplayCityColumn : false;

    const selectedFeature = state.leaksList.leaksByProject.selectedFeature;

    let sortedAlertsDataSet = [];

    // Extract ONLY the 'alertsFields' from the 'alertsDataSet'
    alertsDataSet.forEach( alert => {
        let newAlertObj = {};
        alertsFields.forEach( field => {
            newAlertObj[field] = alert[field];
        });
        sortedAlertsDataSet.push(newAlertObj);
    });

    return {
        selectedProject,
        sortedAlertsDataSet,
        selectedAlerts,
        getUIinfo,
        alertsState,
        alertsType,
        isDisplayCityColumn,
        selectedFeature,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        importAlertInvestigation: (selectedProject) => {
            dispatch(actionsInspect.investigateAlerts(selectedProject, 'json', true));
        },
        onAlertRowClick: (selectedAlert) => {
            dispatch(actionsInspect.selectInspectAlert(selectedAlert));
        },
        openPCA: (alert) => {
            dispatch(actionsInspect.selectInspectAlert(alert));
            dispatch(getPcaForAlert(alert.ID));
            dispatch(actionsAlerts.setOpenPCA(true, false));
        },
        openLeakGrowth: (alert) => {
          dispatch(actionsInspect.selectInspectAlert(alert));
          dispatch(actionsAlerts.setOpenPcaLeakGrowth(alert, true));
        },
        showAll: () => {
            dispatch(actionsInspect.setVisibleAlerts({}));
        },
        sortLocaly: (field, dir) => {
            dispatch((dispatch, getState) => {
              const state = getState();

              const alertsDataSet = state.leaksList.inspect.alerts.data;
              const sortedAlerts = alertsDataSet.sort((a, b) => {
                let nRc = 0;

                const firstItem = (dir == 'asc') ? a : b;
                const secondItem = (dir == 'asc') ? b : a;

                // if (field === 'Burst') {
                //   if (
                //     (firstItem.Burst && secondItem.Burst == false) ||
                //     ((!firstItem.Burst && firstItem.isSuspicious) && (!secondItem.Burst && !secondItem.isSuspicious))
                //     ) {
                //     nRc = 1;
                //   } else {
                //     nRc = -1;
                //   }
                // } else {
                //   if (firstItem[field] > secondItem[field]) nRc = 1;
                //   if (firstItem[field] < secondItem[field]) nRc = -1;
                // }
                if (firstItem[field] > secondItem[field]) nRc = 1;
                else if (firstItem[field] < secondItem[field]) nRc = -1;
                else nRc = 0

                return nRc;
              });
              const indexMap = setIndexes(sortedAlerts, 'id');

              dispatch(actionsInspect.sortAlertsLocaly(sortedAlerts, indexMap, field, dir));
            });
          }

    };
};

const CInspectDataTabs = connect(mapStateToProps, mapDispatchToProps)(InspectDataTabs);

export default CInspectDataTabs;
