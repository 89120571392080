// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth";
import {
  getMultiFactorResolver,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator
} from 'firebase/auth';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';

const INVALID_PHONE_NUMBER = 'auth/invalid-phone-number';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

let onAuthStateChangeListener = null;

const app = firebase.initializeApp(config);
const auth = getAuth(app);
firebase.auth().onAuthStateChanged(function (user) {
  // console.log('onAuthStateChanged', user);
  if (onAuthStateChangeListener != null) {
    onAuthStateChangeListener.onAuthStateChanged(user);

    if (!user) {
      onAuthStateChangeListener.logout();
    } else {
      onAuthStateChangeListener.loggingIn(user);
    }
  }
});

export function setListener(listener) {
  onAuthStateChangeListener = listener;

  const auth = firebase.auth();
  const user = auth.currentUser;
  if (!user) {
    onAuthStateChangeListener.logout();
  } else {
    onAuthStateChangeListener.loggingIn(user);
  }
}

export function getCurrentUser() {
  const auth = firebase.auth();
  const user = auth.currentUser;
  return user;
}

export function signInWithEmailAndPassword(email, password) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        resolve(userCredential.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut() {
  return firebase.auth().signOut();
}

export function sendPasswordResetEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}
let verificationId = null;
let multiFactorResolver = null

export const getMfaResolver = (error) => {
    multiFactorResolver = getMultiFactorResolver(auth, error)
    return multiFactorResolver
}

export const startMfaSignin = async (
    multiFactorHint,
    session
) => {
    const recaptchaVerifier = new RecaptchaVerifier('recaptcha',
        { size: "invisible"}, auth)

    if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
            multiFactorHint: multiFactorHint,
            session: session
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        // Send SMS verification code
        try {
            verificationId = await phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        } catch (error) {
            console.log(error);
        }


    } else {
    }
}

export const finishMfaSignIn = async (
  verificationCode
) => {
  // Get the SMS verification code sent to the user.
  if (verificationId && multiFactorResolver) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    try {
      await multiFactorResolver.resolveSignIn(multiFactorAssertion);
      // User successfully signed in with MFA, do whatever necessary next steps.
    } catch (error) {
      // Handle the case where the user enters the wrong MFA code
      console.error("Wrong MFA code. Please insert the correct code to login.");
      // You might want to throw an error or return to the MFA insertion page
      // Here, I'll throw an error to indicate the wrong code
      throw new Error("Wrong MFA code. Please insert the correct code to login.");
    }
  }

  multiFactorResolver = null;
  verificationId = null;
}

        // Complete sign-in.
        // await multiFactorResolver
        //     .resolveSignIn(multiFactorAssertion)
        //     .then(function (userCredential) {
        //         // User successfully signed in with the second factor phone number.
        //     })
        //     .catch(function (error) {
        //         throw error;
        //     });

