import { connect } from 'react-redux';
import actions, { setDefFilters, setFilters } from '../../actions/AlertsValveActions';
import { FILTERS_VALVE_ALERTS } from '../../constants/SettingsKeys';

import { invalidateProject } from '../../actions/setters';
import ValveAlertsSettings from '../../components/AlertsSettings/ValveAlertsSettings';


const mapStateToProps = (state, ownProps) => {
    const {
        selectedProject
    } = state.leaksList;
    const user = state.leaksList.user;
    const initialFilters = {};
    const valveFilters = state.valve.filters;
    valveFilters.forEach((filter) => {
        if (filter.op && filter.op === 'range') {
            if (filter.from) {
                initialFilters[filter.field + '-From'] = filter.from;
            }
            if (filter.to) {
                initialFilters[filter.field + '-To'] = filter.to;
            }
        } else {
            initialFilters[filter.field] = filter.value;
        }
    });

    return Object.assign({}, {
        user,
        selectedProject,
        initialValues: initialFilters,
        optionList: state.leaksList.optionList.options
    }, ownProps);
}


const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit: (values, dispatch, props) => {
        const {
            selectedProject,
            processFilters,
            cleanEmptyFilters
        } = props;



        var filters = processFilters(Object.assign({}, values));
        delete filters.defFilters.sortBy;
        delete filters.defFilters.sortByDir;
        dispatch(invalidateProject(selectedProject));
        dispatch(setDefFilters(filters.defFilters)); //here todo
        const cleanFilters = cleanEmptyFilters(filters.filters);
        dispatch(setFilters(cleanFilters));
        dispatch(actions.fetchValveAlerts(selectedProject)); //here todo

        localStorage.setItem(FILTERS_VALVE_ALERTS, JSON.stringify(filters.defFilters));
    }
});

const CValveAlertsSettings = connect(
    mapStateToProps,
    mapDispatchToProps
)(ValveAlertsSettings);

export default CValveAlertsSettings;
