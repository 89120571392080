import { connect } from 'react-redux';

import MobileTasksSettings from '../../components/MobileTasksSettings/MobileTasksSettings';
import _ from 'lodash';
import {
  filterTasks,
  setSelectedTask,
  sortTasksLocally,
} from '../../actions/MobileTasksActions';

import {getSamplesAction, setSamplesFilters} from "../../actions/actions";

const mapStateToProps = (state, ownProps) => {
  const { selectedProject } = state.leaksList;
  const sampleStateOptions = state.leaksList.optionList.options.SampleState;
  const mobileState = state.mobile;
  const tasksList = mobileState.tasks.items;
  const sort = mobileState.tasks.mobileTasksSort;
  const samplesFiletrs = mobileState.samples.filters;

  const samplesStatusFilters = samplesFiletrs
    .filter((f) => f.field === 'sample_state')
    .reduce((obj, current) => obj[current.field] = current.value, {});

  return {
    selectedProject,
    sampleStateOptions,
    tasksList,
    initialValues: {
      Tasks: {
        sortBy: _.upperFirst(_.camelCase(sort.field)),
        sortByDir: sort.dir,
        'CreationDate-From': mobileState.tasks.mobileTasksFilters.defFilters["CreationDate-From"],
        'CreationDate-To': mobileState.tasks.mobileTasksFilters.defFilters["CreationDate-To"],
      },
      Samples: {
        ['sample_state']: samplesStatusFilters
      }
    },
    ...ownProps
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values, dispatch, props) => {
    const { processFilters, cleanEmptyFilters } = props;

    const tasksFilters = processFilters({ ...values.Tasks });
    const samplesFilters = processFilters({ ...values.Samples });

    dispatch(setSelectedTask([]));
    dispatch(getSamplesAction(true, [], {}));
    dispatch(filterTasks(tasksFilters));
    dispatch(
      sortTasksLocally(_.snakeCase(tasksFilters.sort.field), tasksFilters.sort.dir)
    );

    dispatch(setSamplesFilters(samplesFilters.filters));

    // localStorage.setItem(FILTERS_SENSORS, JSON.stringify(snesorsFilters.defFilters));
    // localStorage.setItem(FILTERS_SOPS, JSON.stringify(sopsFilters.defFilters));
  },
});

const CMobileTasksSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileTasksSettings);

export default CMobileTasksSettings;
