import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';

import { clearManageItem } from "./setters";

var diff = require('deep-diff').diff;

export function updateUser(orgData) {
  return (dispatch, getState) => {
    //@TODO: Check errors.
    const newUser = orgData.newUser;
    let method = 'POST';
    let differences;
    let res = getState().form.userDetailsForm.values;
    let path = endPoints.USERS_ENDPOINT;
    if (!newUser) {
      method = 'PUT';
      res = [];
      differences = diff(getState().form.userDetailsForm.values, getState().form.userDetailsForm.initial) || [];
      differences.forEach((diff) => {
        if (diff.path[0] !== 'allowedAuth') {
          res.push({ field: diff.path[0], value: diff.lhs })
        }
      })
      res.push({ field: 'allowedAuth', value: getState().form.userDetailsForm.values.allowedAuth })
      path = endPoints.USERS_ENDPOINT + "/" + getState().form.userDetailsForm.initial.UID;
    }

    dispatch(updateUserReq(res))
    return fetchMiddleware(path, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: res })
    }, getState).then(json => {
      if (json.status == true) {
        //@TODO: notify user.
        dispatch(updateUserRes(json))
        dispatch(fetchUsers())
      }
      return (json);
    })

  }
}

export function deleteUser(callback) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedUser = state.manage.selectedManageItem;
    const uid = selectedUser != null ? selectedUser.id : -1;
    const path = endPoints.USERS_ENDPOINT + "/" + uid;

    return fetchMiddleware(path, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: uid
      })
    }, getState).then((json) => {
      if (json.status == true) {
        //@TODO: notify user.
        dispatch(fetchUsers());
        dispatch(clearManageItem());
      }
      callback(json.status);
    });
  };
}



export function editUser(user) {
  return {
    type: types.EDIT_USER,
    user
  };
}

export const fetchDistributorProjects = () => {
  return (dispatch, getState) => {
    const state = getState();
    const editedUserId = state.manage.selectedManageItem.id;

    const path = endPoints.USERS_ENDPOINT + '/' + editedUserId + '/projects';
    dispatch(requestDistributorProjects(editedUserId));
    return fetchMiddleware(path, {}, getState).then(json => {
      const result = json.data;
      let projectsIds = [];
      if (result != null && result.length > 0) {
        projectsIds = result.map(project => {
          return project.ProjectID;
        })
      }
      dispatch(receiveDistributorProjects(editedUserId, projectsIds));
    });
  }
}

const requestDistributorProjects = (userId) => {
  return {
    type: types.REQUEST_DISTRIBUTOR_PROJECTS,
    userId
  }
}

const receiveDistributorProjects = (userId, projectsIds) => {
  return {
    type: types.RECEIVE_DISTRIBUTOR_PROJECTS,
    userId,
    projectsIds,
  }
}

export const setOpenDistributorProjects = (isOpen) => {
  return {
    type: types.SET_OPEN_DISTRIBUTOR_PROJECTS_MODAL,
    isOpen,
  }
}

export const setProjectForDistributor = (projectsIds) => {
  return (dispatch, getState) => {
    const state = getState();
    const editedUserId = state.manage.selectedManageItem.id;
    const editedUserName = state.manage.selectedManageItem.item.UserName;
    const path = endPoints.USERS_ENDPOINT + '/' + editedUserId;

    return fetchMiddleware(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ projects: projectsIds, userName: editedUserName })
    }, getState).then(json => {
      return dispatch(setOpenDistributorProjects(false));
    });
  }
}

function requestUsers() {
  return {
    type: types.REQUEST_USERS,
  };
}

function receiveUsers(users, indexMap) {
  return {
    type: types.RECEIVE_USERS,
    users,
    indexMap,
  };
}

function updateUserRes(data) {
  return {
    type: types.UPDATE_USER_RES,
    data
  };
}

function updateUserReq(values) {
  return {
    type: types.UPDATE_USER_REQ,
    values
  };
}

function fetchUsers(project) {
  return (dispatch, getState) => {
    var path;
    path = endPoints.USERS_ENDPOINT;
    dispatch(requestUsers());
    return fetchMiddleware(path, {}, getState).then((json) => {
      let usersList;

      let data = [];
      let indexMap = {};

      if (json.status == true) {
        if (process.env.REACT_APP_DEMO_CUSTOMERS) {  // exclude projects by ID
          const demoCustomer = process.env.REACT_APP_DEMO_CUSTOMERS.split(',').map(Number);
          data = json.data.filter((user) => demoCustomer.includes(user.CustomerID));
        } else {
          data = json.data;  // include all projects
        }

        indexMap = setIndexes(data, 'UID');
      } else {
        // console.log(json);
        //@TODO: Handle error.
      }
      return [data, indexMap];
    }).then((args) => {
      dispatch(receiveUsers(...args));
    });
  };
}

export function fetchUsersIfNeeded(project) {
  return (dispatch) => {
    return dispatch(fetchUsers(project))
  }
}

export const viewUserDetails = (userId) => {
  return (dispatch, getState) => {
    const state = getState();
    const usersList = state.management;
    const selectedUser = usersList.users[usersList.usersIndexMap[userId]];
    dispatch(selectUser(selectedUser));
  }
}

const selectUser = (user) => {
  return {
    type: types.SELECT_USER,
    user,
  }
}

export const setUsersDefFilters = (filters) => {
  return {
    type: types.SET_USERS_DEF_FILTERS,
    filters
  };
}

export const sortUsersLocaly = (field, dir) => {
  return (dispatch, getState) => {
    const users = getState().management.users;
    const orderedUsers = users.sort((a, b) => {
      let nRc = 0;

      const firstObj = (dir === 'asc') ? a : b;
      const secondObj = (dir === 'asc') ? b : a;
      const aObj = typeof firstObj[field] === 'string'
        ? firstObj[field].trim()
        : firstObj[field] || '';
      const bObj = typeof secondObj[field] === 'string'
        ? secondObj[field].trim()
        : secondObj[field] || '';
      if (typeof secondObj[field] === 'string') {
        return (aObj.localeCompare(bObj));
      }

      if (aObj > bObj || bObj == null) nRc = 1;
      if (aObj < bObj || aObj == null) nRc = -1;

      return (nRc);
    });

    // let orderedUsers = [];
    let indexMap = {};
    orderedUsers.forEach((user, index) => {
      indexMap[user.UID] = index;
      // orderedUsers.push(user);
    });
    dispatch(sortLocaly(orderedUsers, indexMap, field, dir));
  }
}

const sortLocaly = (users, indexMap) => {
  return {
    type: types.SORT_USERS_LOCALY,
    users,
    indexMap,
  }
}



