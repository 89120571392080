import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Typography from '@mui/material/Typography';

import OperationForm from './OperationForm';

export default function ConfSetAPN(props, context) {
  const [open, setOpen] = React.useState(false);
  const [selectedAPN, setSelectedAPN] = React.useState(null);

  function askBeforSubmit(params) {
    setSelectedAPN(params.setApn);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function submitValue() {
    handleClose();
    props.updateConfig({ setApn: selectedAPN })
  }

  return (
    <React.Fragment>

      <OperationForm
        enable={props.enable}
        fields={props.fields}
        onSubmit={askBeforSubmit}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
      >
        {/* <DialogTitle>
          <Typography component='h1'>{context.t('You are about to take a significant action')}</Typography>
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText style={{fontSize: 'large', color: 'black', textAlign: 'center'}}>
            {context.t('you_are_about_to_take_a_significant_action_on_num_devices', { num: props.countSelected })}<br/>
            <span style={{fontSize: 'medium', color: '#606060'}}>{context.t('confirm_updating_devices_apn_to', {apn: selectedAPN})}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose} autoFocus>{context.t('cancel')}</Button>
          <Button color="primary" onClick={submitValue} autoFocus>{context.t('continue')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ConfSetAPN.propTypes = {
  enable: PropTypes.bool,
  updateConfig: PropTypes.func
};

ConfSetAPN.contextTypes = {
  t: PropTypes.func.isRequired
};
