import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../../Modals/AppModal';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
require('./DeleteModal.scss');

export default class DeleteModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            processing: false
        };

        this.delete = this.delete.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.animateEllipsis = this.animateEllipsis.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.processingInterval);
    }

    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }

    delete() {
        const { id } = this.props;
        this.props.onDelete(id);
        this.closeModal();
    }

    animateEllipsis() {
        setTimeout(() => {
            const ellipsis = this.ellipsis;
            this.processingInterval = setInterval(function () {
                if (ellipsis.innerHTML.length > 3)
                    ellipsis.innerHTML = "";
                else
                    ellipsis.innerHTML += ".";
            }, 500);
        });
    }

    render() {
      const { name, type, disabled } = this.props;
      const typeDisplayName = (type !== 'interference' ? type : 'Area')
        //const { type } = this.props;
        return (
          <div
            style={{ float: 'left' }}>
                <button
              className="btn btn-warning btn-order"
              onClick={this.openModal}
              type="button"
              disabled={disabled}
                >
              {this.context.t('delete_type_id', { id: name || '', type: type })}
                </button>

                {this.state.open == true &&
                <AppModal
                  open={this.state.open}
                  onClose={this.closeModal}
                  title={this.context.t('delete_type_id', { id: name || '', type: type})}
                  content={(
                    <div id="delete-dialog-body">
                      {this.context.t('are_you_sure_you_want_to_delete_this_type', { type: type })}
                    </div>
                  )}
                  actions={(
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<DeleteForeverIcon/>}
                        onClick={this.delete}>
                        {this.context.t('delete')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<CancelIcon/>}
                        onClick={this.closeModal}>
                        {this.context.t('cancel')}
                      </Button>
                    </div>
                  )}
                />
                }
            </div>
        );
    }
}

DeleteModal.contextTypes = {
    t: PropTypes.func.isRequired
};
