// CAddMobileAlert.jsx
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import AddMobileAlertForm from '../../components/Mobile/AddMobileAlertForm';
import { getAddressByLatLong } from '../../actions/GlobalsActions';
import * as mobileTasksActions from '../../actions/MobileTasksActions'; // Import your actions
import _ from 'lodash';
import mobileAlertsActions from "../../actions/MobileAlertsActions";
import * as actionsMobileAlerts from "../../actions/MobileAlertsActions";

const getInitialValues = (options) => {

  let initialValues = null;

  if (options && !_.isEmpty(options)) {
    const dateNow = new Date();
    const dateNowString = dateNow.toISOString().split('T')[0];

    const defaultAlertState = options.AlertStateNoise.find((optionItem, index, allOptions) => optionItem.value == 1); //'New'
    const defaultAlertType = options.AlertType.find((optionItem, index, allOptions) => optionItem.label == 'Leak');
    const defaultClassification = options.AudioClassificationEnum.find((optionItem, index, allOptions) => optionItem.label == 'NA');

    initialValues = {
      DetectionDateTime: dateNowString,
      fixDate: dateNowString,
      alertType: defaultAlertType.value,
      alertState: defaultAlertState.value,
      alertClassification: defaultClassification.value,
      Coordinate: '',
    };
  }
  return initialValues;
};

const mapStateToProps = (state, ownProps) => {
  const user = state.leaksList.user;
  const optionList = state.leaksList.optionList.options;

  const {
    AlertStateNoise = [],
    AlertType = [],
    AudioClassificationEnum = [],
  } = optionList;
  const initialValues = getInitialValues(optionList);

  return {
    user,
    initialValues,
    isOpen: state.mobile.tasks.showAddMobileAlertsForm,
    selectedFeature: state.mobile.tasks.selectedTask,
    checkedSamples: state.mobile.samples.checkedSamples,
    options: {
      AlertStateNoise,
      AlertType,
      AudioClassificationEnum,
    },
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doClose: () => {
      ownProps.showAddMobileAlertsForm(false);
    },
    createNewMobileAlert: (taskId, data) => {
      dispatch(actionsMobileAlerts.createNewMobileAlert(taskId, data));
    },
    getAddressByLatLong: () => {
      dispatch(getAddressByLatLong('add-mobile-alert-form', 'address'));
    },
    onCreateNewValue: (valueKey, value) => {
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMobileAlertForm);
