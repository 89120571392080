import { connect } from 'react-redux';
import SOPsOperationsTab from '../../components/SOP/SOPsOperationsTab';
import * as actionsSops from '../../actions/SOPsActions';
import * as actionsInstall from '../../actions/InstallActions';
import { editComment, copyComment, getAddressByLatLong } from '../../actions/GlobalsActions';

import { InstallOperationMode, UninstallMode, DEVICE_DIGITS } from '../../constants/Misc';

const diff = require('deep-diff').diff;

const mapStateToProps = (state, ownProps) => {
  const sensorTypes = state.leaksList.optionList.options.SensorType;
  const syncTypes = state.leaksList.optionList.options.DeviceTypeSyncMethod;
  const deviceGenerationType = state.leaksList.optionList.options.DeviceGenerationType;
  const installState = state.install;
  const isWorking = installState.isWorking;
  const isFetchingDetails = installState.isFetchingDetails;
  const uninstallMode = installState.uninstallMode;
  const uninstallDetails = installState.uninstallBusyDeviceDetails;
  let configs = null;
  if (installState.configs != null) {
    configs = installState.configs.map((config, index, array) => {
      return { value: config, label: config };
    });
  }
  const user = state.leaksList.user;
  const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
  let initialValues = installState.sensorDetails;
  if (initialValues && initialValues.IsPressure == null) {
    initialValues.IsPressure = false;
  }
  let formInput = null;
  let deviceIdInput = null;
  if (state.form.sopOperationsForm != null && state.form.sopOperationsForm.values != null) {
    formInput = state.form.sopOperationsForm.values;
    deviceIdInput = formInput.DeviceID;
  }

  // Set the current operation:
  let mode;
  let isDeviceFieldEnable = true;
  if (initialValues == null || initialValues.ID == null) {
    // initialValues is the sensor data. (initialValues.ID = sensor ID)
    if (deviceIdInput == null || deviceIdInput.length == 0) {
      mode = InstallOperationMode.SOP_ONLY;
    } else {
      mode = InstallOperationMode.SOP_AND_SENSOR;
    }
  } else if (initialValues.DeviceID == null) {
    mode = InstallOperationMode.SOP_AND_SENSOR;
  } else {
    mode = InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE;
    isDeviceFieldEnable = false;
  }

  let isButtonEnabled;
  switch (mode) {
    default:
    case InstallOperationMode.SOP_ONLY:
      isButtonEnabled = true;
      break;
    case InstallOperationMode.SOP_AND_SENSOR:
    case InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE:
      isButtonEnabled = (deviceIdInput != null && deviceIdInput.length == DEVICE_DIGITS) &&
        (formInput.SyncMethod != null && formInput.SyncMethod.toString() != '') &&
        (formInput.SensorType != null && formInput.SensorType.toString() != '') &&
        (formInput.isUnderground != null && formInput.isUnderground.toString() != '') &&
        (formInput.Template != null && formInput.Template != '');
      break;
  }

  let selectedGenerationType;
  if (deviceGenerationType) {
    selectedGenerationType = (deviceIdInput != null && deviceIdInput.startsWith('000')) ? deviceGenerationType[1] : deviceGenerationType[0];
  }

  return Object.assign({}, {
    sensorTypes,
    syncTypes,
    deviceGenerationType,
    user,
    selectedFeature,
    configs,
    initialValues,
    mode,
    uninstallMode,
    uninstallDetails,
    isButtonEnabled,
    isDeviceFieldEnable,
    isFetchingDetails,
    isWorking,
    selectedGenerationType,
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const differences = diff(values, props.initialValues);
      let res = [];
      if (differences != null) {
        differences.forEach((diff) => {
          if (diff.path != null) {
            res.push({ field: diff.path[0], value: diff.lhs })
          }
        });
      }
      dispatch(actionsInstall.addSensorOrInstallOrUpdate(res, (data) => {
        if (data != null) {
          ownProps.notify(data.message, data.level);
        }
      }));
    },
    onEdit: (proxi, object, event) => {
      dispatch(editComment('sopOperationsForm', 'Comment'));
      return true;
    },
    onCopy: (proxi, object, event) => {
      dispatch(copyComment('sopOperationsForm', 'Comment'));
      return true;
    },

    getAddressByLatLong: (proxi, object, event) => {
      dispatch(getAddressByLatLong('sopOperationsForm', 'StreetAddress'));
      return true;
    },
    setUninstallMode: (mode) => {
      dispatch(actionsInstall.setUninstallMode(mode));
    },
    setAsArchived: () => {
      dispatch(actionsInstall.setSensorAsArchived());
    }
  };
};

const CSOPsOperationsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(SOPsOperationsTab);

export default CSOPsOperationsTab;
