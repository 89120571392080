import React from 'react';
import PropTypes from 'prop-types';

import AppModal from '../../Modals/AppModal';

import SensorPressureGraph from "../../../components/SensorsTabs/Pressure/SensorPressureGraph";

require('./plotModal.scss');

export default class ModalPlot extends React.Component {

  static get propTypes() {
    return {
      onClose: PropTypes.func,
      isOpen: PropTypes.bool,
      plotData: PropTypes.array,
      plotName: PropTypes.string,
      uiInfo: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const { isOpen, plotData, plotName, uiInfo } = this.props;

    return (
      <AppModal
        closeIcon
        open={isOpen}
        onClose={this.onClose}
        title={plotName}
        content={
          <SensorPressureGraph
            plotData={plotData}
            isFetching={false}
            uiInfo={uiInfo}
          />
        }
      />
    );
  }
}

ModalPlot.contextTypes = {
  t: PropTypes.func.isRequired
};
