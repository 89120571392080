import { END_POINT } from "../constants/EndPoints"
import { fetchMiddleware } from './MiddlewareActions';

export const fetchPipesData = (polygonPoints) => (dispatch, getState) => {
  const state = getState();
  const projectId = state.leaksList.selectedProject;
  const url = `${END_POINT}/pipes/${projectId}?polygon=${JSON.stringify(polygonPoints)}`;
  return fetchMiddleware(url, {}, getState).then((json) => {
    // console.log(json);
    if (json.status) return json.data;
    return undefined;
  });
}
