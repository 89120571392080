import React from 'react';
import PropTypes from 'prop-types';

import OperationForm from './OperationForm';
import {types} from "../../../constants/G5MessagesTypes";

export default function ConfigurationForm(props) {

  return (
    <OperationForm
      notify={props.notify}
      type={props.type}
      advancedOptions={props.advancedOptions}
      enable={props.enable}
      fields={props.fields}
      onSubmit={props.updateConfig}
      onSaveDefault={props.onSaveDefault}
    />
  );
}

ConfigurationForm.propTypes = {
  type: PropTypes.string,
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  onSaveDefault: PropTypes.func,
  updateConfig: PropTypes.func,
  notify: PropTypes.func.isRequired// Add this line
};

ConfigurationForm.contextTypes = {
  t: PropTypes.func.isRequired
};
