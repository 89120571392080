import React from 'react';
import PropTypes from 'prop-types';


// import { SOPS_TYPES } from "../../../constants/Misc";

const types = [
    'undefined',
    'hydrant',
    'valve',
    'gate_valve',
    'control_valve',
    'service_valve',
    'manhole',
    'fitting'
];

const specialTypes = [
    'pump',
    'prv',
    'washout',
    'watering',
    'fountain',
    'air_valve',
];

class SopTypesLegend extends React.Component {

    render() {
        return (
            <div>
                <table className='installation-table-box'>

                    <div className='installation-table-col'>
                        <thead>
                            <th>
                            </th>
                            <th>
                                {this.context.t('overground')}
                            </th>
                            <th>
                                {this.context.t('underground')}
                            </th>

                        </thead>
                        <tbody>
                            {
                                types.map(value => {
                                    // const className = 'sop marker-' + value;

                                    return (
                                        <tr className="table-row">
                                            <th>{this.context.t(value)}</th>
                                            <td className={'sop marker-' + value}></td>
                                            <td className={'sop underground ' + value}></td>
                                        </tr>
                                    );
                                })
                            }

                        </tbody>
                    </div>

                    <div className='installation-table-col'>


                        <thead>

                            <th>
                                {this.context.t('')}
                            </th>
                        </thead>
                        <tbody>
                            {
                                specialTypes.map(value => {
                                    // const className = 'sop marker-' + value;

                                    return (
                                        <tr className="table-row">
                                            <th>{this.context.t(value)}</th>
                                            {/* <td className={'sop marker-' + value}></td> */}
                                            <td className={'sop special ' + value}></td>
                                        </tr>
                                    );
                                })
                            }


                        </tbody>
                    </div>

                </table>
            </div>
        );
    }
}

SopTypesLegend.contextTypes = {
    t: PropTypes.func.isRequired
}

export default SopTypesLegend;
