import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import Tabs from '../Custom/Tabs/TabsContainer';

import CDeviceTab from '../../containers/CDeviceTab/CDeviceTab';
import CSOPsOperationsTab from '../../containers/CSopsTab/CSOPsOperationsTab';
import CSOPsTab from '../../containers/CSopsTab/CSOPsTab';
import CSensorPressure from '../../containers/CSensorTab/CSensorPressure';
import CSensorTab from '../../containers/CSensorTab/CSensorTab';
import G5CommStatusMsg from './G5CommStatusMsg';

import SensorHistoryTab from './../SensorsTabs/SensorHistoryTab/SensorHistoryTab';
import G5BitMessages from './G5BitMessages';
import G5NoiseMessages from './G5NoiseMessages';
import SensorBitsTab from './SensorBitsTab/SensorBitsTab';
import SensorLogsTab from './SensorLogsTab/SensorLogsTab';
import SensorSamplesTab from './SensorSamplesTab/SensorSamplesTab';
import G5BitSampleMessages from './G5BitSampleMessages';
import G5StatusMessages from './G5StatusMessages';

const classNames = require('classnames');

// const PRESSURE_TAB_INDEX = 5;

export default function SensorsTabs(props, context) {
  const {
    path,
    uiInfo,
    showDetails,
    selectedFeature,
    featureType,
    sensorItem,
    sensorsHistory,
    timeZone,
    notify,
    user,
  } = props;

  React.useEffect(() => {
    if (featureType == 'sensor') {
      if (selectedFeature !== '') {
        if (!showDetails) {
          props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    }
  }, [selectedFeature]);

  const closePanel = () => {
    props.toggleDetailsVisibility(false);
    props.clearSelectedFeature();
    props.selectSensorLog({});
    props.selectSensorBit({});
  };

  const onTabChange = (prevTab, nextTab) => {
    if (!_.isEmpty(props.selectedSensorLog)) {
      props.selectSensorLog(null);
    }
    if (!_.isEmpty(props.g5SelectedNoiseMsg)) {
      props.selectNoiseMessage({});
    }
  };

  const getTabsByPath = () => {
    let tabs;

    switch (path) {
      default:
      case 'sensors': {
        tabs = [
          {
            label: 'Details',
            component: <CSensorTab downloadFile={props.downloadFile} />,
          },
          {
            label: context.t('history'),
            component: (
              <SensorHistoryTab
                sensorsHistory={sensorsHistory}
                uiInfo={uiInfo}
                timeZone={timeZone}
              />
            ),
          },
          {
            label: context.t('device'),
            component: <CDeviceTab uiInfo={uiInfo} />,
          },
        ];

        if (sensorItem != null && sensorItem.DeviceGenerationValue === 0) {
          tabs.push({
            label: context.t('logs'),
            style: {},
            component: (
              <SensorLogsTab
                sensorsLogs={props.sensorsLogs}
                uiInfo={uiInfo}
                timeZone={timeZone}
                onPageIndexChange={props.onPageIndexChange}
                selectSensorLog={props.selectSensorLog}
                selectedSensorLog={props.selectedSensorLog}
                sensorsLogsCount={props.sensorsLogsCount}
                downloadFile={props.downloadFile}
              />
            ),
          });
        }

        tabs.push({
          label: context.t('samples'),
          component: (
            <SensorSamplesTab
              sensorsSamples={props.sensorNoiseSamples}
              uiInfo={uiInfo}
              timeZone={timeZone}
              onPageIndexChange={props.onPageIndexChange}
              selectSensorSample={props.selectSensorSample}
              selectedSensorSample={props.selectedSensorSample}
              totalNoiseSamplesCount={props.totalNoiseSamplesCount}
              noiseSamplesRequestedCount={props.noiseSamplesRequestedCount}
              numberOfRowsInNoiseSamples={props.sensorNoiseSamplesRowsCount}
              downloadFile={props.downloadFile}
            />
          ),
        });

        if (sensorItem != null && sensorItem.IsPressure) {
          tabs.push({
            label: context.t('pressure'),
            component: (
              <CSensorPressure
                sensorItem={sensorItem}
                selectedFeature={selectedFeature}
                uiInfo={uiInfo}
                timeZone={timeZone}
              />
            ),
          });
        }

        if (sensorItem != null && sensorItem.DeviceGenerationValue === 1) {
          tabs.push({
            label: context.t('g5_status'),
            component: (
              <G5StatusMessages
                selectedSensor={props.sensorItem}
                data={props.g5Details}
                downloadReport={props.getG5DeviceStatusMessagesReport}
                timeZone={timeZone}
                user={user}
              />
            ),
          });
        }
        if (
          user.isAQS &&
          sensorItem != null &&
          sensorItem.DeviceGenerationValue === 1
        ) {
          tabs.push(
            // @TODO
            {
              label: context.t('g5_intensity'),
              component: (
                <G5NoiseMessages
                  data={props.g5NoiseMsgs}
                  uiInfo={uiInfo}
                  timeZone={timeZone}
                  selectMessageFunc={props.selectNoiseMessage}
                  selectedMessage={props.g5SelectedNoiseMsg}
                />
              ),
            }
            // {
            //   label: context.t('comm_messages'),
            //   component: (
            //     <G5CommStatusMsg
            //       deviceId={sensorItem.DeviceID}
            //       uiInfo={uiInfo}
            //       timeZone={timeZone}
            //     />
            //   )
            // }
          );
        }
        break;
      }
      case 'install': {
        tabs = [
          {
            label: context.t('install'),
            component: <CSOPsOperationsTab notify={notify} />,
          },
          {
            label: context.t('installation_point'),
            component: <CSOPsTab notify={notify} />,
          },
          {
            label: context.t('history'),
            component: (
              <SensorHistoryTab
                sensorsHistory={sensorsHistory}
                uiInfo={uiInfo}
                timeZone={timeZone}
              />
            ),
          },
          {
            label: context.t('device'),
            component: <CDeviceTab uiInfo={uiInfo} />,
          },
        ];

        if (sensorItem && sensorItem.DeviceGenerationValue === 1) {
          tabs.push(
            {
              label: context.t('g5_status'),
              component: (
                <G5StatusMessages
                  selectedSensor={props.sensorItem}
                  data={props.g5Details}
                  downloadReport={props.getG5DeviceStatusMessagesReport}
                  timeZone={timeZone}
                  user={user}
                />
              ),
            },
            {
              label: context.t('bit_samples'),
              component: (
                <G5BitSampleMessages
                  uiInfo={uiInfo}
                  selectedSensor={props.sensorItem}
                  selectBitSampleMessageFunc={
                    props.handleSelectBitSampleMessage
                  }
                  selectedBitSampleMessage={props.g5SelectedBitSampleMsg}
                  data={props.g5BitSampleMsgs}
                  timeZone={timeZone}
                  user={user}
                />
              ),
            }
            // {
            //   label: context.t('bit_messages'),
            //   component: (
            //     <G5BitMessages
            //       data={props.g5BitMsgs}
            //       uiInfo={uiInfo}
            //       timeZone={timeZone}
            //       downloadFile={props.downloadFile}
            //       handleSelectMessage={props.handleSelectMessage}
            //       selectedMessage={props.g5SelectedBitMsg}
            //     />
            //   ),
            // }
          );
        } else {
          tabs.push(
            {
              label: context.t('logs'),
              component: (
                <SensorLogsTab
                  sensorsLogs={props.sensorsLogs}
                  uiInfo={uiInfo}
                  timeZone={timeZone}
                  onPageIndexChange={props.onPageIndexChange}
                  selectSensorLog={props.selectSensorLog}
                  selectedSensorLog={props.selectedSensorLog}
                  sensorsLogsCount={props.sensorsLogsCount}
                  downloadFile={props.downloadFile}
                />
              ),
            },
            {
              label: context.t('bit'),
              component: (
                <SensorBitsTab
                  sensorsBits={props.sensorsBits}
                  uiInfo={uiInfo}
                  timeZone={timeZone}
                  onPageIndexChange={props.onPageIndexChange}
                  selectSensorBit={props.selectSensorBit}
                  selectedSensorBit={props.selectedSensorBit}
                  sensorsBitsCount={props.sensorsBitsCount}
                  downloadFile={props.downloadFile}
                />
              ),
            }
          );
        }
        break;
      }
    }

    return tabs;
  };

  const panelStyle = { height: 0.493 * uiInfo.pageHeight };

  const panelClasses = classNames('alert-details', { show: showDetails });

  const tabs = getTabsByPath();

  return (
    <div className={panelClasses} style={panelStyle}>
      <Tabs
        title={context.t('sensor_id_param2', { id: selectedFeature })}
        tabs={tabs}
        handleClose={closePanel}
        onTabChange={onTabChange}
      />
    </div>
  );
}

SensorsTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};
