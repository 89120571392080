import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import Select from './../Select/Select';
import Input from './../Input/Input';
import { Field, reduxForm } from 'redux-form';

const classNames = require('classnames');

class PCAModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      advancedOpen: false,
    };
    this.advancedToggle = this.advancedToggle.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  advancedToggle() {
    this.setState({ advancedOpen: !this.state.advancedOpen });
  }

  // Close dialog
  onClose() {
    this.props.closePCADialog();
    this.setState({ advancedOpen: false });
  }

  onSave() {
    this.props.closePCADialog();
    this.props.submitAction('pipeAssessment');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen != this.state.open) {
      this.setState({
        open: nextProps.isOpen
      });
    }
  }

  render() {
    const { options } = this.props;

    const panelClasses = classNames(
      'noShow',
      { 'show': this.state.advancedOpen }
    );
    const checkBoxFieldStyle = {
      display: 'inline-flex'
    };


    return (
      <AppModal
        closeIcon
        open={this.state.open}
        onClose={this.onClose}
        title={this.context.t('pipe_condition_assessment_details')}
        content={(
         <div style={{ maxHeight: '800px', overflowY: 'auto', overflowX: 'hidden' }}>
          <form className="form-horizontal sticky-actions" onSubmit={this.props.handleSubmit}>
            <fieldset>
              <legend>{this.context.t('event_information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('pipe_material')}:</label>
                    <Field name="PipeMaterialCode"
                      component={(props) => (
                        <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PipeMaterialEnum} />
                      )}
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>

                      <span>{this.context.t('pipe_diameter')}:</span>


                    </label>

                    <Field
                      name="PipeDiameterInch"
                      // component={Input} type="number" className="form-control"/>
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={[
                            { label: '0.75 "', value: 0.75 },
                            { label: '1.00 "', value: 1.00 },
                            { label: '1.25 "', value: 1.25 },
                            { label: '2.00 "', value: 2.00 },
                            { label: '2.50 "', value: 2.50 },
                            { label: '3.00 "', value: 3.00 },
                            { label: '4.00 "', value: 4.00 },
                            { label: '5.00 "', value: 5.00 },
                            { label: '6.00 "', value: 6.00 },
                            { label: '8.00 "', value: 8.00 },
                            { label: '10.00 "', value: 10.00 },
                            { label: '12.00 "', value: 12.00 },
                            { label: '16.00 "', value: 16.00 },
                            { label: '20.00 "', value: 20.00 },
                            { label: '24.00 "', value: 24.00 },
                            { label: '28.00 "', value: 28.00 },
                            { label: '32.00 "', value: 32.00 },
                            { label: '36.00 "', value: 36.00 },
                            { label: '40.00 "', value: 40.00 }
                          ]}
                        />)
                      }
                    />
                  </div>

                  {/* <div className="col-xs-3">
                  <label>{this.context.t('Pipe Coating')}:</label>
                  <Field name="PipeCoating" component={Input} type="number" className="form-control" />
              </div> */}

                  <div className="col-xs-3">
                    <label>{this.context.t('pipe_coating')}:</label>
                    <Field name="PipeCoating"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PipeCoatingCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('pipe_install_year')}:</label>
                    <Field name="PlacementYear" component={Input} type="number" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('pipe_thickness_at_break')}:</label>
                    <Field name="PipeThicknessInch_BreakLocation" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('crack_diameter_inches')}:</label>
                    <Field name="PipeCrackDiameterInch" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('water_loss_unit_h', { unit: this.props.units === 'm' ? 'l' : 'g' })}:</label>
                    <Field name="WaterLossLH" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('pipe_id')}:</label>
                    <Field name="PipeID" component={Input} type="text" className="form-control" />
                  </div>

                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">
                  <div className="col-xs-3">
                    <label>{this.context.t('event_date')}:</label>
                    <Field name="EventDate" component={Input} type="date" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('event_nature')}:</label>
                    <Field name="EventNatureOfBreakCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCANatureOfBreakCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('event_cause')}:</label>
                    <Field name="EventCauseOfBreakCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCACauseOfBreakCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('corrosion_type')}:</label>
                    <Field name="EventCorrosionTypeCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCACorrosionTypeCodesEnum} />)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('repair_date')}:</label>
                    <Field name="RepairDate" component={Input} type="date" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('repair_type')}:</label>
                    <Field name="RepairType"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCARepairCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-6">
                    <label>{this.context.t('nature_of_break_comment')}:</label>
                    <Field name="EventNatureOfBreakComment" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>{this.context.t('location_information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('ground_type')}:</label>
                    <Field name="LocationGroundTypeCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAGroundTypeCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('traffic')}:</label>
                    <Field name="LocationTrafficCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCATrafficCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('street_surface')}:</label>
                    <Field name="LocationStreetSurfaceCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAStreetSurfaceCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('weather_conditions')}:</label>
                    <Field name="LocationWeatherConditionCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAWeatherConditionsCodesEnum} />)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3" style={{ display: 'grid' }}>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsElectrolysis" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('is_electrolysis')}
                    </label>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsRocks" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('is_rocks')}
                    </label>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsVoids" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('is_voids')}
                    </label>
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('resistivity')}:</label>
                    <Field name="LocationResistivityOh_MsCm" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('frost_depth')}:</label>
                    <Field name="LocationFrostDepthInch" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('snow_depth')}:</label>
                    <Field name="LocationSnowDepthInch" component={Input} type="number" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12 text-dir-button">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('proximity_to_other_utilities')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="LocationProximityOtherUtilities" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset>
              <legend>{this.context.t('general_information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">
                  <div className="col-xs-4 text-dir-button">
                    <label>{this.context.t('reported_by')}:</label>
                    <Field name="GeneralReportedBy" component={Input} type="text" className="form-control" />
                  </div>

                  <div className="col-xs-8 text-dir-button">
                    <label>{this.context.t('comments')}:</label>
                    <Field name="GeneralComment" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset className={panelClasses}>
              <legend>{this.context.t('historical_information')}</legend>
              <div className="form-group">
                <div className="col-xs-3">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsAirTempSuddenChange" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('sudden_air_temp_change')}
                  </label>
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_avg')}:</label>
                  <Field name="HistoricalAirTempChangeAvgClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_rise')}:</label>
                  <Field name="HistoricalAirTempChangeRiseClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_fail')}:</label>
                  <Field name="HistoricalAirTempChangeFailClesius" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-3">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsWaterTempSuddenChange" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('sudden_water_temp_change')}
                  </label>
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_avg')}:</label>
                  <Field name="HistoricalWaterTempChangeAvgClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_rise')}:</label>
                  <Field name="HistoricalWaterTempChangeRiseClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('temp_fail')}:</label>
                  <Field name="HistoricalWaterTempChangeFailClesius" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('bedding_type')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalInstalltionBeddingCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAGroundTypeCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-8 text-dir-button">
                  <label>{this.context.t('bedding_comment')}:</label>
                  <Field name="HistoricalInstalltionBeddingComment" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('backfill')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalInstalltionBackfillCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAGroundTypeCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-8 text-dir-button">
                  <label>{this.context.t('backfill_comment')}:</label>
                  <Field name="HistoricalInstalltionBackfillComment" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('prior_2_weeks_weather')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="Historical2WeeksPriorWeatherCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('select')}
                          options={options.PCAWeatherConditionsCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <label>{this.context.t('pipe_type')}:</label>
                  <Field name="HistoricalPipeTypeCode"
                    component={(props) =>
                      <Select {...props}
                        placeholderTitle={this.context.t('select')}
                        options={options.PipeMaterialEnum} />
                    }
                  />
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('pipe_thickness')}:</label>
                  <Field name="HistoricalPipeThicknessInch" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4 text-dir-button">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('pipe_length')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalPipeLayingLengthMeter" component={Input} type="text" className="form-control" />
                  </div>
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('design_operating_press')}:</label>
                  <Field name="HistoricalPipeDesignOperatingPressurePSI" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-4">
                  <label>{this.context.t('compaction')}:</label>
                  <Field name="HistoricalCompactionCode"
                    component={(props) =>
                      <Select {...props}
                        placeholderTitle={this.context.t('select')}
                        options={options.PCACompactionCodesEnum} />
                    }
                  />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsPreviousBreakReported" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('is_previous_break_reported')}
                  </label>
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('previous_break_operating_press')}:</label>
                  <Field name="HistoricalIsPreviousBreakPressurePSI" component={Input} type="text" className="form-control" />
                </div>

              </div>

            </fieldset>
            <div className="form-group">
              <div className="col-xs-12">
              <a
                className='btn btn-info'
                onClick={this.advancedToggle}>
                {this.context.t('advanced')}
              </a>
              <a
                className='btn btn-success'
                onClick={this.onSave}>
                {this.context.t('save')}
              </a>
              <a
                className='btn btn-danger'
                onClick={this.onClose}>
                {this.context.t('close')}
              </a>
              </div>
            </div>
          </form>
         </div>
        )}
      />
    );
  }
}

PCAModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const pcaModal = reduxForm({
  form: 'pipeAssessment',
  enableReinitialize: true,
})(PCAModal);

export default pcaModal;
