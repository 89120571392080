import PropTypes from 'prop-types';
import React from 'react';

import { Column, Table } from 'fixed-data-table-2';

import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import CValveAlertsSettings from '../../containers/CAlertsSettings/CValveAlertsSettings';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

require('../AlertsTable/AlertsTable.scss');

function ValveAlertsTable(props, context) {
  const {
    items,
    indexMap,
    selectedFeature,
    selectedProjectId,
    localSortProp,
    pHeight,
    pWidth,
    timeZone,
    rowClickEvent,
    addingManualNoiseAlertMode,
    t,
  } = props;

  const [goToRow, setGoToRow] = React.useState(null);

  React.useEffect(() => {
    if (selectedFeature) {
      const rowIndex = items.findIndex((vAlert) => vAlert.id === selectedFeature.id)
      // const rowIndex = props.indexMap[selectedFeature.id];
      setGoToRow(rowIndex);

      // setTimeout(() => {
      //   console.log('scroll to valve ');

      // }, 2500);
    }
  }, [selectedFeature]);

  function getRowClassName(rowIndex) {
    if (!selectedFeature) {
      return '';
    }

    const rowItem = items[rowIndex];
    if (rowItem?.id === selectedFeature.id) {
      return 'active-row';
    } else {
      return '';
    }
  }

  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };

  return (
    <div>
      {/* { props.user.isAQS &&
        <CAddNoiseAlert
          isOpen={addingManualNoiseAlertMode}
          showAddAlertForm={props.addNewManualNoiseAlert}
          notify={props.notify}
        />
      } */}

      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={getRowClassName}
        scrollToRow={goToRow}
      >
        <Column
          columnKey='id'
          width={70}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='id' />}
        />

        {props.user.isAQS && (
          <Column
            columnKey='create_date'
            width={95}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={
              <TextCell
                data={items}
                field='create_date'
                dateType={DateType.DATE}
                additional={timeZone}
              />
            }
          />
        )}

        <Column
          columnKey='alert_state'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <CSelectCell
              data={items}
              field='alert_state'
              options={props.optionList.options.AlertStateNoise}
              dummyPropForUpdate={localSortProp}
            />
          }
        />
        <Column
          columnKey='sensor_id'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='sensor_id' />}
        />
        <Column
          columnKey='Comment'
          headerClassName='comment'
          cellClassName='comment'
          width={120}
          flexGrow={3}
          header={t('comment')}
          cell={<TextCell data={items} field='Comment' />}
        />
      </Table>

      {false && items && items.length > 0 && (
        <CTableActions type='alerts' notify={props.notify} />
      )}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t('selected_selectedindex_total_total', {
            selectedIndex:
              indexMap[selectedFeature && selectedFeature.ID] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}

      {/* { props.user.isAQS &&
        <button
          className="btn-success create-item"
          onClick={() => { props.addNewManualNoiseAlert(true) }}
          type="button">
          <i title={context.t('Add')} />
        </button>
      }
      {
        selectedFeature.ID &&
        <button
          className="btn-success noise-to-alert"
          onClick={() => { props.createAlertFromNoiseAlert(selectedFeature.ID) }}
          type="button">
          <i title={context.t('Create Alert')} />
        </button>
      } */}
    </div>
  );
}

ValveAlertsTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

const valveAlertsTable = TableMixin(ValveAlertsTable, CValveAlertsSettings);
export default valveAlertsTable;
