import * as React from 'react';
import PropTypes from 'prop-types';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Table, Column } from '../../Custom/SimpleTable';

const BACKGROUNDS = {
  GOOD: 'lightgreen',
  BAD: 'orangered'
};

function BitsInfoTable(props, context) {
  const { items, selectedItems, local, reloadDevices } = props;

  const [filterItems, setFilterItems] = React.useState(null);

  React.useEffect(() => {
    setFilterItems({
      field: 'DeviceID',
      value: selectedItems
    });
  }, [selectedItems]);

  function renderBatteryField(value) {
    return (value) ? value.toFixed(3) : '--';
  }

  function getCellBackgroundColor(item, keyForStyle) {
    const itemData = items.find((listItem) => listItem.DeviceID === item.DeviceID);
    const style = (itemData) ? ({ backgroundColor: (itemData[keyForStyle] ? BACKGROUNDS.GOOD : BACKGROUNDS.BAD) }) : null;
    return style;
  }

  return (
    <Table
      context={context}
      items={items}
      filter={filterItems}
      containerStyle={{maxHeight: '78vh'}}
      reloadDevices={reloadDevices}
    >
      <Column columnKey='DeviceID' headerLabel={context.t('device_id')} />
      <Column columnKey='IEMI' headerLabel={context.t('iemi')} />
      <Column columnKey='BatteryLevel'
        headerLabel={context.t('battery_level')}
        renderFunc={(value) => renderBatteryField(value)}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'BatteryLevelOK')}
      />
      <Column columnKey='BatteryLevelPost'
        headerLabel={context.t('battery_post')}
        renderFunc={(value) => renderBatteryField(value)}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'BatteryLevelPostOK')}
      />
      <Column columnKey='HardwareRevision' headerLabel={context.t('fw_version')} />
      <Column columnKey='FirmwareRevision' headerLabel={context.t('hw_version')} />
      <Column columnKey='RSRQ_db'
        headerLabel={context.t('rsrq')}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'RSRQ_dbOK')}
      />
      <Column columnKey='RSRP_dbm'
        headerLabel={context.t('rsrp')}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'RSRP_dbmOK')}
      />
      <Column columnKey='bestChannel' headerLabel={context.t('fm_radio_channel')} />
      <Column columnKey='bestRssi'
        headerLabel={context.t('rssi')}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'bestRssiOK')}
      />
      <Column columnKey='bestSnr'
        headerLabel={context.t('snr')}
        getCellStyleFunc={(item) => getCellBackgroundColor(item, 'bestSnrOK')}
      />
      <Column columnKey='NoiseIntensityGain' headerLabel={context.t('noise_gain')} />
      <Column columnKey='NoiseIntensityMinRMS' headerLabel={context.t('noise_min_rms')} />
      <Column columnKey='NoiseIntensityMinRmsNormalize' headerLabel={context.t('normalize_noise')} />
    </Table>
  );
}

BitsInfoTable.contextTypes = {
  t: PropTypes.func.isRequired
};

BitsInfoTable.propTypes = {
  items: PropTypes.array.isRequired,
  local: PropTypes.string,
  reloadDevices: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  workingMode: PropTypes.string.isRequired,
};

export default BitsInfoTable;

// /* eslint-disable react/no-multi-comp */
// import * as React from 'react';
// import PropTypes from 'prop-types';

// import { makeStyles } from '@mui/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableFooter from '@mui/material/TableFooter';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';

// import IconButton from '@mui/material/IconButton';
// import RefreshIcon from '@mui/icons-material/Refresh';
// // import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

// import '../style.scss';

// const useStyles = makeStyles({
//   container: {
//     // width: 'fit-content'
//   },
//   table: {
//     display: 'block',
//     height: '78.5vh',
//     minWidth: 700,
//   },
//   footer: {
//     position: 'sticky',
//     insetBlockEnd: 0,
//     bottom: 0,
//     backgroundColor: '#fafafa',

//     '& td': {
//       border: '1px solid #eee',
//     }
//   },
//   audioCell: {
//     backgroundColor: '#cbcbfe66'
//   },
//   correlationAudioCell: {
//     backgroundColor: '#faeac366'
//   },
//   correlationCell: {
//     backgroundColor: '#00ff0e3b'
//   },
//   correlationRadioCell: {
//     backgroundColor: '#ff000030'
//   },
//   cell: {
//     borderLeft: '1px solid #eee',
//     whiteSpace: 'nowrap',
//   },
//   valid: {
//     backgroundColor: 'lightgreen',
//   },
//   invalid: {
//     backgroundColor: 'orangered',
//   }
// });

// function BitsInfoTable(props, context) {
//   const { items, selectedItems } = props;
//   const classes = useStyles();

//   // const columnCheckboxRef = React.useRef();

//   // React.useEffect(() => {
//   //   let selectedCount = 0;
//   //   items.forEach((item) => {
//   //     if (selectedDevices[item.DeviceID]) {
//   //       selectedCount += 1;
//   //     }
//   //   });
//   //   columnCheckboxRef.current.indeterminate = (selectedCount > 0 && selectedCount < items.length);
//   // }, [selectedDevices]);

//   // function changeAll(event) {
//   //   const checked = event.target.checked;
//   //   const checkedDevicesDictionary = {};

//   //   items.forEach((item) => {
//   //     checkedDevicesDictionary[item.DeviceID] = checked;
//   //   });

//   //   props.setSelectedDevices(checkedDevicesDictionary);
//   // }

//   // function setChecked(item) {
//   //   return (event) => {
//   //     const checked = event.target.checked;
//   //     const checkedDevicesDictionary = {...selectedDevices};
//   //     checkedDevicesDictionary[item.DeviceID] = checked;
//   //     props.setSelectedDevices(checkedDevicesDictionary);
//   //   };
//   // }

//   const renderTableHead = () => (
//     <TableHead>
//       <TableRow>
//         {/* <TableCell classes={{root: classes.cell}} align='center'>
//           <input
//             type='checkbox'
//             onChange={changeAll}
//             ref={columnCheckboxRef}
//             checked={items.reduce((accumulator, currentValue) => accumulator && (selectedDevices[currentValue.DeviceID]), true)}/>
//         </TableCell> */}
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('DeviceID')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('IEMI')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('Battery Level')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('Battery Post')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('FW Version')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('HW Version')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('RSRQ')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('RSRP')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('FM Radio Channel')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('RSSI')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('SNR')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('Noise Gain')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('Noise Min RMS')}</TableCell>
//         <TableCell classes={{root: classes.cell}} align='center'>{context.t('Normalize Noise')}</TableCell>
//       </TableRow>
//     </TableHead>
//   );

//   const renderBodyRow = (item) => {
//     const bandsCount = 10;
//     const noiseIntensityMinBandPowerValues = [];
//     for (let i = 0; i < bandsCount; i++) {
//       const val = item[`NoiseIntensityMinBandPower${i + 1}`];
//       noiseIntensityMinBandPowerValues.push(val);
//     }
//     const batteryLevelClass = item.BatteryLevelOK ? classes.valid : classes.invalid;
//     const batteryLevelPostClass = item.BatteryLevelPostOK ? classes.valid : classes.invalid;
//     const rsrqClass = item.RSRQ_dbOK ? classes.valid : classes.invalid;
//     const rsrpClass = item.RSRP_dbmOK ? classes.valid : classes.invalid;
//     const bestRssiClass = item.bestRssiOK ? classes.valid : classes.invalid;
//     const bestSnrClass = item.bestSnrOK ? classes.valid : classes.invalid;

//     return (
//       <TableRow
//         hover
//         key={item.DeviceID}
//         role="checkbox"
//       >
//         {/* <TableCell classes={{ root: classes.cell }} align='center'>
//           <input
//             type='checkbox'
//             checked={selectedDevices[item.DeviceID]}
//             onChange={props.setDeviceChecked(item)}
//           />
//         </TableCell> */}
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.DeviceID}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.IEMI}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={batteryLevelClass} align='center'>{`${(item.BatteryLevel) ? item.BatteryLevel.toFixed(3) : '--'}`}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={batteryLevelPostClass} align='center'>{`${(item.BatteryLevelPost) ? item.BatteryLevelPost.toFixed(3) : '--'}`}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.HardwareRevision}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.FirmwareRevision}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={rsrqClass} align='center'>{item.RSRQ_db}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={rsrpClass} align='center'>{item.RSRP_dbm}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{(item.bestChannel) ? (item.bestChannel / 100).toFixed(2) : '-'}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={bestRssiClass} align='center'>{item.bestRssi}</TableCell>
//         <TableCell classes={{ root: classes.cell }} className={bestSnrClass} align='center'>{item.bestSnr}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.NoiseIntensityGain}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.NoiseIntensityMinRMS}</TableCell>
//         <TableCell classes={{ root: classes.cell }} align='center'>{item.NoiseIntensityMinRmsNormalize}</TableCell>
//       </TableRow>
//     );
//   };

//   return (
//     <TableContainer component={Paper} className={classes.container}>
//       <Table fixedHeader stickyHeader className={classes.table} aria-label="g5 devices table">
//         {
//           renderTableHead()
//         }

//         <TableBody>
//           {
//             selectedItems.length === 0 ?
//               items.map(renderBodyRow) :
//               items.filter((device) => selectedItems.indexOf(device.DeviceID) !== -1).map(renderBodyRow)
//           }
//         </TableBody>
//         <TableFooter classes={{root: classes.footer}}>
//           <TableRow>
//             <TableCell
//               colSpan={14}
//               align='right'>
//                 <IconButton
//                   style={{
//                     position: 'absolute',
//                     left: '2px',
//                     bottom: '3px'
//                   }}
//                   onClick={props.reloadDevices}>
//                   <RefreshIcon/>
//                 </IconButton>
//               {context.t('{count} Devices', {
//                 count: selectedItems ? (selectedItems.length > 0 ? selectedItems.length : items.length) : '-'
//               })}
//             </TableCell>
//           </TableRow>
//         </TableFooter>
//       </Table>
//     </TableContainer>
//   );
// }

// BitsInfoTable.contextTypes = {
//   t: PropTypes.func.isRequired
// };

// BitsInfoTable.propTypes = {
//   items: PropTypes.array.isRequired,
//   local: PropTypes.string,
//   reloadDevices: PropTypes.func.isRequired,
//   selectedItems: PropTypes.array.isRequired,
//   // selectedDevices: PropTypes.object,
//   // setDeviceChecked: PropTypes.func,
//   // setSelectedDevices: PropTypes.func,
//   // selectedItems: PropTypes.array.isRequired,
// };

// export default BitsInfoTable;
