import React from 'react';
import PropTypes from 'prop-types';

const MANUAL = 'manual';
const AUTO = 'auto';

export default class CoupleGenerationControlPanel extends React.Component {

  static get propTypes() {
    return {
      setDrawMode: PropTypes.func,
      generateAutoCouple: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);

    this.handleModeChange = this.handleModeChange.bind(this);

    this.state = {
      selectedMode: AUTO
    };
  }

  handleModeChange(event) {
    const value = event.target.value;
    this.setState({
      selectedMode: value
    });
    this.props.setDrawMode(value);
  }

  render() {
    const { generateAutoCouple } = this.props;

    return (
      <div className="couple-generation-controll">
        <div className="radio">
          <label>
            <input
              type="radio"
              value={MANUAL}
              checked={this.state.selectedMode == MANUAL}
              onChange={this.handleModeChange}
            />
            {this.context.t('manual')}
          </label>
        </div>
        <div className="radio">
          <label
            style={{ marginRight: '20px' }}
          >
            <input
              type="radio"
              value={AUTO}
              checked={this.state.selectedMode == AUTO}
              onChange={this.handleModeChange}
            />
            {this.context.t('auto')}
          </label>
          <a
            className="btn btn-info"
            disabled={this.state.selectedMode != AUTO}
            onClick={generateAutoCouple}
          >{this.context.t('generate')}</a>
        </div>
      </div>
      );
  }
}

CoupleGenerationControlPanel.contextTypes = {
  t: PropTypes.func.isRequired
};
