import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell, ColumnGroup } from 'fixed-data-table-2';

import TextCell from '../../components/TextCell/TextCell';

const classNames = require('classnames');

export default class CouplePathView extends React.Component {

  constructor(props) {
    super(props);

    this.editCouplePathToggle = this.editCouplePathToggle.bind(this);

    this.state = {
      isEditting: false,
    }
  }

  editCouplePathToggle() {
    const edittingState = !this.state.isEditting;
    this.setState({
      isEditting: edittingState,
    });

    this.props.editCouplePathToggle(edittingState);
  }

  render() {
    const { clearCouplePath, generateMode } = this.props;
    const couplePath = this.props.couplePath || [];

    const editButtonTitle = this.state.isEditting ? this.context.t('done') : this.context.t('edit');

    const editButtonState = this.state.isEditting ? 'save' : 'editting';
    const editBtnClasses = classNames(
      'edit',
      editButtonState,
    );

    return (
      <div className="couple-path-table">
        <div className="path-points-table">
          <Table
            rowsCount={couplePath.length}
            groupHeaderHeight={30}
            rowHeight={30}
            width={590}
            height={300}
            headerHeight={30}
            scrollToRow={couplePath.length}>
            {/* {...this.props} */}

            <ColumnGroup
              fixed={true}
              header={<Cell>{this.context.t('pipe_section_details')}</Cell>}>

              <Column
                columnKey="rowIndex"
                fixed={true}
                header={<Cell>{this.context.t('index')}</Cell>}
                cell={<TextCell data={couplePath} field="index" />}
                width={70}
                // flexGrow={0.35}
              />
              <Column
                columnKey="material"
                fixed={true}
                header={<Cell>{this.context.t('material')}</Cell>}
                cell={<TextCell data={couplePath} field="MaterialName" />}
                width={70}

                // flexGrow={0.35}
              />
              <Column
                columnKey="length"
                fixed={true}
                header={<Cell>{this.context.t('length')}</Cell>}
                cell={<TextCell data={couplePath} field="Length" />}
                width={70}

                // flexGrow={0.35}
              />
            </ColumnGroup>

            <ColumnGroup
              header={<Cell>{this.context.t('point_1')}</Cell>}
              fixed={true}>

              <Column
                columnKey="Longitude"
                header={<Cell>{this.context.t('longitude')}</Cell>}
                cell={<TextCell data={couplePath} field="Longitude1" />}
              // flexGrow={0.7}
              fixed={true}

                width={90}
              />
              <Column
                columnKey="Latitude"
                header={<Cell>{this.context.t('latitude')}</Cell>}
                cell={<TextCell data={couplePath} field="Latitude1" />}
              // flexGrow={0.7}
              fixed={true}

                width={90}
              />
            </ColumnGroup>
            <ColumnGroup
              header={<Cell>{this.context.t('point_2')}</Cell>}
              // fixed={true}
              >

              <Column
                columnKey="Longitude"
                header={<Cell>{this.context.t('longitude')}</Cell>}
                cell={<TextCell data={couplePath} field="Longitude2" />}
                flexGrow={1}
                // fixed={true}

                width={90}
              />
              <Column
                columnKey="Latitude"
                header={<Cell>{this.context.t('latitude')}</Cell>}
                cell={<TextCell data={couplePath} field="Latitude2" />}
                flexGrow={1}
                // fixed={true}

                width={90}
              />
            </ColumnGroup>

          </Table>
        </div>
        <div className="title">
          <span>{this.context.t('couple_path')}</span>
          <i className="clear" onClick={clearCouplePath} disabled={!generateMode} />
        </div>
      </div>
    );
  }
}

CouplePathView.contextTypes = {
  t: PropTypes.func.isRequired
}

