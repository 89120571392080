import { connect } from 'react-redux';
import * as actions from '../../actions/CustomersActions';
import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import CustomerDistributionAllCustomerUsersTable from '../CustomersTabs/CustomerDistributionAllCustomerUsersTable';
import CustomerDistributionListsTable from '../CustomersTabs/CustomerDistributionListsTable';
import CustomerDistributionUsersTable from '../CustomersTabs/CustomerDistributionUsersTable';
import { Button } from '@mui/material';
import ReactSelect from "react-select";
import Checkbox from '@mui/material/Checkbox';

class CustomerDistributionListsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      newListName: undefined,
      selectedAlertStateNoise: null,
      selectedAlertState: null,
      selectedIsValveMonitoring: false
    };

    this.onChangeNewListName = this.onChangeNewListName.bind(this);
    this.createNewList = this.createNewList.bind(this);
    this.updateListUsers = this.updateListUsers.bind(this);
    this.getDivStyle = this.getDivStyle.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedList && nextProps.selectedList.id !== this.props.selectedList.id) {
      const corrStatuses = nextProps.selectListOptions.corrOptions.filter((option) => {
        return nextProps.selectedList.corrStatuses?.includes(option.value);
      });
      const noiseStatuses = nextProps.selectListOptions.noiseOptions.filter((option) => {
        return nextProps.selectedList.noiseStatuses?.includes(option.value);
      });

      this.setState({
        selectedAlertStateNoise: noiseStatuses,
        selectedAlertState: corrStatuses,
      });
    }

  }
  onChangeNewListName(event) {
    this.setState({
      newListName: event.target.value
    });
  }

  createNewList() {
    if (this.props.isProjectLevel){
      const projectId = this.props.selectedProject;
      this.props.postNewProjectList(projectId, this.state.newListName);

    } else {
      const customerId = this.props.selectedCustomer.ID;
      this.props.postNewList(customerId, this.state.newListName);
    }
  }
  updateListUsers() {
    if (this.props.isProjectLevel){
      const projectId = this.props.selectedProject;
      this.props.updateProjectsList(projectId, this.state.newListName);

    } else {
      const customerId = this.props.selectedCustomer.ID;
      this.props.updateList(customerId, this.state.newListName);
    }
  }


  getDivStyle(borderColor) {
    return {
      border: 'solid 1px ' + borderColor,
      borderRadius: '5px',
      margin: '3px'
    };
  }

  handleOnHide = () => {
    this.props.selectList({});
    this.props.closeFunction();
  }

  render() {
    const { open, lists, dListUsers, usersList, selectedList, selectListOptions,
      selectList, handleUserClick, handleUserAttributeChange, updateList, updateProjectsList } = this.props;
    // const { isLoading, options, value } = this.state;

    return (
      <AppModal
        closeIcon
        open={open}
        maxWidth={'inherit'}
        onClose={this.handleOnHide}
        title={this.context.t('manage_distribution_lists')}
        content={(
          <div>
          <div style={{display: 'inline-flex'}}>
                <div style={this.getDivStyle('cadetblue')}>
                  <div style={{ width: 'fit-content' }}>
                    <input
                      type="text"
                      placeholder={this.context.t('new_list_item')}
                      value={this.state.newListName}
                      onChange={this.onChangeNewListName}/>
                    <Button variant='contained' onClick={this.createNewList}>{this.context.t('create')}</Button>
                  </div>
                  <CustomerDistributionListsTable
                    items={lists}
                    selectedList={selectedList}
                    selectLisHandler={selectList}
                    selectedCustomer={this.props.selectedCustomer}
                    setDistributionListAsAnnouncesStatusChange={this.props.setDistributionListAsAnnouncesStatusChange}
                    setListIsValveMonitoringChange={this.props.setListIsValveMonitoringChange}
                    deleteDistibutionList={this.props.deleteDistributionList}
                    isProjectLevel={this.props.isProjectLevel}
                  />


                </div>

                <div style={{
                  ...this.getDivStyle('cornflowerblue'),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}>
                  <CustomerDistributionUsersTable
                    items={dListUsers}
                    onUserClick={handleUserClick}
                    onUserAttrChange={handleUserAttributeChange}
                  />
                <input
                  type='button'
                  className='btn btn-info'
                  disabled={selectedList.ID == undefined}
                  value={this.context.t('save_changes')}
                  onClick={this.updateListUsers} />

                </div>
                <div style={this.getDivStyle('lightskyblue')}>
                  <CustomerDistributionAllCustomerUsersTable
                    items={usersList}
                    onUserClick={handleUserClick}
                    isDisabled={selectedList.ID == undefined}
                    user={this.props.currentUser}
                  />
                </div>
              </div>
            { this.props.isProjectLevel && selectedList.id &&
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'max-content 30%',
                columnGap: '20px',
                rowGap: '5px',
                alignItems: 'baseline',
              }}>
                <label>{this.context.t('alert_statuses')}</label>
                <ReactSelect
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={'select'}
                  options={selectListOptions.corrOptions}
                  value={this.state.selectedAlertState}
                  onChange={(selectedOptions) => {
                    // console.log('Selected Options (AlertState):', selectedOptions);
                    this.setState({ selectedAlertState: selectedOptions });
                  }}
                />

                <label>{this.context.t('noise_alert_statuses')}</label>
                <ReactSelect
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={'select'}
                  options={selectListOptions.noiseOptions}
                  value={this.state.selectedAlertStateNoise}
                  onChange={(selectedOptions) => {
                    // console.log('Selected Options (AlertStateNoise):', selectedOptions);
                    this.setState({ selectedAlertStateNoise: selectedOptions });
                  }}
                />

                <label>{this.context.t('valve_monitoring')}</label>
                <div>
                  <Checkbox
                    checked={this.state.selectedIsValveMonitoring}
                    onChange={(event) => {
                      this.setState({ selectedIsValveMonitoring: event.target.checked })
                    }}
                  />
                </div>
                <input
                  type='button'
                  className='btn btn-info'
                  disabled={selectedList.ID == undefined}
                  value={this.context.t('configure')}
                  onClick={() => {
                    const corr = this.state.selectedAlertState.map((option) => option.value);
                    const noise = this.state.selectedAlertStateNoise.map((option) => option.value);
                    const valve = this.state.selectedIsValveMonitoring;
                    this.props.updateProjectListNotificationSettings(selectedList.ID, corr, noise, valve);
                  }} />
              </div>
            }
            </div>
        )}
      />
    );
  }
}

CustomerDistributionListsModal.contextTypes = {
  t: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  // find only users of the same customer:
  const currentUser = state.leaksList.user;
  const selectedCustomer = state.manage.selectedManageItem.item;
  const selectedProject = state.leaksList.selectedProject;
  const selectListOptions = state.leaksList.optionList.options;
  const corrOptions = selectListOptions.AlertState.filter((opt) => opt.is_distribution_list === 1)
  const noiseOptions = selectListOptions.AlertStateNoise.filter((opt) => opt.is_distribution_list === 1)
  const lists = ownProps.isProjectLevel ? state.distributionLists.projectsDLists : state.distributionLists.lists;
  const selectedList = Object.assign({}, state.distributionLists.selectedList);
  const currentUsers = [...state.management.users];
  let allUsers = currentUser.isAQS ? currentUsers.filter(user => user.CustomerID === currentUser.customerId || user.CustomerID === selectedCustomer.ID) : currentUsers;

  const usersList = ownProps.isProjectLevel ? [...state.distributionLists.projectUsers] : [...allUsers];

  const dListUsers = Object.assign([], state.distributionLists.usersInEdit);

  dListUsers.forEach((user) => {
    const userDetails = usersList.find((itrUser) => (itrUser.UID == user.UserID));
    if (userDetails != null) {
      user.FirstName = userDetails.FirstName;
      user.LastName = userDetails.LastName;
    }
  });

  return {
    ...ownProps,
    lists,
    selectedList,
    dListUsers,
    usersList,
    selectedCustomer,
    currentUser,
    selectedProject,
    selectListOptions: { corrOptions, noiseOptions },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectList: (list) => {
      dispatch(actions.selectList(list, ownProps.isProjectLevel));
    },
    postNewList: (customerId, name) => {
      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };
      dispatch(actions.postNewDistributionList(callback, customerId, name));
    },
    postNewProjectList: (projectId, name) => {
      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };
      dispatch(actions.postNewProjectsDistributionList(callback, projectId, name));
    },
    handleUserClick: (user, action) => {
      switch (action) {
        case 'add':
          dispatch(actions.addUserToList(user.UID));
          break;

        case 'remove':
          dispatch(actions.removeUserFromList(user.UserID));
          break;

        default:
          break;
      }
    },
    handleUserAttributeChange: (user, type, value) => {
      dispatch(actions.setUserAttribute(user.UserID, type, value));
    },
    updateList: () => {

      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };

      dispatch(actions.updateUsersList(callback));
    },
    updateProjectsList: () => {
      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };

      dispatch(actions.updateProjectsUsersList(callback));
    },

    updateProjectListNotificationSettings: (listId, corr, noise, valve) => {
      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };
      dispatch(actions.updateProjectListNotificationSettings(callback, listId, corr, noise, valve))
    },

    setDistributionListAsAnnouncesStatusChange: (customerId, listObj, checked) => {
      dispatch(actions.setListAnnouncesStatusChange(customerId, listObj.ID, checked, (isSuccess) => {
        const notifyMessage = isSuccess ? 'Change saved' : 'Faild';
        const level = isSuccess ? 'success' : 'error';
        ownProps.notify(notifyMessage, level);
      }));
    },
    setListIsValveMonitoringChange: ( listObj, checked) => {
      dispatch(actions.setListIsValveMonitoringChange(  listObj.ID, checked, (isSuccess) => {
        const notifyMessage = isSuccess ? 'Change saved' : 'Faild';
        const level = isSuccess ? 'success' : 'error';
        ownProps.notify(notifyMessage, level);
      }));
    },
    deleteDistributionList: ( listId ) => {
      dispatch(actions.deleteDistributionList(  listId,(isSuccess) => {
        const notifyMessage = isSuccess ? 'Change saved' : 'Faild';
        const level = isSuccess ? 'success' : 'error';
        ownProps.notify(notifyMessage, level);
      }));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDistributionListsModal);
