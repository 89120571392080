import * as React from 'react';
import PropTypes from 'prop-types';

import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { useDispatch } from 'react-redux';
import {
  sortG5Devices,
  storeG5DevicesAction,
} from '../../../actions/DeviceConfigurationAction';
import SortableHeaderCell from '../../SortableHeaderCell/SortableHeaderCell';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';

const CustomeCell = (props) => (
  <Cell>{props.items[props.rowIndex][props.columnKey]}</Cell>
);

const ServerFieldCell = (props) => {
  const val = props.items[props.rowIndex][props.columnKey];
  const valDisplay = val ? val.split('.')[0] : '--';
  return <Cell>{valDisplay}</Cell>;
};

function StatusesTable(props, context) {
  const { items, sort, local, uiInfo, options } = props;

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(-1);
  const parsedItems = items.map((item) => {
    const option = options.FM2ConfigType.find(
      (option) => option.code === item.fmReceiverChannel2_Configuration
    );
    return {
      ...item,
      fmReceiverChannel2_Configuration_abbrviation: option?.abbrviation,
    };
  });

  const dispatch = useDispatch();

  const [preFilterItems, setPreFilterItems] = React.useState([]);
  const lastSelectedIndex = React.useRef();

  function renderBatteryField(value) {
    return value ? value.toFixed(3) : '--';
  }

  const getMultiRowsBySelection = (index) => {
    const start = Math.min(index, lastSelectedIndex.current);
    const end = Math.max(index, lastSelectedIndex.current);
    const multiSelect = items
      .filter((_item, itemIndex) => {
        if (itemIndex >= start && itemIndex <= end) {
        }
        return itemIndex >= start && itemIndex <= end;
      })
      .map((item) => item.DeviceID);
    return multiSelect;
  };

  const filterCheckboxRender = (item, index) => (
    <Cell>
      <input
        type='checkbox'
        checked={preFilterItems.indexOf(item.DeviceID) !== -1}
        onChange={(event) => {
          const { checked } = event.target;
          const { shiftKey } = event.nativeEvent;
          let preFiltersArr = [...preFilterItems];
          if (checked) {
            if (!shiftKey) {
              preFiltersArr.push(item.DeviceID);
            } else {
              const multiSelect = getMultiRowsBySelection(index);
              preFiltersArr.push(...multiSelect);
            }
          } else {
            if (!shiftKey) {
              // unchecked selected
              preFiltersArr = preFiltersArr.filter(
                (itemDeviceId) => itemDeviceId !== item.DeviceID
              );
            } else {
              const multiSelect = getMultiRowsBySelection(index);
              preFiltersArr = preFiltersArr.filter(
                (itemDeviceId) => !multiSelect.includes(itemDeviceId)
              );
            }
          }
          setPreFilterItems(preFiltersArr);
          lastSelectedIndex.current = index;
        }}
      />
    </Cell>
  );

  const handleRowClick = (event, rowIndex) => {
    setSelectedItemIndex(rowIndex);
  };

  const rowClassNameGetter = (rowIndex) => {
    return rowIndex === selectedItemIndex ? 'active-row' : null;
  };

  const sortTable = {
    sortDir: sort.direction,
    sortBy: sort.field,
    sort: (columnKey) => {
      dispatch(sortG5Devices(columnKey));
      setSelectedItemIndex(-1);
    },
  };

  return (
    <section>
      <Table
        rowsCount={parsedItems.length}
        width={uiInfo.pageWidth / 1.1635}
        maxHeight={uiInfo.pageHeight / 1.0909}
        headerHeight={50}
        rowHeight={40}
        onRowClick={handleRowClick}
        rowClassNameGetter={rowClassNameGetter}
      >
        <Column
          fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey=''
          width={60}
          header={
            <Cell>
              <Button
                variant='contained'
                onClick={() => {
                  props.handleSelection(preFilterItems);
                  setPreFilterItems([]);
                }}
              >
                {context.t('filter')}
              </Button>
            </Cell>
          }
          cell={(props) =>
            filterCheckboxRender(parsedItems[props.rowIndex], props.rowIndex)
          }
        />
        <Column
          fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='#'
          width={40}
          header={<Cell>#</Cell>}
          cell={(props) => <Cell>{props.rowIndex + 1}</Cell>}
        />
        <Column
          fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='ID'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='DeviceID'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='LastStatusReportTime'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = parsedItems[props.rowIndex][props.columnKey];
            const time = UIDateFormater(value, DateType.DATE_AND_TIME, local);
            return <Cell>{time}</Cell>;
          }}
        />
        <Column
          align='center'
          columnKey='BatteryLevel'
          width={75}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = parsedItems[props.rowIndex][props.columnKey];
            return <Cell>{renderBatteryField(value)}</Cell>;
          }}
        />
        <Column
          align='center'
          columnKey='BatteryLevelPost'
          width={75}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = parsedItems[props.rowIndex][props.columnKey];
            return <Cell>{renderBatteryField(value)}</Cell>;
          }}
        />
        <Column
          align='center'
          columnKey='AccessTechnologyName'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => (
            <Cell>{parsedItems[props.rowIndex][props.columnKey]}</Cell>
          )}
        />
        <Column
          align='center'
          columnKey='IEMI'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          align='center'
          columnKey='ICCID'
          width={180}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          align='center'
          columnKey='StatusTimeZone'
          width={160}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='HardwareRevision'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='FirmwareRevision'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='RSRQ_db'
          width={60}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='RSRP_dbm'
          width={60}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='StatusFmReceiverChannel_10k'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='StatusFmReceiverChannel2_10k'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='fm_channel_snr'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='fm_channel2_snr'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='fmReceiverChannel2_Configuration_abbrviation'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='APN'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='Server'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={parsedItems} />}
        />
        <Column
          columnKey='FallbackServer'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={parsedItems} />}
        />
        <Column
          columnKey='time_source'
          width={120}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = parsedItems[props.rowIndex][props.columnKey];
            if (value === 0) {
              return <Cell>{'TLV'}</Cell>;
            } else {
              return <Cell>{'Cellular'}</Cell>;
            }
          }}
        />
        <Column
          columnKey='is_valve_monitor'
          width={120}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='isMini'
          width={120}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='modem_fw_rev'
          width={120}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='dfu_fw_rev'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='dfu_type'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='dfu_block_num'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
        <Column
          columnKey='random_backoff_connect'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={parsedItems} />}
        />
      </Table>
      <footer style={{ backgroundColor: 'white' }}>
        <IconButton onClick={props.reloadDevices}>
          <RefreshIcon fontSize='small' />
        </IconButton>

        <p style={{ float: 'right', margin: '5px 10px' }}>
          <span>
            {context.t('selected_param', {
              selectedItems: selectedItemIndex + 1,
            })}
          </span>
          &emsp;
          <span>
            {parsedItems.length} {context.t('items')}
          </span>
        </p>
      </footer>
    </section>
  );
}

StatusesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

StatusesTable.propTypes = {
  items: PropTypes.array.isRequired,
  local: PropTypes.string,
  reloadDevices: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  workingMode: PropTypes.string.isRequired,
};

export default StatusesTable;
