import React from 'react';
import PropTypes from 'prop-types';

import I18n from "redux-i18n";
import { translations } from '../translations/tr';

export default function App(props) {

  return (
    <I18n translations={translations}>
      <div className="page-container">
        {props.children}
      </div>
        <input
            style={{ display: 'none' }}
            id="recaptcha"
            type="submit"
            value="Send Phone"
        />

    </I18n>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired
};
