import * as types from '../constants/ActionTypes';

export const CREATE_COUPLE_STEPS = {
  0: 'idle',
  1: 'Choose Sensor 1',
  2: 'Choose Sensor 2',
  3: 'Select draw mode',
  4: 'Draw path',
  5: 'Path complete. Please select material and "Save" to create a couple.',
  6: 'Submitting...',
  7: 'Loading pipes', // For auto path
  8: 'Calculating path', // For auto path
};

const DEFAULT_MATERIAL_CODE = 1; // Metal;
const DEFAULT_VELOCITY_CODE = null;
const DEFAULT_PATHLENGTH_CODE = 0;

const initialState = {
  step: 0,
  isActive: false,
  editMode: false, // boolean. is in edit mode or not.
  newCouple: null,
  setupSensors: false,
  edittingMode: 'auto', // enum. null OR 'auto' OR 'manual'
  isCouplePathReady: false,
  couplePath: [],
  sectionsCouplePath: [],
  isSubmitting: false,
  pipes: null,
};

const planning = (state = initialState, action) => {
  const actionType = (action == null) ? "" : action.type;
  switch (actionType) {
    case "@@router/LOCATION_CHANGE":
      if (action.payload.pathname != 'couples') {
        return Object.assign({}, state, initialState);
      } else {
        return (state);
      }

    //case "@@redux-form/CHANGE":
    //  if (action.meta != null && action.meta.form == 'coupleDetails') {
    //    if (state.isActive) {
    //      if (action.meta.field == 'MaterialCode') {
    //        return Object.assign({}, state, {
    //          newCouple: Object.assign({}, state.newCouple, {
    //            MaterialCode: action.payload
    //          }),
    //        });
    //      }
    //    }
    //  }
    //  return (state);

    case types.SET_COUPLE_EDIT_MODE:
      return Object.assign({}, state, {
        editMode: action.editMode,
      });

    case types.SELECT_LEAK:
      return Object.assign({}, state, {
        editMode: false,
        couplePath: [],
        step: 0,
      });

    case types.CREATE_NEW_COUPLE: {
      let couple = null;
      if (action.newCouple != null) {
        couple = Object.assign(action.newCouple, {
          MaterialCode: state.defaultMaterialCode || DEFAULT_MATERIAL_CODE,
        });
      }
      return Object.assign({}, state, {
        isActive: action.isActive,
        //newCouple: couple
      });
    }
    case types.SETUP_NEW_COUPLE:
      return Object.assign({}, state, {
        setupSensors: action.isSetup,
        step: 1,
      });

    case types.RESET_NEW_COUPLE: {
      const coupleRemains = {
        MaterialCode: state.defaultMaterialCode || DEFAULT_MATERIAL_CODE,
        VelocityMS: DEFAULT_VELOCITY_CODE,
        PathLengthM: DEFAULT_PATHLENGTH_CODE
      };
      return Object.assign({}, state, initialState, {
        isActive: true,
        setupSensors: true,
        step: 1,
        newCouple: {},
      });
    }
    case types.SELECT_SENSOR_FOR_NEW_COUPLE: {

      // the related steps:
      // 1: 'Choose Sensor 1'
      // 2: 'Choose Sensor 2'
      // 3: 'Select draw mode'
      const nextStep = (action.key == '1') ? 2 : 3;
      return Object.assign({}, state, {
        editMode: true,
        step: nextStep,
      });
    }

    case types.SET_EDIT_COUPLE_PATH_MODE:
      return Object.assign({}, state, {
        edittingMode: action.edittingMode,
        step: 4
      });

    case types.UPDATE_COUPLE_PATH_POINTS:
      return action.sectionsCouplePath != null ? Object.assign({}, state, {
        couplePath: action.pathPoints,
        sectionsCouplePath: action.sectionsCouplePath
      }) : Object.assign({}, state, {
        couplePath: action.pathPoints,
      });

    case types.SET_COUPLE_PATH_POINT: {
      const edittedPointItem = state.couplePath.find(item => {
        return item.index == action.index;
      });
      const edittedPoint = Object.assign({}, edittedPointItem, {
        Longitude: action.point[0],
        Latitude: action.point[1],
      });
      const editedCouplePath = Object.assign([], state.couplePath);
      editedCouplePath[action.index] = edittedPoint;
      return Object.assign({}, state, {
        couplePath: editedCouplePath
      });
    }
    case types.SET_COUPLE_PATH_LENGTH:
      return Object.assign({}, state, {
        newCouple: Object.assign({}, state.newCouple, {
          PathLengthM: action.length
        })
      });
    case types.CLEAR_COUPLE_PATH:
      if (state.couplePath != undefined) {
        const path = state.couplePath.length > 0 ? [state.couplePath[0]] : [];
        // const sectionsPath = state.sectionsCouplePath.length >= 0 ? state.sectionsCouplePath[0] : [];
        return Object.assign({}, state, {
          couplePath: path,//path
          sectionsCouplePath: []
        });
      }
      return Object.assign({}, state, {
        couplePath: [],//path
        sectionsCouplePath: []
      });

    case types.CLEAR_ALL_NEW_COUPLE_DATA:
      return Object.assign({}, state, initialState);
    case types.SET_COUPLE_PATH_READY:
      const stepNext = action.isCouplePathReady ? 5 : 4;
      return Object.assign({}, state, {
        isCouplePathReady: action.isCouplePathReady,
        step: stepNext,
      });

    case types.GENERATE_COUPLE_REQUEST:
      return Object.assign({}, state, {
        isSubmitting: true,
        step: 6,
        defaultMaterialCode: action.values.material,
      });
    case types.GENERATE_COUPLE_RECEIVE:
      return Object.assign({}, state, {
        isSubmitting: false,
        step: 5, // return to 'path complete'. If created successfuly - so 'clear all' will dispatch
      });
    case types.REQUEST_PROJECT_PIPES:
      return Object.assign({}, state, {
        pipes: null,
        step: 8, // 'Looking for automatic path'
      });
    case types.RECEIVE_PROJECT_PIPES:
      return Object.assign({}, state, {
        pipes: action.pipes,
      });
    default:
      return state;
  }
};

export default planning;
