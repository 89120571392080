// /* eslint-disable react/no-multi-comp */

import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactSelect from 'react-select';
import { PuffLoader } from 'react-spinners';

import Typography from '@mui/material/Typography';
import AppModal from './AppModal';

import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import 'jspdf-autotable';

import ReactExport from 'react-data-export';
import JsonToTable from './JsonToTable';
import { END_POINT } from '../../constants/EndPoints';
import { getDateForExcelExports } from '../../actions/Utilities/dateUtils';

require('../TableActions/TableActions.scss');
require('./../Picker/Picker.scss');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const reportTypes = [
  { value: 'sensorActivity', label: 'Sensor Activity' },
  { value: 'sensorActivityByType', label: 'Sensor Activity By Type' },
  { value: 'sensorIssues', label: 'Sensor Issues' },
  { value: 'alertReport', label: 'Burst Report' },
  { value: 'sensorFmIssues', label: 'Sensor FM Issues' },
];

export default function AdminSensorsReportsModal(props, context) {
  const classes = useStyles();
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectedReportLabel, setSelectedReportLabel] =
    useState('Select Report Type');

  const [xlsData, setXlsData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openReportsModal, setOpenReportsModal] = useState(false);
  const [fetchAttempted, setFetchAttempted] = useState(false);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const sortedData = useMemo(() => {
    const dataToSort = [...filteredData];
    let sorted = dataToSort.sort((a, b) => {
      if (sortConfig.key) {
        const valueA = a[sortConfig.key];
        const valueB = b[sortConfig.key];
        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
    return sorted;
  }, [filteredData, sortConfig]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredData(reportData);
      return;
    }

    const keys = Object.keys(reportData[0]);
    const ourFilteredData = reportData.filter((row) =>
      keys.some(
        (key) =>
          row[key] &&
          row[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    setFilteredData(ourFilteredData);
  }, [searchQuery, reportData]);

  useEffect(() => {
    const tableDataToXls = () => {
      if (Array.isArray(sortedData)) {
        const columns = extractTitles(sortedData);
        const data = extractData(sortedData);
        setXlsData([{ columns, data }]);
      }
    };

    tableDataToXls();
  }, [sortedData]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const dispatch = useDispatch();
  const customers = useSelector((state) => state.leaksList.customers.items);
  const customersOptions = useMemo(
    () =>
      customers.map((customer) => ({
        value: customer.ID,
        label: customer.Name,
      })),
    [customers]
  );

  const extractTitles = (reportData) => {
    if (reportData.length === 0) return [];
    return Object.keys(reportData[0]).map((key) => ({
      title: key,
      width: { wch: 30 },
    }));
  };

  const extractData = (reportData) => {
    return reportData.map((item) =>
      Object.values(item).map((value) => ({
        value: value,
        style: { alignment: { horizontal: 'center' } },
      }))
    );
  };

  const selectReport = (selectedOption) => {
    if (selectedOption) {
      setSelectedReport(selectedOption.value);
      setSelectedReportLabel(selectedOption.label);
    }
  };

  const generateReportByType = () => {
    let type = selectedReport;

    const url = `${END_POINT}/dashboard/report/${type}`;

    dispatch(async (dispatch, getState) => {
      const { user } = getState().user;
      const token = await user.getIdToken();
      setIsLoading(true);
      setFetchAttempted(true);

      try {
        const response = await fetch(url, {
          headers: {
            token,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const reportData = await response.json();
        if (reportData.data && reportData.data.length !== 0) {
          if (selectedReport === 'sensorFmIssues') {
            const cleanedData = reportData.data.map((item) => {
              const { ProjectID, ...rest } = item;
              return rest;
            });

            setReportData(cleanedData);
          } else {
            setReportData(reportData.data);
          }
        } else {
          throw new Error('No data available');
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setReportData([]);
        setReportData([{ error: 'There is no data available' }]);
      } finally {
        setIsLoading(false);
        setSearchQuery('');
        setSortConfig({ key: '', direction: '' });
      }
    });
  };

  const handleOpenReportsModal = () => {
    setOpenReportsModal(!openReportsModal);
  };

  return (
    <div className='admin-report-con'>
      <div className='table-actions'>
        <button
          onClick={handleOpenReportsModal}
          className={'btn btn-link reportsBtn'}
          title={context.t('open_reports')}
        />
      </div>

      <AppModal
        closeIcon
        fullWidth
        maxWidth='lg'
        title={context.t('sensors_admin_report')}
        open={openReportsModal}
        onClose={handleOpenReportsModal}
        content={
          <div className={classes.container}>
            {isLoading ? (
              <div style={{ margin: 'auto', width: 'fit-content' }}>
                <PuffLoader size={100} loading />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '80%',
                    flexWrap: 'wrap',
                    marginBottom: '20px',
                  }}
                >
                  <div className={classes.selectCon}>
                    <div className={classes.selectBox}>
                      <Typography component='p' variant='p'>
                        {context.t('report_type')}
                      </Typography>
                      <ReactSelect
                        className={classes.select}
                        options={reportTypes}
                        onChange={selectReport}
                        placeholder={
                          selectedReportLabel
                            ? selectedReportLabel
                            : context.t('select_report')
                        }
                        value={selectedReport}
                      />
                    </div>
                  </div>

                  <div className={classes.btnBox}>
                    <Button
                      className={classes.btn}
                      variant='contained'
                      color='primary'
                      onClick={generateReportByType}
                      disabled={!selectedReport}
                    >
                      {context.t('generate_report')}
                    </Button>

                    {reportData?.length === 0 ? (
                      <Button
                        className={`${classes.btn} ${classes.secondBtn}`}
                        variant='contained'
                        color='primary'
                        size='small'
                        startIcon={<SaveIcon />}
                        disabled
                      >
                        {context.t('save_as_excel')}
                      </Button>
                    ) : (
                      <ExcelFile
                        filename={`${getDateForExcelExports()}_${selectedReportLabel}`}
                        element={
                          <Button
                            className={`${classes.btn} ${classes.secondBtn}`}
                            variant='contained'
                            color='primary'
                            size='small'
                            startIcon={<SaveIcon />}
                          >
                            {context.t('save_as_excel')}
                          </Button>
                        }
                      >
                        <ExcelSheet dataSet={xlsData} name={selectedReport} />
                      </ExcelFile>
                    )}
                  </div>
                </div>

                <div className={classes.tableBox}>
                  {fetchAttempted &&
                  reportData.length === 1 &&
                  reportData[0].error ? (
                    <Typography variant='h6' color='error'>
                      {reportData[0].error}
                    </Typography>
                  ) : (
                    <>
                      {fetchAttempted &&
                      (selectedReport === 'sensorIssues' ||
                        selectedReport === 'alertReport') ? (
                        <p>* {context.t('based_on_last_weeks_data')}</p>
                      ) : null}

                      <JsonToTable
                        headers={
                          reportData.length ? Object.keys(reportData[0]) : null
                        }
                        jsonData={sortedData}
                        handleSearch={handleSearch}
                        requestSort={requestSort}
                        sortConfig={sortConfig}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}

AdminSensorsReportsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  container: {
    minHeight: '300px',
    height: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#ededed',
    padding: '1em',
    overflow: 'hidden',
  },
  selectCon: {
    display: 'flex',
    marginRight: '10px',
    flexWrap: 'wrap',
    zIndex: 100,
  },
  selectBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1em',
    fontSize: '1.2em',
  },
  select: {
    width: '250px',
    marginTop: '1em',
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
  tableBox: {
    maxWidth: '90%',
    margin: 'auto',
    height: '100%',
    maxHeight: '650px',
  },
  btn: {
    width: '150px',
    margin: '5px auto',
    minHeight: '30px',
  },
  secondBtn: {
    backgroundColor: '#11BBED',
    '&:hover': {
      backgroundColor: '#11BBED',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    },
  },
});
