import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';

import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CInterferencesSettings from '../../containers/CInterferencesSettings/CInterferencesSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
class InterferencesTable extends React.Component {
    render() {
        const { items, indexMap, selectedFeature, pHeight, pWidth, localSortProp, rowClickEvent, goToRow, rowClassNameGetter, t } = this.props;

        //document.title = 'AQS • Alerts';

        const totalItemsStyle = {
            float: 'right',
            padding: '2px 8px',
        };

        return (
            <div>
                <Table
                    maxHeight={pHeight - 93}
                    width={pWidth}
                    rowsCount={items.length}
                    rowHeight={30}
                    headerHeight={30}
                    onRowClick={rowClickEvent}
                    rowClassNameGetter={rowClassNameGetter}
                    scrollToRow={goToRow}
                    {...this.props}
                >
                    <Column columnKey="id" width={70} flexGrow={1}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="id" />} />
                    <Column columnKey="Type" width={70} flexGrow={1}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="typeName" />} />
                    <Column columnKey="StartFrequency" width={100} flexGrow={1}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="StartFrequency" />} />
                    <Column columnKey="EndFrequency" width={100} flexGrow={1}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="EndFrequency" />} />
                    <Column columnKey="StartTime" width={100} flexGrow={3}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="StartTime" dateType={DateType.TIME} additional={this.props.timeZone} />} />
                    <Column columnKey="EndTime" width={100} flexGrow={3}
                        header={<SortableHeaderCell sortTable={localSortProp} />}
                        cell={<TextCell data={items} field="EndTime" dateType={DateType.TIME} additional={this.props.timeZone} />} />
                </Table>

                {/* @TODO: Implement export projects to excel button */}
                {items && items.length > 0 &&
                    <CTableActions
                        type="interferences"
                        notify={this.props.notify}
                    />
                }

                {false && items && items.length > 0 &&
                    <CTableActions
                        type="interferences"
                        notify={this.props.notify}
                    />
                }
                {items && items.length > 0 &&
                  <span style={totalItemsStyle}>{t('selected_selectedindex_total_total', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
                }

            </div>
        );
    }
}

InterferencesTable.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InterferencesTable = TableMixin(InterferencesTable, CInterferencesSettings);
