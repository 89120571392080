import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { i18nState } from "redux-i18n";

import devcieConfigurations from '../reducers/devcieConfigurations';
import distributionLists from '../reducers/DistributionLists';
import graphs from '../reducers/graphs';
import iQuarius from '../reducers/iQuariusReducers';
import install from '../reducers/install';
import leaksList from '../reducers/MainReducer';
import local from '../reducers/local';
import login from '../reducers/login';
import manage from '../reducers/Manage';
import management from '../reducers/management';
import mapState from '../reducers/mapLayers';
import noiseAlerts from '../reducers/noiseAlerts';
import pipeAssessments from '../reducers/pipeAssessments';
import planning from '../reducers/planning';
import reports from '../reducers/reports';
import samples from '../reducers/Samples';
import temp from '../reducers/temp';
import user from '../reducers/user';
import mobileDevices from '../actions/MobileDevicesActions';
import mobileTasks from '../actions/MobileTasksActions';
import mobileSamples from '../actions/MobileSamplesActions';
import mobileAlerts from '../actions/MobileAlertsActions';
import mobileState from '../actions/MobileStateAction';
import { slice as valveAlertSlice } from '../actions/AlertsValveActions';

//import {i18nState} from "redux-i18n/immutable"

//
const rootReducer = combineReducers({
  devcieConfigurations,
  distributionLists,
  form: formReducer,
  graphs,
  i18nState,
  iQuarius,
  install,
  leaksList,
  local,
  manage,
  management,
  mapState,
  noiseAlerts,
  pipeAssessments,
  planning,
  reports,
  routing: routerReducer,
  samples,
  temp,
  user,
  mobile: combineReducers({
    devices: mobileDevices,
    alerts: mobileAlerts,
    tasks: mobileTasks,
    samples: mobileSamples,
    modeState: mobileState
  }),
  valve: valveAlertSlice.reducer
});

export default rootReducer;
