/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import SpectrogramGraph from './SpectrogramGraph';
import CorrelationGraph from './CorrelationGraph';
import BIAS from './BIAS';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  column: {
    border: 'solid 1px #ccc',
    borderRadius: '20px'
  }
}));

function maxProbGraphs(context, indexVal, data, props) {
  if ( !props.metaData['max_probability_ndx']
    || !props.metaData['max_probability_leak_ndx']
    || !props.metaData['max_probability']
    || !props.metaData['bias_acc']
  ) {
    return null;
  }
  const maxProbIndex = props.metaData['max_probability_ndx'][indexVal];
  const titleParams = {
    maxProbIndex: maxProbIndex,
    maxProbLeakIndex: props.metaData['max_probability_leak_ndx'][indexVal],
    maxProbVal: props.metaData['max_probability'][indexVal]
  };
  const biasData = props.metaData['bias_acc'][maxProbIndex];
  return (
    <React.Fragment>
      <Grid item>
        <Typography variant='h6'>{context.t('avg_max_leak_val_ndx', titleParams)}</Typography>
      </Grid>
      <Grid item>
        <SpectrogramGraph
          // graphTitle={context.t('Max Prob Spectrogram {index}', {index: indexVal})}
          data={data.histogram}
          xValues={{
            start: props.graphParams.freq.start,
            step: props.graphParams.freq.step
          }}
          xTitle={context.t('frequency')}
          yTitle={context.t('distance')}
          yAxisLabels={props.distancesForAxis}
        />

      </Grid>
      <Grid item>
        <CorrelationGraph
          // title={context.t('Max Prob Correlation {index}', {index: indexVal})}
          data={[data.correlation]}
          factor={props.graphParams.factor}
          xTitle={context.t('distance')}
          yTitle={context.t('contrast')}
          xAxisLabels={props.distancesForAxis}
          leaksMarkers={props.maxProbabilityPositions}
          sensorsOnAxis={props.sensorsPositions}
        />
      </Grid>
      <Grid item>
        <BIAS
          data={biasData}
          xTitle={context.t('bands')}
          yTitle={context.t('days')}
          hoverMode
        />
      </Grid>
    </React.Fragment>
  );
}

export default function AdvancedGraphs(props, context) {
  const classes = useStyle();
  return (
    <React.Fragment className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="center"
      >
        {
          [0,1,2].map((val) => (
            <Grid item key={val}>
              <Grid container direction="column" className={classes.column}>
                {
                  props.maxProbabilityGraphs[val] &&
                  maxProbGraphs(context, val, props.maxProbabilityGraphs[val], props)
                }
              </Grid>
            </Grid>
            )
          )
        }
      </Grid>
    </React.Fragment>
  );
}

AdvancedGraphs.contextTypes = {
  t: PropTypes.func.isRequired
};
