import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';
import Input from './../Input/Input';
const classNames = require('classnames');

class AddNoiseAlertForm extends React.Component {

  static get propTypes() {
    return {
        user: PropTypes.object,
        getAddressByLatLong: PropTypes.func,
        isOpen: PropTypes.bool,
        doClose: PropTypes.func,
        options: PropTypes.object,
        onCreateNewValue: PropTypes.func,
        couplesIdsData: PropTypes.array,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        error: PropTypes.string,
    };
  }

  render() {
    const { isOpen, doClose, user, AlertStateNoise, onCreateNewValue, couplesIdsData, submitting, handleSubmit, getAddressByLatLong } = this.props;

    const panelClasses = classNames(
      'add-alert-form',
      { 'show': isOpen }
    );

    const formClass = 'form-group auto-center';
    const labelsClass = 'col-xs-3 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    const divStyle = { float: 'left', width: '100%' };

    return (
      <div className={panelClasses}>
        <div className="new-alert-header">
        <div className="actions">
            <i className="icon-close" onClick={doClose} />
          </div>
          <h2>{this.context.t('add_new_noise_alert')}</h2>
        </div>
        <form
          style={divStyle}
          className="form-horizontal sticky-actions"
          onSubmit={handleSubmit}>
            {/* <div style={divStyle}> */}
            {/* Status */}
            <div className={formClass}>
              <label className={labelsClass}>{this.context.t('status')}</label>
              <div className={fieldsClass}>
                <Field name="AlertState"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('select')}
                    options={AlertStateNoise}
                  />)
                  }
                />
              </div>
            </div>

            {/* Repair Date */}
            <div className={formClass}>
              <label className={labelsClass}>{this.context.t('event_date')}:</label>
              <div className={fieldsClass}>
                <Field name="CreateDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Repair Date */}
            <div className={formClass}>
              <label className={labelsClass}>{this.context.t('alert_date')}:</label>
              <div className={fieldsClass}>
                <Field name="InsertDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

          {/* Comment */}
          <div className={formClass}>
            <label className={labelsClass}>{this.context.t('comment')}:</label>
            <div className={fieldsClass}>
              <Field name="Comment"
                component={Input}
                type="text"
                className="form-control"
                disabled={!user.editMode} />
            </div>
          </div>

          <div className="form-actions text-right">
            <a
              className="btn btn-info"
              onClick={doClose}>{this.context.t('cancel')}</a>
            <button
              className="btn btn-success"
              type="submit"
              disabled={submitting}>{this.context.t('save')}</button>
          </div>
        </form>
      </div>
    );
  }
}

// validators:
const validate = (values) => {
  const errors = {};

  // check Required fields:
  const requiredFields = ['InsertDate', 'CreateDate', 'AlertState'];
  requiredFields.forEach((fieldKey) => {
    if (values[fieldKey] == null || values[fieldKey] === '') {
      errors[fieldKey] = 'Required';
    }
  });

  return (errors);
};

AddNoiseAlertForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const addNoiseAlertForm = reduxForm({
  form: 'add-noise-alert', // a unique name for this form
  enableReinitialize: true,
  validate,
})(AddNoiseAlertForm);

export default addNoiseAlertForm;
