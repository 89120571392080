import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import Favicon from 'react-favicon';
import React, { useEffect, useState } from 'react';
import CreateMobileAlertModal from '../../components/Modals/CreateMobileAlertModal';
import Picker from '../../components/Picker/Picker';
import Header from '../../components/Header/Header';
import { CMainMap } from '../CMap/CMainMap';
import { CTableSwitch } from '../CTableSwitch/CTableSwitch';
import { CTabsSwitch } from '../CTabsSwitch/CTabsSwitch';
import { getUIinfo } from '../UIhelper/UIhelper';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import classNames from 'classnames';
import * as actionsProjects from '../../actions/ProjectsActions';
import { doSignOut } from '../../actions/LoginActions';
import * as actionsMobileTasks from '../../actions/MobileTasksActions';
import * as actionsMobileAlerts from '../../actions/MobileAlertsActions';
import * as actionsMobileSamples from '../../actions/MobileSamplesActions';
import * as globalsActions from '../../actions/GlobalsActions';
import PopupAlert from '../../components/Notifications/PopupAlert';

import { getMobileTask } from '../../actions/SearchActions';

import _ from 'lodash';
let getMapCaptureImpl;
let setMapLayersDisplayImpl;
const MobilePage = (props) => {
  const {
    path,
    projects,
    logo,
    selectedProject,
    iconFeatures,
    selectedFeatureType,
    projectGraphsUnits,
    selectedTaskId,
    dispatch,
    isFetching,
    user,
    timeZone,
    selectedFeature,
    filters,
    center,
    timezone,
    units,
    distanceFactor,
    items,
    indexMap,
    mobileTasksFilters,
    optionList,
    checkedSamples,
    displayItems,
  } = props;
  //   this.state = {
  //     mapCenter: [],
  //     uiInfo: getUIinfo({ minWidth: 1400 }),
  //     showDetails: false,
  //     sortBy: 'priority',
  //     sortDir: '',
  //   };
  // }
  const sys = SYSTEM_RESOURCE;
  const removeFilter = (filter) => {
    const defFilters = mobileTasksFilters.defFilters;
    const oldFilters = mobileTasksFilters.filters;
    const newDefFilters = { ...defFilters };
    const datesFields = ['CreationDate'];

    if (datesFields.indexOf(filter) !== -1) {
      delete newDefFilters[`${filter}-To`];
      delete newDefFilters[`${filter}-From`];
    }
    delete newDefFilters[filter];
    const newFilters = oldFilters.filter(function (f) {
      return f.field !== filter;
    });

    dispatch(
      actionsMobileTasks.filterTasks({
        defFilters: newDefFilters,
        filters: newFilters,
      })
    );
  };

  const clearSelectedFeature = () => {
    dispatch(actionsMobileTasks.setSelectedTask([]));
    dispatch(actionsMobileSamples.setSelectedSample({}));
  };
  const [uiInfo, setUiInfo] = useState(getUIinfo({ minWidth: 1400 }));
  const [showDetails, setShowDetails] = useState(false);
  const [isAddMobileAlertOpen, setIsAddMobileAlertOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    popupKey: -1,
    message: '',
    severity: 'info',
    action: null,
  }
  )

  const toggleDetailsVisibility = () => {
    setShowDetails(!showDetails);
  };

  const getMapCaptureFunc = (impl) => {
    getMapCaptureImpl = impl;
  };

  const setMapLayersDisplayFunc = (impl) => {
    setMapLayersDisplayImpl = impl;
  };
  const createMobileAlertModalResetForm = () => {
    dispatch(reset('add-mobile-alert'));
  };

  const notify = (message, level, action) => {
    setPopupState({
      popupKey: new Date().getTime(),
      message: message,
      severity: level,
      action: action,
    })
  }

  useEffect(() => {
    const cb = () => {
      setUiInfo(getUIinfo({ minWidth: 1400 }));
    }
    window.addEventListener('resize', cb);
    return () => {
      window.removeEventListener('resize', cb);
    }
  });
  useEffect(() => {
    setShowDetails(false);
  }, [path, setShowDetails]);
  const mainClasses = classNames('main', { fetching: isFetching });

  return (
    <section className="page leaks-page">
      <Favicon
        url={require('./../../../images/system/' + sys + '/favicon_1.png')}
      />

      <Header
        selectedProject={selectedProject}
        onPathChange={() => { }}
        user={user}
        local={timeZone}
        downloadFile={() => { }}
        logo={logo}
        signOutFunc={() => {
          dispatch(doSignOut());
        }}
      />

      <div className={mainClasses}>
        {
          <Picker
            value={selectedProject}
            onChange={(nextProject) => {
              dispatch(actionsProjects.selectProject(nextProject));
            }}
            options={projects}
          />
        }
        <CMainMap
          sensors={[]}
          sops={[]}
          iconFeatures={iconFeatures}
          uiInfo={uiInfo}
          selectedFeature={selectedFeature}
          selectedFeatureType={selectedFeatureType}
          filters={filters}
          center={center}
          path={path}
          // selectSample={this.selectSample}
          // selectCoupleSample={this.selectCoupleSample}
          selectedProject={selectedProject}
          showLayerManager={true}
          showLegend={true}
          showSearchBox={true}
          // reloadNoiseSamples={this.reloadNoiseSamples}
          // closeNoise={this.closeNoise}
          timeZone={timeZone}
          graphsUnits={projectGraphsUnits}
          units={units}
          distanceFactor={distanceFactor}
          // downloadFile={this.handleDownloadFile}
          // downloadFileFromURl={this.downloadFileFromURl}
          user={user}
          // viewWaterMeterLayer={user.viewWaterMeter}
          // getMapCenterLatLng={this.updateMapCenterLatLng}
          getMapCapture={getMapCaptureFunc}
          setMapLayersDisplay={setMapLayersDisplayFunc}
          notify={notify}
        />

        <CTableSwitch
          path={path}
          uiInfo={uiInfo}
          showDetails={showDetails}
          sortTableLocaly={(field, dir) =>
            dispatch(
              actionsMobileTasks.sortTasksLocally(_.snakeCase(field), dir)
            )
          }
          onSearchItem={(itemID) => {
            const mobileFeatures = getMobileTask(items, itemID);
            const feature = mobileFeatures.length > 0 ? mobileFeatures : null;
            dispatch(globalsActions.setSelectedItem(feature));
          }}
          removeFilter={removeFilter}
          refreshData={() => {
            const currentProject = this.props.selectedProject;
            this.props.dispatch(actionsProjects.selectProject(currentProject));
          }}
          // selectSample={this.selectSample}
          toggleDetailsVisibility={toggleDetailsVisibility}
          clearSelectedFeature={clearSelectedFeature}
        // getMapCapture={this.getMapCaptureImpl}
        // setMapLayersDisplay={this.setMapLayersDisplayImpl}
        />
        <CTabsSwitch
          setIsAddMobileAlertOpen={setIsAddMobileAlertOpen}
          user={user}
          // projectName={projectName}
          path={path}
          uiInfo={uiInfo}
          selectedProject={selectedProject}
          selectedFeature={selectedFeature}
          featureType={selectedFeatureType}
          // downloadFile={this.handleDownloadFile}
          // onPageIndexChange={this.onPageIndexChange}
          showDetails={showDetails}
          toggleDetailsVisibility={toggleDetailsVisibility}
          clearSelectedFeature={clearSelectedFeature}
          indexMap={indexMap}
          // uploadFile={this.handleUploadFile}
          // samples={samples}
          // selectSample={this.selectSample}
          // sortSamples={this.sortSamples}
          timeZone={timeZone}
          // navigateToFeature={this.navigateToFeature}
          // setProjectCenter={this.setProjectCenter}
          notify={notify}
          selectedTaskId={selectedTaskId}
          units={units}
          clearForm={(formName) => {
            dispatch(reset(formName));
          }}
        // getMapCapture={this.getMapCaptureImpl}
        />
        <CreateMobileAlertModal
          optionList={optionList}
          resetForm={createMobileAlertModalResetForm}
          isOpen={isAddMobileAlertOpen}
          setOpenStatusDialog={setIsAddMobileAlertOpen}
          options={optionList.options}
          createNewMobileAlert={actionsMobileAlerts.createNewMobileAlert}
          selectedFeature={selectedFeature}
          checkedSamples={checkedSamples}
          displayItems={displayItems}
        // onEdit={this.statusModalEditComment}
        // onCopy={this.statusModalCopyComment}
        />

        {popupState && <PopupAlert {...popupState} />}

      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  const {
    selectedProject,
    leaksByProject,
    projectsList,
    optionList,
    filters,
    user,
    customers,
  } = state.leaksList;

  const {
    isFetching,
    lastUpdated,
    iconFeatures,
    sort,
    history,
    files,
    details,
    samples,
  } = leaksByProject[selectedProject] || {
    selectedProject: selectedProject,
    filters: [],
    isFetching: true,
    iconFeatures: [],
    sort: { init: true },
    history: {},
    files: {},
    details: {},
    user: {},
    path: '',
    log: {},
    samples: {},
  };
  let path = state.routing.locationBeforeTransitions.pathname.replace('/', '');
  const {
    mobile: {
      tasks: { items },
      indexMap,
    },
  } = state;
  path = path || 'alerts';

  const lang = state.i18nState.lang;
  const projects = projectsList.items || [];
  const { projectIndexMap } = projectsList;
  const selectedProjectItem = projects[projectIndexMap[selectedProject]];
  const projectGraphsUnits =
    selectedProjectItem != null
      ? selectedProjectItem.UseFeetInMatlabGraphs
        ? 'ft'
        : 'm'
      : 'm';


  const selectedFeatureType = 'mobileTask'; // TODO switch case or leave this way. this page is not in use

  const { mobileTasksSort, mobileTasksFilters } = state.mobile.tasks;

  // const mobileDisplayMode = state.mobile.modeState.mode;

  // if (mobileDisplayMode == 'Tasks') {
  //   const selectedFeature = state.mobile.tasks.selectedTask.length
  //     ? state.mobile.tasks.selectedTask
  //     : [];
  // } else if (mobileDisplayMode == 'Alerts') {
  //   const selectedFeature = state.mobile.alerts.selectedAlert.length
  //     ? state.mobile.alerts.selectedAlert
  //     : [];
  // }

  const selectedFeature = state.mobile.tasks.selectedTask.length
    ? state.mobile.tasks.selectedTask
    : [];


  const units = state.local.units;
  const timeZone = state.local.timeZone;
  let center = { init: false };
  if (projects.length && selectedProject) {
    //@TODO: find the project by id.
    const id = projectIndexMap[selectedProject];
    if (id != null) {
      center = {
        init: true,
        lat: projects[id].RefPointLat,
        lng: projects[id].RefPointLng,
      };
    }
  }
  const distanceFactor = state.local.factor[units];
  return {
    mobileTasksFilters,
    mobileTasksSort,
    lang,
    selectedProject,
    projectGraphsUnits,
    selectedFeature,
    selectedFeatureType,
    isFetching,
    lastUpdated,
    iconFeatures,
    projects,
    selectedProjectItem,
    optionList,
    sort,
    history,
    files,
    details,
    filters,
    user,
    path,
    indexMap,
    center,
    units,
    distanceFactor,
    timeZone,
    customers: customers.items,
    logo: user.logo,
    items,
    checkedSamples: state.mobile.samples.checkedSamples,
    displayItems: state.mobile.samples.displayItems,
  };
};
export default connect(mapStateToProps)(MobilePage);
