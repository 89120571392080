import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import CInterferenceDetails from '../../containers/CInterferences/CInterferenceDetails';

const classNames = require('classnames');
const _ = require('lodash');

export default class InterferencesTabs extends React.Component {

  constructor(props) {
    super(props);

    // this.state = {
    //   nTabIndex: 0,
    //   bShowInstallTab: true,
    // }

    this.closePanel = this.closePanel.bind(this);
    // this.setSelectedTab = this.setSelectedTab.bind(this);
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);

    // the clearSelectedFeature have case in install.js (reducer) that clean the install state:
    this.props.clearSelectedFeature();

    // close map draw mode if needed:
    this.props.closeMapDrawMode();

    this.props.clearForm('interferenceForm');
  }

//   setSelectedTab(selectedIndex) {
//     this.setState({
//       nTabIndex: selectedIndex,
//     });
//   }

  componentWillReceiveProps(nextProps) {
    const { installMode, showDetails, featureType, selectedFeature } = nextProps;
    if (!_.isEmpty(installMode.newInstallItem)) {
      if (!showDetails) {
        this.props.toggleDetailsVisibility(true);
      }
    } else if (featureType == 'interference') {
      if (selectedFeature !== "") {
        if (!showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (showDetails) {
        this.props.toggleDetailsVisibility(false);
      }
    }

    // const bNextShowInstallTab = selectedFeature != null && selectedFeature != "";
    // let nNextTabIndex = this.state.nTabIndex;
    // if (bNextShowInstallTab != this.state.bShowInstallTab) {
    //   if (bNextShowInstallTab == false) {
    //     nNextTabIndex = 0;
    //   } else if (this.state.bNextShowInstallTab == false) {

    //     nNextTabIndex = 1;
    //   }
    //   this.setState({
    //     bShowInstallTab: bNextShowInstallTab,
    //     nTabIndex: nNextTabIndex,
    //   })
    // }
  }

  render() {
    const {
      units,
      distanceFactor,
      timeZone,
      selectedProject,
      selectedFeature,
      uiInfo,
      showDetails,
      projectName,
      notify
    } = this.props;
    // const { bShowInstallTab } = this.state;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
      //(isFilesFetching ? 'fetching' : '')
    );

    const panelStyle = { height: 0.493 * uiInfo.pageHeight };
    //const bShowInstallTab = selectedFeature != null && selectedFeature != "";
    const title = (this.props.installMode.newInstallItem == null) ? this.context.t('area_of_interest_id', {id: selectedFeature}) : this.context.t('create_new_area_of_interest');

    return (
      <div className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={title}
          handleClose={this.closePanel}
          tabs={[ { label: this.context.t('areas_of_interest'), component: <CInterferenceDetails notify={notify} /> } ]}
        />
      </div>
    );
  }

}

InterferencesTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
