import React from 'react';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { featuresTypes, zIndexs } from './Options';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Stroke, Style } from 'ol/style';

import { LineString } from 'ol/geom';
import * as alertsPressure from './AlertsPressure';
import * as noiseAlert from './NoiseAlert';
import * as valveAlert from './ValveAlert';

const proj = require('ol/proj');

const classNames = require('classnames');

let shownShadowId;
let bossFeature;
let lineToBoss;

// Alerts layer style
const style = new Style({
  image: new Icon({
    // See dynamic styles in getAlertStyle()
    src: require('../../../../images/map/alerts/alert-c-open.png'), anchor: [0.5, 1]
  })
});

const source = new VectorSource({
  features: [],
});

// Alerts layer
export const layer = new VectorLayer({
  name: 'layerAlerts',
  style: style,
  source: source,
  zIndex: zIndexs.alerts,
});

export const setSource = (nextIconFeatures, props) => {

  let iconFeatures = [];
  Object.keys(nextIconFeatures).forEach((key) => {
    const itrFeature = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          itrFeature.position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.ALERT,
      id: itrFeature.id,
      priority: itrFeature.priority,
      state: itrFeature.state,
      stateNum: itrFeature.stateNum,
      alertType: itrFeature.alertType,
      typeNum: itrFeature.typeNum,
      alertTypeNum: itrFeature.alertTypeNum,
      intensity: itrFeature.intensity,
      isManual: itrFeature.isManual,
      leakBoss: itrFeature.LeakBoss,
      comment: itrFeature.comment,
      probability: itrFeature.probability,
      closureDate: itrFeature.closureDateMillis,
      fixDate: itrFeature.fixDate,
      detectedAt: itrFeature.detectedAt,
      noiseAlert: itrFeature.noiseAlert,
      position: itrFeature.position,
      boss: {
        id: itrFeature.LeakBoss,
        position: [
          itrFeature.BossLongitude,
          itrFeature.BossLatitude
        ]
      }
    });
    iconFeature.setStyle(getStyle(iconFeature, false, props));
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () => [
  layer,
  ...alertsPressure.getLayers(),
  ...noiseAlert.getLayers(),
  ...valveAlert.getLayers()
];

export const hasFeatures = () =>
  source.getFeatures().length > 0;

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected, props) => {
  let alertStyle = false;
  let iconStatus = false;

  const attr = feature.getProperties();
  bSelected = bSelected ? '-selected' : '';

  const alertType = props.options.AlertType.find((option) => option.value === attr.alertTypeNum);
  // console.log('alertType in get style', alertType);

  if (attr.alertTypeNum == 2 &&
    ([0, 1, 12, 13].indexOf(attr.stateNum) !== -1) // statuses: [Suspected, New, Reopen, Out Of Bounds]
  ) {
    iconStatus = 'irrigation';
  } else if (attr.stateNum == 10) { // TO_BE_FIXED
    iconStatus = `to-be-fixed-${attr.isManual ? 'manual' : 'auto'}`;
  } else if (attr.stateNum == 6) {
    iconStatus = `fixed-${attr.isManual ? 'manual' : 'auto'}`;
  } else if (attr.stateNum == 8) {
    iconStatus = 'shadow';
  }
  else if (attr.stateNum == 9) {
    iconStatus = 'falsepath';
  }
  else if (alertType?.IsArtifact && attr.stateNum === 5) { //artifact && status closed
    iconStatus = 'artifact-closed';
  }
  else if (alertType?.IsArtifact) { // artifact
    iconStatus = 'artifact';
  }
  else if (attr.priority && attr.state) {
    iconStatus = attr.priority + '-' + attr.state;
  }

  if (iconStatus) {
    alertStyle = [new Style({
      image: new Icon({
        src: require('../../../../images/map/alerts/alert-' + iconStatus + bSelected + '.png'),
        anchor: [0.5, 1],
      }),
      zIndex: (bSelected) ? 999 : zIndexs.alerts,
    })];

    if (attr.stateNum == 11) { // 11 = 'Duplicate'
      alertStyle.push(new Style({
        image: new Icon({
          src: require('../../../../images/map/alerts/indicators/indication-duplicate.png'),
          scale: 0.3,
          anchor: [1, 1],
        })
      }));
    }
    // if (attr.stateNum == 2) { // 2 = 'To Be Located'
    //   let anchorIconV;
    //   switch (attr.priority) {
    //     case 'a': anchorIconV = [-0.1, 2.35]; break;
    //     case 'b': anchorIconV = [0.1, 1.95]; break;
    //     case 'c':
    //     case 'd':
    //     default: anchorIconV = [0.15, 1.75]; break;
    //   }

    //   alertStyle.push(new Style({
    //     image: new Icon({
    //       src: require('../../../../images/magnifying-glass.png'),
    //       scale: 0.18,
    //       anchor: anchorIconV,
    //       opacity: 0.85,
    //     }),
    //     zIndex: (((bSelected) ? 999 : zIndexs.alerts) + 10),
    //   }));
    // }
    if (attr.stateNum == 3) { // 3 = 'Located'
      let anchorIconV;
      switch (attr.priority) {
        case 'a': anchorIconV = [-0.1, 2.35]; break;
        case 'b': anchorIconV = [0.1, 1.95]; break;
        case 'c':
        case 'd':
        default: anchorIconV = [0.15, 1.75]; break;
      }
      alertStyle.push(new Style({
        image: new Icon({
          src: require('../../../../images/ic_circle_v.png'),
          scale: 0.1,
          anchor: anchorIconV,
          opacity: 0.9,
        }),
        zIndex: (((bSelected) ? 999 : zIndexs.alerts) + 10),
      }));
    }
  }
  return alertStyle;
};


export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();

  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    intensity: attr.intensity || (false || 1),
    priority: attr.priority || false,
    stateNum: attr.stateNum,
    alertTypeNum: attr.alertTypeNum,
    leakBoss: attr.leakBoss,
    closureDate: attr.closureDate,
    comment: attr.comment,
    probability: attr.probability,
    fixDate: attr.fixDate,
    detectedAt: attr.detectedAt,
    noiseAlert: attr.noiseAlert,
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context, props) => {

  const tooltipClasses = classNames(
    'tooltip',
    'priority-' + itemInfo.priority
  );

  const alertState = props.alertStateOptions.find((aState) => aState.value === itemInfo.stateNum);
  const alertType = props.alertTypeOptions.find((aType) => aType.value === itemInfo.alertTypeNum);

  const alertComment = itemInfo.comment && itemInfo.comment.length > 30 ? itemInfo.comment.substring(0, 30) + '...' : itemInfo.comment;
  return (
    <span className={tooltipClasses} style={{ opacity: 0.85 }}>
      <div className="tooltip-row">{context.t('alert_id_param', { id: itemInfo.id })}</div>
      <div className="tooltip-row">{context.t('intensity_param', { intensity: itemInfo.intensity })}</div>
      {alertState &&
        <div className="tooltip-row">{context.t('status_state_param', { state: alertState.DisplayName })}</div>
      }
      <div className="tooltip-row">{context.t('alert_type_param', { alertType: alertType.DisplayName })}</div>

      {/* {alertType &&
        <div className="tooltip-row">{context.t('alert_type_param', { alertType: alertType })}</div>
      } */}

      <div className="tooltip-row">{context.t('probability_param', { probability: itemInfo.probability })}</div>

      {
        itemInfo.stateNum === 8 && // 8 == Shadow
        <div className="tooltip-row">{context.t('leak_boss_param', { boss: itemInfo.leakBoss })}</div>
      }

      <div className="tooltip-row">{context.t('detected_date_date', { date: itemInfo.detectedAt ? UIDateFormater(itemInfo.detectedAt, DateType.DATE, props.timeZone) : '--' })}</div>


      {
        itemInfo.stateNum === 5 && // 5 == Closed
        <div className="tooltip-row">{context.t('closure_date_date', { date: itemInfo.closureDate ? UIDateFormater(itemInfo.closureDate, DateType.DATE, props.timeZone) : '--' })}</div>
      }
      {
        itemInfo.stateNum === 6 && // 6 == Fixed
        <div className="tooltip-row">{context.t('fixed_date_date', { date: itemInfo.fixDate ? UIDateFormater(itemInfo.fixDate, DateType.DATE, props.timeZone) : '--' })}</div>
      }
      {
        itemInfo.noiseAlert &&
        <div className="tooltip-row">{context.t('noise_alert_id_noiseId', { noiseId: itemInfo.noiseAlert })}</div>
      }
      {alertComment &&
        <div className="tooltip-row">{context.t('comment_comment', { comment: alertComment })}</div>
      }
    </span>
  );
};

export const onPointerMove = (feature) => {
  const properties = feature?.getProperties();

  if (!properties || (
    shownShadowId && properties.id !== shownShadowId
  )) {
    if (bossFeature) {
      source.removeFeature(bossFeature);
      bossFeature = undefined;
    }

    if (lineToBoss) {
      source.removeFeature(lineToBoss);
      lineToBoss = undefined;
    }

    if (shownShadowId) {
      shownShadowId = undefined;
    }
  } else {

    if (properties.type === featuresTypes.ALERT &&
      properties.stateNum === 8 && // 8 is Shadow
      properties.boss.position
    ) {
      if (!shownShadowId) {
        bossFeature = new Feature({
          geometry: new Point(
            proj.transform(
              properties.boss.position,
              'EPSG:4326',
              'EPSG:3857'
            )
          )
        });
        bossFeature.setStyle(new Style({
          image: new Icon({
            src: require('../../../../images/map/alerts/alert-boss.png'),
            anchor: [0.5, 1],
            opacity: 0.8
          })
        }));

        const pathCoordinates = [
          properties.position,
          properties.boss.position
        ].map((lngLatArr) => proj.transform(
          lngLatArr,
          'EPSG:4326',
          'EPSG:3857'
        ));
        lineToBoss = new Feature({
          geometry: new LineString(pathCoordinates, 'XY'),
        })
        lineToBoss.setStyle(new Style({
          stroke: new Stroke({
            color: 'gold',
            width: 4,
          }),
        }))

        source.addFeatures([bossFeature, lineToBoss]);
        shownShadowId = properties.id;
      }
    }
  }
}
