import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';

const classNames = require('classnames');

require('../AlertsSettings/AlertsSettings.scss');

class InterferencesSettings extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, uiInfo, interferenceTypes ,toggleSettingsDisplay } = this.props;
    const panelClasses = classNames(
      'alerts-settings',
      { 'show': this.props.showSettings }
    );

    const panelStyle = { height: uiInfo.pageHeight, width: 0.395 * (uiInfo.pageWidth - 10) };

    return (
      <div className={panelClasses} style={panelStyle}>
        <div className="alerts-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay} />
          </div>
          <h2>{this.context.t('alerts_options')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('sort_by')}</legend>
            <div className="form-group">
              <div className="col-xs-8">
                <Field name="sortBy"
                  component={props =>
                    <Select { ...props }
                      placeholderTitle={this.context.t('select')}
                      clearable={false}
                      options={[
                        { value: 'ID', label: this.context.t('id') },
                        { value: 'Type', label: this.context.t('area_of_interest_type') },
                        { value: 'SopID', label: this.context.t('sopid') },
                        { value: 'Couples', label: this.context.t('intensity') },
                        { value: 'StartFrequency', label: this.context.t('start_frequency') },
                        { value: 'EndFrequency', label: this.context.t('end_frequency') },
                        { value: 'StartTme', label: this.context.t('end_time') },
                        { value: 'EndTime', label: this.context.t('start_time') },
                      ]}
                    />
                  }
                />
              </div>
              <div className="col-xs-4">
                <Field name="sortByDir"
                  component={props =>
                    <Select {...props}
                      placeholderTitle={this.context.t('select')}
                      clearable={false}
                      backspaceRemoves={false}
                      options={[
                        { value: 'asc', label: this.context.t('asc') },
                        { value: 'desc', label: this.context.t('desc') },
                      ]}
                    />
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>{this.context.t('Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-1 control-label">{this.context.t('type')}</label>
              <div className="col-xs-11">
                <Field name="Type"
                  component={props =>
                    <Select {...props}
                      placeholderTitle={this.context.t('select')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={interferenceTypes}
                    />
                  }
                />
              </div>
            </div>
            <div className="text-right">
              <button className="btn btn-success" type="submit" disabled={submitting} onClick={toggleSettingsDisplay} >
                {this.context.t('save_and_apply')}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

}

// function validate(formProps) {
//   const errors = {}
//   if (positiveNum(formProps.DaysDetected)) {
//     errors.DaysDetected = 'must be positive';
//   }
//   return errors;

// }

InterferencesSettings.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
InterferencesSettings = reduxForm({
  form: 'interference-settings', // a unique name for this form
  enableReinitialize: true,
})(InterferencesSettings);

export default InterferencesSettings;
