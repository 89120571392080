import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';
import {setListIsValveMonitoringChange} from "../../actions/CustomersActions";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default class CustomerDistributionListsTable extends React.Component {

    constructor(props) {
        super(props);

        this.handleSelectList = this.handleSelectList.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    handleSelectList(_event, rowIndex) {
        const selectedList = this.props.items[rowIndex];
        this.props.selectLisHandler(selectedList);
    }

    getClassName(rowIndex) {
        const { selectedList } = this.props;
        const { items } = this.props;
        let className = '';
        if (selectedList) {
            if (selectedList.ID === items[rowIndex].ID) {
                className = 'active-row';
            }
        }
        return className;
    }

    render() {
        const { items } = this.props;

        return (
            <Table
                maxHeight={500}
                width={320}
                rowsCount={items.length}
                rowHeight={30}
                headerHeight={30}
                onRowClick={this.handleSelectList}
                rowClassNameGetter={this.getClassName}
            >
                <Column
                    columnKey="ListName"
                    width={190}
                    header={<Cell>{this.context.t('list_name')}</Cell>}
                    cell={(data) => (<Cell>{items[data.rowIndex]["Name"]}</Cell>)}
                />


              {this.props.isProjectLevel &&
              <Column
                columnKey="deleteButton"
                width={100}
                header={<Cell>{this.context.t('delete_list')}</Cell>}
                cell={(data) => (
                  <Cell>
                    <IconButton
                      onClick={(event) => {
                        const list = items[data.rowIndex];
                        this.props.deleteDistibutionList(list.ID);
                      }}

                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Cell>
                )}
              /> }

            </Table>
        );
    }
}

CustomerDistributionListsTable.contextTypes = {
    t: PropTypes.func.isRequired
};
