export const UNITS = 'units';
export const ZONE = 'timezone';
export const LANGUAGE = 'ln';
export const PIPES_DISPLAY_TYPE = 'pipes';
export const COVERAGE_DISPLAY_TYPE = 'couplesCoverage';
export const LAST_PROJECT = 'lastPrj';
export const FILTERS_ALERTS = 'settingsAlerts';
export const FILTERS_NOISE_ALERTS = 'settingsNoiseAlerts';
export const FILTERS_VALVE_ALERTS = 'settingsValveAlerts';
export const FILTERS_SENSORS = 'settingsSensors';
export const FILTERS_SOPS = 'settingsSops';
export const FILTERS_COUPLES = 'settingsCouples';
export const FILTERS_MOBILE_TASKS = 'settingsMobileTasks';
export const FILTERS_MOBILE_ALERTS = 'settingsMobileAlerts';

// Correlation, Spectogram-Spectrogram, Samples
