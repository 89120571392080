import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../components/TextCell/TextCell';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
const classNames = require('classnames');

export default class iQuariusSamplesTable extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.path == 'iQuarius') {
      if (nextProps.selectedTaskId !== 0) {
        if (!nextProps.showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (nextProps.showDetails) {
        this.props.toggleDetailsVisibility(false);
      }
    }
  }

  render() {

    const { items, indexMap, selectedFeature, localSortProp, rowClickEvent, rowClassNameGetter, goToRow, user, t, showDetails, uiInfo } = this.props;
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    const sortProp = {
      sort: null,
      sortBy: null,
      sortDir: null
    };

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails }
    );

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;

    return (
      <div className={panelClasses}>
        <Table
          maxHeight={pHeight}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={rowClassNameGetter}
          scrollToRow={goToRow}
          {...this.props}
        >
          <Column
            columnKey="SampleTime"
            width={80}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="SampleTime" dateType={DateType.DATE} />} />
          <Column
            columnKey="Status"
            width={45}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Status" />} />
          <Column
            columnKey="Intensity"
            width={45}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Intensity" />} />
          <Column
            columnKey="Quality"
            width={45}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Quality" />} />
          <Column
            columnKey="DistanceOfLeakFromDevice1"
            width={45}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="DistanceOfLeakFromDevice1" />} />
          <Column
            columnKey="Comments"
            width={80}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Comments" />} />
        </Table>

        {items && items.length > 0 && false &&
          <span style={totalItemsStyle}>{t('selected_selectedindex_total_total', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
        }

      </div>
    );
  }
}
