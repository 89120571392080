import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import CUserDetailsTab from '../../containers/CUsersTab/CUserDetailsTab';
const classNames = require('classnames');

export default class UsersTabs extends React.Component {

  constructor(props) {
    super();
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.clearSelectedFeature();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.manageItem !== null && this.props.manageItem === null) ||
      nextProps.manageItem !== null && this.props.manageItem.id !== nextProps.manageItem.id) {
      this.props.toggleDetailsVisibility(true);
    }
  }

  render() {
    const { showDetails, manageItem, uiInfo, user } = this.props;
    if (manageItem == null || manageItem.item == null) {
      return null;
    }

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
    );
    const panelStyle = { height: 0.493 * uiInfo.pageHeight };
    const title = (manageItem.id !== -1) ? this.context.t('user_name_name', { name: manageItem.item.UserName }) : this.context.t('create_new_user');

    return (
      <div className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={title}
          handleClose={this.closePanel}
          tabs={[
            {
              label: this.context.t('details'),
              component: <CUserDetailsTab notify={this.props.notify} />
            }
          ]}
        />
      </div>
    );
  }
}

UsersTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
