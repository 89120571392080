/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

// import { ENV, ENVIRONMENTS } from '../../../constants/EndPoints';

import { Field, reduxForm } from 'redux-form';
import AppModal from '../../../Modals/AppModal';

import ImagesTab from '../../../AlertsTabs/ImagesTab/ImagesTab';
import Input from '../../../Input/Input';
import AddressField from '../../../Custom/AddressField';
import WorkOrderModal from '../../../WorkOrderModal/WorkOrderModal';
import CommentField from '../../../CommentField/CommentField';
import UIDateFormater from '../../../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../../../containers/UIhelper/UIDateFormater';
require('./MobileDetailsTab.scss');

const X_Y_DIGITS = 4;

class MobileDetailsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textDir: "ltr",
      comment: '',
      editComment: false,
      galleryOpen: false,
    };

    this.openGallery = this.openGallery.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.changeTextDir = this.changeTextDir.bind(this);
    this.onOpenPCAclick = this.onOpenPCAclick.bind(this);
    this.openActualLeakLocation = this.openActualLeakLocation.bind(this);
  }

  changeTextDir(dir) {
    this.setState({
      textDir: dir,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedFeature != this.props.selectedFeature) {
      this.setState({
        editComment: false,
      });
    }
    if (nextProps.initialValues != null && this.state.editComment === false) {
      this.setState({
        comment: nextProps.initialValues.Comment,
      });

    }
  }
  onCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  onOpenPCAclick() {
    // if (this.props.isFixedAlert) {
    this.props.openPCA(this.props.selectedFeature);
    // }
  }

  openActualLeakLocation() {
    this.props.openActualLeakLocation();
  }

  openGallery() {
    const { images } = this.props;
    if (images != null && images.length > 0)
      this.setState({
        galleryOpen: true
      });
  }

  closeGallery() {
    this.setState({
      galleryOpen: false
    });
  }

  render() {
    const {
      selectedFeature,
      error,
      handleSubmit,
      pristine,
      submitting,
      user,
      alertDetails,
      images,
      distributionListsOptions,
    } = this.props;


    const fieldsClass = 'col-xs-8 control-input';
    const initialValues = this.props.initialValues ? this.props.initialValues : {}; // Prevent 'undefined' errors.

    return (
      <div className="mobile-details-tab form-horizontal sticky-actions">
        <form onSubmit={handleSubmit}>
          <CommentField
            fieldName="Comment"
            featureID={selectedFeature}
            placeholder={this.context.t('alert_comment')}
            onEdit={this.props.onEdit}
            onCopy={this.props.onCopy}
            disabled={!user.editMode}
          />
          <AddressField
            className="form-group"
            user={user}
            fieldName="address"
            fieldClassName={fieldsClass}
            onGetAddress={this.props.getAddressByLatLong}
          />

          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('event_date')}</label>
            {/*@TODO handle date format of default value */}
            <div className="col-xs-8 control-input">
              <Field name="create_date" component={Input} type="date" className="form-control"
                disabled={!user.editMode} />
            </div>
          </div>

          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('end_date')}</label>
            <div className="col-xs-8 control-input">
              <Field name="end_date" component={Input} type="date" className="form-control"
                disabled={!user.editMode} />
              {/* <span className="control-readonly">{UIDateFormater(initialValues.ClosureDate, DateType.DATE, timeZone) || '-'}</span> */}
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('task_id')}</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.task_uuid || ''}</span>
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('intensity')}</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Intensity || '1'}</span>
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('longitude_latitude')}</label>
            <div className="col-xs-8 control-input">
              <span
                className="control-readonly">{initialValues.longitude || '-'} , {initialValues.latitude || '-'}</span>
            </div>
          </div>


          <div className="form-actions">

            <WorkOrderModal
              alertId={alertDetails ? alertDetails.alert_id : ''}
              sendNotification={this.props.sendNotification}
              distributionLists={distributionListsOptions}
              getMapCapture={this.props.getMapCapture}
              contextType="mobile" // Pass contextType here
            />

            {/* hide till we develop functionality */}
            {/* <a
              className="btn btn-warning btn-order"
              onClick={this.openActualLeakLocation}>
              {this.context.t('set_actual_leak_position')}
            </a> */}

            <button
              className="btn btn-success btn-submit"
              disabled={pristine || submitting}
              type="submit">{this.context.t('save')}</button>
            <div className="form-feedback text-danger">
              {error}
            </div>
          </div>
        </form>

        {JSON.parse(process.env.REACT_APP_FILES) &&
          <AppModal
            closeIcon
            open={this.state.galleryOpen}
            onClose={this.closeGallery}
            content={
              <ImagesTab
                images={images}
              />
            }
          />
        }
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.KavMaim > 2147483647) {
    errors.KavMaim = "Pipe ID cannot be longer than 9 digits";
  }
  return errors;
};

const mapStateToProps = (state) => ({
  selectedMobileAlert: state.mobile.alerts.selectedAlert,
  // ... other state mappings ...
});

MobileDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
export default reduxForm({
  form: 'mobile-details', // a unique name for this form
  enableReinitialize: true,
  validate
})(MobileDetailsTab);
