import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { Field, reduxForm } from 'redux-form';
import AppModal from '../Modals/AppModal';

import { UninstallMode } from '../../constants/Misc';
import CommentField from '../CommentField/CommentField';
import Select from '../Select/Select';

import { copyComment, editComment } from '../../actions/GlobalsActions';
import * as actionsInstall from '../../actions/InstallActions';

class UninstallSensorModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: '',
      message: '',
      buttonTitle: '',
    }

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.destroy('uninstallDeviceForm');
    this.props.onClose();
  }

  componentWillReceiveProps(nextProps) {
    const { uninstallMode, uninstallDetails, sensorDetails } = nextProps;
    const isOpen = (uninstallMode != UninstallMode.NONE);

    let title;
    let message;
    let buttonTitle;
    switch (uninstallMode) {
      default:
      case UninstallMode.NONE:
        title = '';
        message = '';
        buttonTitle = '';
        break;
      case UninstallMode.NORMAL:
        title = this.context.t('uninstall_device');
        message = this.context.t('are_you_sure_you_want_to_remove_this_device');
        buttonTitle = this.context.t('uninstall');
        break;
      case UninstallMode.UNINSTALL_AND_REMOVE_SENSOR:
        title = this.context.t('unistall_device_and_remove_sensor');
        message = this.context.t('are_you_sure_you_want_to_uninstall_the_device_and_remove_this_sensor');
        buttonTitle = this.context.t('Uninstall');
        break;
      case UninstallMode.UNINSTALL_AND_REINSTALL_ON_NEW_LOCATION:
        title = this.context.t('device_already_installed_in_project_project', { project: uninstallDetails.project });
        message = this.context.t('uninstall_device_and_install_it_on_the_new_sensor');
        buttonTitle = this.context.t('uninstall');
        break;
      case UninstallMode.JUST_REMOVE_SENSOR:
        title = this.context.t('remove_sensor_sensorid', { sensorID: sensorDetails.ID });
        message = this.context.t('are_you_sure_you_want_to_remove_this_sensor');
        buttonTitle = this.context.t('remove');
        break;
    }
    if (this.state.open != isOpen) {
      this.setState({
        open: isOpen,
        title,
        message,
        buttonTitle
      });
    }
  }

  render() {
    const { open, title, message, buttonTitle } = this.state;
    const { selectedActionCode, isWorking, uninstallMode } = this.props;
    let bIsButtonDisable = false;
    if (uninstallMode != UninstallMode.JUST_REMOVE_SENSOR) {
      bIsButtonDisable = selectedActionCode == null;
    }
    const buttonAction = (uninstallMode == UninstallMode.JUST_REMOVE_SENSOR) ? this.props.removeSensor : this.props.uninstallDevice;

    return (
      <AppModal
        open={open}
        onClose={this.onClose}
        title={title}
        content={(
          <form className="form-horizontal sticky-actions" onSubmit={this.props.handleSubmit}>
            <div>
              <p>{message}</p>
            </div>
            {uninstallMode != UninstallMode.JUST_REMOVE_SENSOR &&
              <CommentField
                fieldName="Comment"
                featureID={-1}
                placeholder={this.context.t('uninstall_comment')}
                onEdit={this.props.onEdit}
                onCopy={this.props.onCopy}
                disabled={false}
              />
            }
            {uninstallMode != UninstallMode.JUST_REMOVE_SENSOR &&
              <div className="form-group">
                <label className="col-xs-2 control-label">{this.context.t('action')}:</label>
                <div className="col-xs-10 control-input">
                  <Field name="ActionCode"
                    component={(props) =>
                    (<Select {...props}
                      key={"ActionCode"}
                      placeholderTitle={this.context.t('select')}
                      options={this.props.uninstallActions}
                    />)
                    }
                  />
                </div>
              </div>
            }
          </form>
        )}
        actions={(
          <div>
            <div style={{
              position: 'absolute',
              top: '18%',
              left: 0,
              width: '100%'
            }}>
              <BarLoader
                color="#4cae4c"
                width="110%"
                loading={isWorking}
              />
            </div>
            <button
              className='btn btn-success'
              onClick={buttonAction}
              disabled={bIsButtonDisable}>
              {buttonTitle}
            </button>
            <button
              className='btn btn-primary'
              onClick={this.onClose}>
              {this.context.t('cancel')}
            </button>
          </div>
        )}
      />
      // <Modal
      //   show={open}
      //   onHide={this.onClose}
      //   aria-labelledby="ModalHeader"
      // >
      //   <Modal.Header closeButton>
      //     <Modal.Title id='ModalHeader'>{title}</Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>
      //     <form className="form-horizontal sticky-actions" onSubmit={this.props.handleSubmit}>
      //       <div>
      //         <p>{message}</p>
      //       </div>
      //       {uninstallMode != UninstallMode.JUST_REMOVE_SENSOR &&
      //         <CommentField
      //           fieldName="Comment"
      //           featureID={-1}
      //           placeholder={this.context.t('Uninstall comment')}
      //           onEdit={this.props.onEdit}
      //           onCopy={this.props.onCopy}
      //           disabled={false}
      //         />
      //       }
      //       {uninstallMode != UninstallMode.JUST_REMOVE_SENSOR &&
      //         <div className="form-group">
      //           <label className="col-xs-2 control-label">{this.context.t('action')}:</label>
      //           <div className="col-xs-10 control-input">
      //             <Field name="ActionCode"
      //               component={props =>
      //                 <Select {...props}
      //                   key={"ActionCode"}
      //                   placeholderTitle={this.context.t('select')}
      //                   options={this.props.uninstallActions}
      //                 />
      //               }
      //             />
      //           </div>
      //         </div>
      //       }
      //     </form>
      //   </Modal.Body>
      //   <Modal.Footer>
      //     <div style={{
      //       position: 'absolute',
      //       top: '18%',
      //       left: 0,
      //       width: '100%'
      //     }}>
      //       <BarLoader
      //         color="#4cae4c"
      //         width="110%"
      //         loading={isWorking}
      //       />
      //     </div>
      //     <button
      //       className='btn btn-success'
      //       onClick={buttonAction}
      //       disabled={bIsButtonDisable}>
      //       {buttonTitle}
      //     </button>
      //     <button
      //       className='btn btn-primary'
      //       onClick={this.onClose}>
      //       {this.context.t('Cancel')}
      //     </button>
      //   </Modal.Footer>
      // </Modal>
    );
  }
}

UninstallSensorModal.contextTypes = {
  t: PropTypes.func.isRequired
}

UninstallSensorModal = reduxForm({
  form: 'uninstallDeviceForm', // a unique name for this form
  enableReinitialize: true,
})(UninstallSensorModal);


const mapStateToProps = (state, ownProps) => {
  const installState = state.install;
  const sensorDetails = installState.sensorDetails;
  const uninstallMode = installState.uninstallMode;
  const uninstallDetails = installState.uninstallBusyDeviceDetails;
  const uninstallActions = state.leaksList.optionList.options.DeviceMaintenance;
  const selectedActionCode = (
    state.form.uninstallDeviceForm != null
    && state.form.uninstallDeviceForm.values != null
    && state.form.uninstallDeviceForm.values.ActionCode) ? state.form.uninstallDeviceForm.values.ActionCode : null;
  const isWorking = state.install.isWorking;

  return Object.assign({}, {
    sensorDetails,
    uninstallActions,
    uninstallMode,
    uninstallDetails,
    selectedActionCode,
    isWorking
  });
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      // console.log('on submit');
    },
    onEdit: (proxi, object, event) => {
      dispatch(editComment('uninstallDeviceForm', 'Comment'));
      return true;
    },
    onCopy: (proxi, object, event) => {
      dispatch(copyComment('uninstallDeviceForm', 'Comment'));
      return true;
    },
    uninstallDevice: () => {
      dispatch(actionsInstall.uninstallDevice((data) => {
        if (data != null) {
          ownProps.notify(data.message, data.level);
        }
      }));
    },
    removeSensor: () => {
      dispatch(actionsInstall.removeSensor((data) => {
        if (data != null) {
          ownProps.notify(data.message, data.level);
        }
      }));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UninstallSensorModal);
