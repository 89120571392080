import React from 'react';
import PropTypes from 'prop-types';

import PrsDetails from '../../components/AlertsPressureTabs/PrsDetails';
import PrsSamplesTable from '../../components/AlertsPressureTabs/PrsSamplesTable';
import TabsContainer from '../Custom/Tabs/TabsContainer';


const classNames = require('classnames');

export default class AlertsPressureTabs extends React.Component {

  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.sortSamples = this.sortSamples.bind(this);
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.selectSample({});
    this.props.clearSelectedFeature();
  }


  sortTable(field, dir) {
    this.props.sortSamples(field, dir);
  }

  sortSamples() {
    const { filters } = this.props;
    if(filters.samplesSort) {
      return {
        sort: this.props.sortSamples,
        sortBy: filters.samplesSort.field,
        sortDir: filters.samplesSort.dir
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.featureType == 'prsAlert') {
      if (nextProps.selectedFeature !== "") {
        if (!nextProps.showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (nextProps.showDetails) {
        this.props.toggleDetailsVisibility(false);
      }
    }
  }

  onTransientSampleSelect = (sample) => {
    this.props.selectTransientAlertSamples(sample);
  }

  onSelectTab = (toIndex, fromIndex) => {
    // console.log(`from: ${fromIndex} to: ${toIndex}`);
    if (fromIndex === 0) {
      this.props.selectTransientAlertSamples({});
    }
  }

  render() {
    const {
      selectedFeature,
      selectedFeatureDetails,
      sensorItem,
      showDetails,
      timeZone,
      uiInfo,
      user
    } = this.props;

    if (!selectedFeature) {
      return null;
    }

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
    );
    const panelStyle = { height: 0.493 * uiInfo.pageHeight };

    // const plotData = [{
    //     // name: UIDateFormater(selectedFeatureDetails.transient.x, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone),
    //     y: selectedFeatureDetails.transient.y,
    //     mode: 'lines',
    //     type: 'scatter'
    //   }]

    return (
        <div className={panelClasses} style={panelStyle}>
            <TabsContainer
              title={this.context.t('alert_alertId', { alertId: selectedFeature })}
              handleClose={this.closePanel}
              tabs={[
                {
                  label: this.context.t('samples'),
                  component: (
                    <PrsSamplesTable
                      pHeight={uiInfo.pageHeight}
                      pWidth={uiInfo.pageWidth}
                      items={this.props.alertSamples}
                      exportSamples={this.props.exportSamples}
                      selectedFeatureDetails={selectedFeatureDetails}
                      timeZone={timeZone}
                      selectedSample={this.props.selectedSample}
                      selectSampleFunction={this.onTransientSampleSelect}
                    />
                  )
                },
                // {
                //   label: this.context.t('Graph'),
                //   component: (
                //     <CSensorPressure
                //       transientTabByDefault
                //       sensorItem={selectedFeatureDetails}
                //       selectedFeature={selectedFeature}
                //       uiInfo={uiInfo}
                //       timeZone={timeZone}
                //     />
                //   )
                // },
                {
                  label: this.context.t('details'),
                  component: (
                    <PrsDetails
                      initialValues={selectedFeatureDetails}
                      user={user}
                    />
                  )
                },
              ]}
            />
        </div>
    );
  }
}

AlertsPressureTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
