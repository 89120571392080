import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import TextCell from '../../components/TextCell/TextCell';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

require('../TableActions/TableActions.scss');

const PrsSamplesTable = (props, context) => {

  const { items, pHeight, pWidth, timeZone } = props;

  let width = 0.395 * (pWidth - 10);
  let height = 0.494 * pHeight;

  const rowClickEvent = (_event, rowIndex) => {
    const selectedItem = items[rowIndex];
    props.selectSampleFunction(selectedItem);
  }

  const rowClassNameGetter = (rowIndex) => {
    // selectedSample
    const { items, selectedSample } = props;

    if (items[rowIndex] && items[rowIndex].Time === selectedSample.Time) {
      return 'active-row';
    }
  }

  return (
    <div>
      <Table
        maxHeight={height - 60}
        width={width}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
      // scrollToRow={goToRow}
      // {...this.props}
      >
        <Column columnKey="Time" width={40} flexGrow={1}
          header={<Cell>{context.t('time')}</Cell>}
          cell={<TextCell data={items} field="Time" dateType={DateType.DATE_AND_TIME_WITH_SECONDS} additional={timeZone} />} />

        <Column columnKey="MinValue" width={40} flexGrow={1}
          header={<Cell>{context.t('min_value')}</Cell>}
          cell={<TextCell data={items} field="MinValue" />} />

        <Column columnKey="MaxValue" width={40} flexGrow={1}
          header={<Cell>{context.t('max_value')}</Cell>}
          cell={<TextCell data={items} field="MaxValue" />} />

        <Column columnKey="MedValue" width={40} flexGrow={1}
          header={<Cell>{context.t('median_value')}</Cell>}
          cell={<TextCell data={items} field="MedValue" />} />
      </Table>

      <div
        style={{ position: 'absolute', bottom: '2px', left: '2px' }}
        className='table-actions'>
        <button
          onClick={() => props.exportSamples(props.selectedFeatureDetails)}
          className='btn btn-link table-actions-export-alerts-history'
          title={context.t('export')} />

      </div>

    </div>
  );
};

PrsSamplesTable.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PrsSamplesTable;
