/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

const useStyle = makeStyles({
  root: {
    height: '73.7vh'
  },
  header: {
    position: 'fixed',
    insetBlockStart: 0,
    top: 0,
    zIndex: 1,
  },
  footer: {
    position: 'sticky',
    insetBlockEnd: 0,
    bottom: 0,
    backgroundColor: '#fafafa',

    '& td': {
      border: '1px solid #eee',
    }
  },
});

export default function DevicesTableMultySelect(props, context) {
  const { items, workingMode, handleSelection, selectedItems } = props;

  const classes = useStyle();
  const isSelected = (row) => selectedItems.indexOf(row.DeviceID) !== -1;

  const handleSelectAllClick = (event) => {
    let newSelecteds;
    if (event.target.checked) {
      newSelecteds = items.map((n) => n.DeviceID);
    } else {
      newSelecteds = [];
    }
    //setSelected(newSelecteds);

    handleSelection(newSelecteds, workingMode);
  };

  // eslint-disable-next-line no-unused-vars
  const handleRowSelect = (_event, row) => {
    let newSelected = [];
    const deviceId = row.DeviceID;

    if (workingMode === 'status') {
      newSelected = [deviceId];
    } else {
      const selectedIndex = selectedItems.indexOf(deviceId);

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedItems, deviceId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedItems.slice(1));
      } else if (selectedIndex === selectedItems.length - 1) {
        newSelected = newSelected.concat(selectedItems.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedItems.slice(0, selectedIndex),
          selectedItems.slice(selectedIndex + 1),
        );
      }
    }

    handleSelection(newSelected, workingMode);
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell align="center">
          <Checkbox
            color="primary"
            indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
            checked={items.length > 0 && selectedItems.length === items.length}
            onChange={handleSelectAllClick}
          />
        </TableCell>
        <TableCell align="center">{context.t('device_id')}</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderBodyRow = (item) => (
    <TableRow
      hover
      key={item.DeviceID}
      role="checkbox"
      onClick={(event) => handleRowSelect(event, item)}
      selected={isSelected(item)}
    >
      <TableCell align='center' padding="checkbox">
        <Checkbox color="primary" checked={isSelected(item)} />
      </TableCell>
      <TableCell align='center'>{item.DeviceID}</TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper} classes={{ root: classes.root }}>
      <Table fixedHeader stickyHeader aria-label={context.t('g5_devices_table')}>
        {
          renderTableHead()
        }
        <TableBody>
          {
            items.map(renderBodyRow)
          }
        </TableBody>
        <TableFooter classes={{root: classes.footer}}>
          <TableRow>
            <TableCell colSpan={2} align='right'>
              { selectedItems.length === 0 ?
                context.t('count_devices', { count: items.length })
                :
                context.t('selected_devices', { count: items.length, selected: selectedItems.length })
              }
            </TableCell>
          </TableRow>
        </TableFooter>

      </Table>
    </TableContainer>
  );
}

DevicesTableMultySelect.contextTypes = {
  t: PropTypes.func.isRequired
};

DevicesTableMultySelect.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  workingMode: PropTypes.string.isRequired,
};
