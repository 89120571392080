import React from 'react';
import PropTypes from 'prop-types';

require('./SensorBitStyles.scss');

export default class SensorBitInfo extends React.Component {

  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    const { selectSensorBit } = this.props;
    selectSensorBit({});
  }

  render() {
    const { selectedSensorBit, timeZone, downloadFile } = this.props;
    const intensityIndicatorSrc = selectedSensorBit.data.IsIntensitySampleOk ? require('../../../../images/ic_v.png') : require('../../../../images/ic_x.png');
    const noiseSampleIndicatorSrc = selectedSensorBit.data.IsNoiseSampleOk ? require('../../../../images/ic_v.png') : require('../../../../images/ic_x.png');
    const noiseSyncIndicatorSrc = selectedSensorBit.data.IsNoiseSyncOk ? require('../../../../images/ic_v.png') : require('../../../../images/ic_x.png');
    const sampleSizeIndicatorSrc = selectedSensorBit.data.IsSampleSizeOk ? require('../../../../images/ic_v.png') : require('../../../../images/ic_x.png');
    const iconSize = 35;
    return (
      <div className='sample-info'>
        <i className="icon-close" onClick={this.closePanel} />
        <h2 style={{ marginLeft: '20px' }}>{this.context.t('bit_information')}</h2>
        <div className="sample-graphs" style={{float: 'left', marginLeft: '50px'}}>
          <div>
            <table style={{ width: '90%' }}>
              <tbody>
                <tr>
                  <td><img className="indicator-icon" src={intensityIndicatorSrc} height={iconSize} width={iconSize} style={{ marginRight: '15px' }}/></td>
                  <td className="boldHeader"><span>{this.context.t('sample_intensity')}</span></td>
                  <td className="control-readonly"><span>{selectedSensorBit.data.IntensitySample.toFixed(2)}</span></td>
                </tr>
                <tr>
                  <td><img className="indicator-icon" src={sampleSizeIndicatorSrc} height={iconSize} width={iconSize} style={{ marginRight: '15px' }} /></td>
                  <td className="boldHeader"><span>{this.context.t('sample_size')}</span></td>
                  <td className="control-readonly"><span>{selectedSensorBit.data.SampleSize.toFixed(2)}</span></td>
                </tr>
                <tr>
                  <td><img className="indicator-icon" src={noiseSampleIndicatorSrc} height={iconSize} width={iconSize} style={{ marginRight: '15px' }} /></td>
                  <td className="boldHeader"><span>{this.context.t('noise_sample')}</span></td>
                  <td className="control-readonly"><span>{selectedSensorBit.data.NoiseSample.toFixed(2)}</span></td>
                </tr>
                <tr>
                  <td><img className="indicator-icon" src={noiseSyncIndicatorSrc} height={iconSize} width={iconSize} style={{ marginRight: '15px' }} /></td>
                  <td className="boldHeader"><span>{this.context.t('noise_sync')}</span></td>
                  <td className="control-readonly"><span>{selectedSensorBit.data.NoiseSync.toFixed(2)}</span></td>
                </tr>
              </tbody>
            </table>
          </div>

          {false &&
            <div>
              <span style={{ marginLeft: '20px' }} ><span className="boldHeader">{this.context.t('file_name')}</span>: {selectedSensorBit.data.FileName}<br /></span>
              <button className='btn btn-info downloadLogBtn' onClick={() => { downloadFile(selectedSensorBit.data.FileName, 'file') }} title={this.context.t('Download log file')}>
                {this.context.t('download')}
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

SensorBitInfo.contextTypes = {
  t: PropTypes.func.isRequired
}
