import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import AnalysisTab from '../../components/Inspect/Tabs/AnalysisTab';
import PivotTableTab from '../../components/Inspect/Tabs/PivotTableTab';
import Dashboard from '../../components/Inspect/Tabs/Dashboard';


export default class PipeAssessmentsAnalysisTabs extends React.Component {

   static get propTypes() {
      return {
         layoutVisibility: PropTypes.string,
         rawLeaksData: PropTypes.array,
         pipesList: PropTypes.array,
         isFetchingPipes: PropTypes.bool,
         valuesSets: PropTypes.object,
         pipesValuesSets: PropTypes.object,
         plotData: PropTypes.array,
         heatMapData: PropTypes.array,
         onSelectGroup: PropTypes.func,
         selectAlertsByFilters: PropTypes.func,
         materialsByPlacementYear: PropTypes.object,
         diameterByPlacementYear: PropTypes.object,
         materialAndDiameterByPlacementYear: PropTypes.object,
         pcaScoreData: PropTypes.object,
      };
   }

   render() {
      const { rawLeaksData, valuesSets, pipesList, pipesValuesSets, plotData, heatMapData, onSelectGroup, onSelectScoreGroup, selectAlertsByFilters, layoutVisibility, scoreSelectedGroupData, scoreSelectedGroupCategory,
        materialsByPlacementYear, diameterByPlacementYear, materialAndDiameterByPlacementYear, pcaScoreData, isFetchingPipes, units, factor
       } = this.props;
      return (
        <TabsContainer
          whiteTabs
          justifyCenter
          tabs={[
            {
              label: this.context.t('dashboard'),
              style: { height: '695px' },
              component: (
                <Dashboard
                  rawLeaksData={rawLeaksData}
                  valuesSets={valuesSets}
                  pipesList={pipesList}
                  isFetchingPipes={isFetchingPipes}
                  pipesValuesSets={pipesValuesSets}
                  pcaScoreData={pcaScoreData}
                  layoutVisibility={layoutVisibility}
                  onSelectGroup={onSelectGroup}
                  onSelectScoreGroup={onSelectScoreGroup}
                  measurmentsDetails={{units, factor}}
                  scoreSelectedGroupData={scoreSelectedGroupData}
                  scoreSelectedGroupCategory={scoreSelectedGroupCategory}
               />
              )
            },
            {
              label: this.context.t('material_or_placement_year'),
              style: { height: '695px' },
              component: (
                <AnalysisTab
                  analysisData={materialsByPlacementYear}
                  xAttribute={'PlacementYear'}
                  yAttribute={'Material'}
                  rawLeaksData={rawLeaksData}
                  valuesSets={valuesSets}
                  pipesList={pipesList}
                  pipesValuesSets={pipesValuesSets}
                  onSelectGroup={onSelectGroup}
                  layoutVisibility={layoutVisibility}
               />
              )
            },
            {
              label: this.context.t('diameter_or_placement_year'),
              style: { height: '695px' },
              component: (
                <AnalysisTab
                  analysisData={diameterByPlacementYear}
                  xAttribute={'PlacementYear'}
                  yAttribute={'PipeDiameterInch'}
                  rawLeaksData={rawLeaksData}
                  valuesSets={valuesSets}
                  pipesList={pipesList}
                  pipesValuesSets={pipesValuesSets}
                  onSelectGroup={onSelectGroup}
                  layoutVisibility={layoutVisibility}
                />
              )
            },
            {
              label: this.context.t('material_and_diameter_or_placement_year'),
              style: { height: '695px' },
              component: (
                <AnalysisTab
                  analysisData={materialAndDiameterByPlacementYear}
                  xAttribute={'PlacementYear'}
                  yAttribute={'MaterialDiameter'}
                  rawLeaksData={rawLeaksData}
                  valuesSets={valuesSets}
                  pipesList={pipesList}
                  pipesValuesSets={pipesValuesSets}
                  onSelectGroup={onSelectGroup}
                  layoutVisibility={layoutVisibility}
                />
              )
            },
            {
              label: this.context.t('advance_alerts'),
              style: { height: '695px' },
              component: (
                <PivotTableTab
                  sortedAlertsDataSet={rawLeaksData}
                  selectAlertsByFilters={selectAlertsByFilters}
               />
              )
            },
            {
              label: this.context.t('advance_pipes'),
              style: { height: '695px' },
              component: (
                <PivotTableTab
                  sortedAlertsDataSet={pipesList}
               />
              )
            },
          ]}
        />
      );
   }
}

PipeAssessmentsAnalysisTabs.contextTypes = {
   t: PropTypes.func.isRequired
};
