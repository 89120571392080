import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');


const SOP_ICON_SCALE = "0.35";

const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/waterMeter/ic-meter-alert.png'),
    anchor: [0.5, 0.5],
    size: [64, 64],
    scale: SOP_ICON_SCALE,
  })
});

const source = new VectorSource({
  features: []
});

export const layer = new VectorLayer({
  name: 'layerWaterMeter',
  style: style,
  source: source,
  zIndex: zIndexs.waterMeter,
});

export const setSource = (nextIconFeatures, props) => {
  let iconFeatures = [];
  Object.keys(nextIconFeatures).forEach((key) => {
    const meterAlert = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          meterAlert.position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.WATER_METER,
      id: meterAlert.id,
      status: meterAlert.status,
      customer: meterAlert.customer,
      consumption: meterAlert.consumption,
      sampleTime: meterAlert.sampleTime,
      consumptionDate: meterAlert.consumptionDate,
    });
    iconFeature.setStyle(style);
    iconFeatures.push(iconFeature);
  });
  layer.getSource().addFeatures(iconFeatures);
};

export const getStyle = () => {
  return (style);
}

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    sampleTime: attr.sampleTime,
    consumptionDate: attr.consumptionDate,
    customer: attr.customer,
    consumption: attr.consumption,
    type: attr.type,
    id: attr.id,
    status: attr.status,
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
return (
  <div className="tooltip">
    <table>
      <tr className="tooltip-row">
        <td>{context.t('sample_time')}</td>
        <td>{UIDateFormater(itemInfo.sampleTime, DateType.DATE_AND_TIME, context.t.timeZone)}</td>
      </tr>
      <tr className="tooltip-row">
        <td>{context.t('consumption_time')}</td>
        <td>{UIDateFormater(itemInfo.consumptionDate, DateType.DATE_AND_TIME, context.t.timeZone)}</td>
      </tr>
      <tr className="tooltip-row">
        <td>{context.t('customer_id')}</td>
        <td>{itemInfo.customer}</td>
      </tr>
      <tr className="tooltip-row">
        <td>{context.t('consumption')}</td>
        <td>{itemInfo.consumption.toFixed(2)}</td>
      </tr>
    </table>
  {/* <span className="tooltip">
    <div className="tooltip-row">{this.context.t('Sample Time')} {UIDateFormater(itemInfo.sampleTime, DateType.DATE_AND_TIME, this.props.timeZone)}</div>
    <div className="tooltip-row">{this.context.t('Consumption Time')} {UIDateFormater(itemInfo.consumptionDate, DateType.DATE_AND_TIME, this.props.timeZone)}</div>
    <div className="tooltip-row">{this.context.t('Customer ID')} {itemInfo.customer}</div>
    <div className="tooltip-row">{this.context.t('Consumtion')} {itemInfo.consumption}</div>
  </span>  */}
  </div>
  );
};
