export function parsePolygonFromDB(rawPolygon) {
  let polygon = null;

  if (rawPolygon != null) {
    // #1: cut the prefix by find the first '(' in the string:
    let firsCharIndex = rawPolygon.indexOf('(');
    let rawPoints = rawPolygon.substring(firsCharIndex);

    const loopFunction = (data) => {
      let polygonDataArray = [];

      if (data.charAt(0) == '(') {
        // we have an array.
        // cut the parenthesis:
        let innerData = data.substring(1, data.length - 1);
        let splitedData = innerData.split('), (');
        splitedData.forEach((arrayItem) => {
          let polygonData = loopFunction(arrayItem);
          polygonDataArray.push(polygonData);
        });
      } else {
        let rawPointsArray = data.split(', ');
        let pointsArray = [];
        rawPointsArray.forEach((point) => {
          let pointItem = point.split(' ');
          pointsArray.push([
            parseFloat(pointItem[0]),
            parseFloat(pointItem[1]),
          ]);
        });
        polygonDataArray.push(pointsArray);
      }

      return polygonDataArray;
    };

    polygon = loopFunction(rawPoints);

    // const startIndex = rawPolygon.indexOf('((') + '(('.length;
    // const endIndex = rawPolygon.indexOf('))');
    // // cut the coordinate part:
    // let rowData = rawPolygon.substring(startIndex, endIndex);

    // let rowArray = rowData.split(',');

    // polygon = [];

    // rowArray.forEach((item) => {
    //   const point = item.trim().split(' ');
    //   polygon.push([
    //     parseFloat(point[0]),
    //     parseFloat(point[1])
    //   ]);
    // });
  }

  return polygon;
}
