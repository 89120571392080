import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import Select from '../Select/Select';

const classNames = require('classnames');

require('./MobileAlertsSettings.scss');

const MobileAlertsSettings = (props, context) => {

  const {
    handleSubmit,
    pristine,
    submitting,
    uiInfo,
    toggleSettingsDisplay,
    // sampleStateOptions,
    optionList,
    alertsList,
  } = props;
  const panelClasses = classNames('sensors-settings', 'alerts-settings', {
    show: props.showSettings,
  });
  console.log('optionList', optionList);
  const panelStyle = {
    height: uiInfo.pageHeight,
    width: 0.395 * (uiInfo.pageWidth - 10),
  };

  return (
    <div className={panelClasses} style={panelStyle}>
      <div className="sensors-header alerts-header">
        <div className="actions">
          <i className="icon-close" onClick={toggleSettingsDisplay} />
        </div>
        <h2>{context.t('alerts_options')}</h2>
      </div>
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <fieldset>
          <legend>{context.t('sort_by')}</legend>
          <div className="form-group">
            <div className="col-xs-8">
              <Field
                name="Alerts.sortBy"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={false}
                    options={[
                      {
                        value: 'CreationDate',
                        label: context.t('creation_date_default'),
                      },
                      {
                        value: 'TaskName',
                        label: context.t('task_name'),
                      },
                      {
                        value: 'ID',
                        label: context.t('id'),
                      },
                      {
                        value: 'AlertType',
                        label: context.t('alert_type'),
                      },
                      {
                        value: 'Intensity',
                        label: context.t('intensity'),
                      },
                      {
                        value: 'AlertState',
                        label: context.t('alert_state'),
                      },
                      {
                        value: 'Address',
                        label: context.t('address'),
                      },
                      {
                        value: 'Comment',
                        label: context.t('comment'),
                      },
                    ]}
                  />
                )}
              />
            </div>
            <div className="col-xs-4">
              <Field
                name="Alerts.sortByDir"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'asc', label: context.t('asc') },
                      { value: 'desc', label: context.t('desc') },
                    ]}
                  />
                )}
              />
            </div>
          </div>
          <legend>{context.t('alerts_filters')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('creation_date')}
            </label>
            <div className="col-xs-3 control-date-form">
              <Field
                name="Alerts.CreationDate-From"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-xs-1 control-label"> {context.t('to')}</div>
            <div className="col-xs-3 control-date-to">
              <Field
                name="Alerts.CreationDate-To"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('task_name')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Alerts.TaskName"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    multi
                    clearable
                    backspaceRemoves
                    options={alertsList?.map((alert) => ({
                      label: alert.task_name,
                      value: alert.task_name,
                    }))}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('type')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Alerts.AlertType"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={true}
                    multi={true}
                    closeMenuOnSelect={false}
                    options={optionList.AlertType}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('status')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Alerts.AlertState"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={true}
                    multi={true}
                    closeMenuOnSelect={false}
                    options={optionList.AlertStateNoise}
                  />
                )}
              />
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-success"
              type="submit"
              disabled={pristine || submitting}
              onClick={toggleSettingsDisplay}
            >
              {context.t('save_and_apply')}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  )
    ;
}

MobileAlertsSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Decorate the form component
const mobileAlertsSettings = reduxForm({
  form: 'mobile-alerts-settings', // a unique name for this form
  enableReinitialize: true,
})(MobileAlertsSettings);

export default mobileAlertsSettings;
