import PropTypes from 'prop-types';
import React from 'react';


import MapIcon from '@mui/icons-material/Map';
import IconButton from '@mui/material/IconButton';

import EngineeringIcon from '@mui/icons-material/Engineering';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

import CCoupleAssessmentReportModal from '../../containers/CCoupleAssessmentReportForm/CCoupleAssessmentReportForm';
// import { getDateForExcelExports } from '../../actions/Utilities/dateUtils';

const showTechnicianReport = JSON.parse(process.env.REACT_APP_SHOW_TECHNICIAN_REPORT);

export default function TableActions(props, context) {
  const { type, exportProject, exportAll, exportAllHistory, exportSensorsMaintenance, customOnClick, user } = props;

  // const useGSheet = useGoogleSheet();
  // const [isloadingGoogleSheet, setIsloadingGoogleSheet] = React.useState(false);

  // React.useEffect(() => {
  //   if (!useGSheet.isApiLoaded) {
  //     useGSheet.init();
  //   }
  // }, [useGSheet]);

  const className = "table-actions " + type;

  const hanldeExportToGoogleSheet = async () => {
    setIsloadingGoogleSheet(true);
    try {
      const res = await exportProject('json')
      if (res.status) {
        const dataAsTable = jsonToTable(res.data.report);
        // console.log('dataAsTable', dataAsTable);
        const dateAndTime = new Date().toLocaleString().replace(/,/g, '').replace(/\//g, '-').replace(/:/g, '-');
        const fileName = `${res.data.fileName.split('.')[0]} ${dateAndTime}.xlsx`;

        const writeFileResponse = await useGSheet.writeFile(fileName, 'Report', dataAsTable)
        setIsloadingGoogleSheet(false);

        props.notify(context.t('exported_to_google_sheet_successfully'), 'success', {
          label: context.t('open_google_sheet'),
          callback: () => {
            window.open(writeFileResponse.spreadsheetUrl);
          }
        });
      }
    } catch (error) {
      // console.log('error', error);
      setIsloadingGoogleSheet(false);
    }
  }

  return (
    <div className={className} >
      {type !== 'iQuarius' && type !== 'pressure' && type !== 'mobileAlerts' &&
        <>
          {/* {
            isloadingGoogleSheet ?
            <Rings
              height="30"
              width="30"
              color={'#6090B1'}
              wrapperStyle={{ float: 'left' }}
            />
            :
            useGSheet.isReady && !useGSheet.isAuthenticated ?
              <button onClick={useGSheet.authenticate} className="btn btn-link table-actions-export-auth-google" title={context.t('Export to Google Sheets - Connect')} />
              :
              <button onClick={hanldeExportToGoogleSheet} className="btn btn-link table-actions-export-google-cloud" title={context.t('Export to Google Sheets - Export Sheet')} />
          } */}
          <button onClick={exportProject} className={"btn btn-link table-actions-export " + type} title={context.t('export_to_excel')} />
        </>
      }

      {
        type === 'alerts' &&
        <button
          onClick={exportAllHistory}
          // className={"btn btn-link table-actions-export " + type}
          className="btn btn-link table-actions-export-alerts-history"
          title={context.t('export_alerts_history')}
        />
      }
      {/* {
        type === 'noiseAlerts' &&
        <button onClick={exportProject}
          // className="btn btn-link table-actions-export"
          className={"btn btn-link table-actions-export " + type}
          title={context.t('export_noise_alerts_history')}
        />
      } */}
      {
        type === 'sensors' &&
        <button onClick={exportSensorsMaintenance} className='btn btn-link table-actions-export-sensors-maintenance' title={context.t('sensors_maintenance')} />
      }
      {
        type === 'sensors' && props.path === 'sensors' &&
        <IconButton
          onClick={() => props.loadSensorsActivityData()}
          title={context.t('sensors_activity_report')}
        >
          <PermDataSettingIcon />
        </IconButton>
      }
      {
        showTechnicianReport && type === 'sensors' && props.path === 'sensors' && user.technicianAdvanced &&
        <IconButton
          onClick={() => props.loadTechnicianData()}
          title={context.t('technician_report')}
        >
          <EngineeringIcon />
        </IconButton>
      }
      {
        type === 'couples' &&
        <IconButton
          title={context.t('export_shp_file')}
          onClick={() => props.exportCouplesShapeFile()}
        >
          <MapIcon />
        </IconButton>

      }
      {type === 'assessments' &&
        <CCoupleAssessmentReportModal
          getMapCapture={props.getMapCapture}
        />
      }
      {type === 'pressure' &&
        <button
          onClick={exportProject}
          className={"btn btn-link table-actions-export " + type}
          title={context.t('export_alerts_to_excel')} />
      }
      {type === 'mobile' &&
        <IconButton
          title={context.t('iq_100b_reports')}
          onClick={() => props.loadIQ100BReportsData()}
        >
          <PermDataSettingIcon />
        </IconButton>
      }
      {false && type === 'pressure' &&
        <button
          onClick={exportAll}
          className='btn btn-link table-actions-export-alerts-history'
          title={context.t('export_all_transients_to_excel')} />
      }
    </div>
  );

}

//@TODO write props types...
//TableActions.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};
TableActions.contextTypes = {
  t: PropTypes.func.isRequired
};

TableActions.propTypes = {
  exportAll: PropTypes.func.isRequired,
  exportProject: PropTypes.func.isRequired
};


