import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import * as alertsActions from '../../actions/AlertsActions';
import { getAddressByLatLong } from '../../actions/GlobalsActions';
import AddAlertForm from '../../components/AlertsSettings/AddAlertForm';
const _ = require('lodash');

const getInitialValues = (options) => {
  let initialValues = null;

  if (options && !_.isEmpty(options)) {
    const dateNow = new Date();
    const dateNowString = dateNow.toISOString().split('T')[0];

    const defultABC = options.ABC.find((optionItem, index, allOptions) => optionItem.label == 'A');
    const defultAlertState = options.AlertState.find((optionItem, index, allOptions) => optionItem.value == 6); //'Fixed'
    const defultAlertType = options.AlertType.find((optionItem, index, allOptions) => optionItem.label == 'Leak');

    initialValues = {
      DetectionDateTime: dateNowString,
      fixDate: dateNowString,
      ABC: defultABC.value,
      AlertType: defultAlertType.value,
      AlertState: defultAlertState.value,
      Coordinate: '',
      Probability: 0
    };
  }

  return (initialValues);
};

const mapStateToProps = (state, ownProps) => {
  const user = state.leaksList.user;
  const optionList = state.leaksList.optionList.options;
  const { WorkAreas = [], ContractorID = [], PCARepairCodesEnum = [], AlertState = [], AlertType = [], ABC = [] } = optionList;

  _.remove(ABC, (opt) => opt.label == 'D');

  const alertStatuses = AlertState.filter((status) => {
    return status.label == 'Fixed' || status.label == 'To be Fixed';
  });

  const selectedProjectId = state.leaksList.selectedProject;
  let projectCouples = [];
  if (state.leaksList.leaksByProject[selectedProjectId] && state.leaksList.leaksByProject[selectedProjectId].couples) {
    projectCouples = state.leaksList.leaksByProject[selectedProjectId].couples.couples;
  }

  const couplesIdsData = projectCouples.map((couple, index, array) => {
    const coupleId = couple.id;
    const address1 = couple.Address1;
    const address2 = couple.Address2;
    return {
      label: `${coupleId}:  ${address1} / ${address2}`,
      value: coupleId
    };
  });

  let initialValues = getInitialValues(optionList);

  return Object.assign({}, {
    user,
    options: {
      WorkAreas,
      ContractorID,
      PCARepairCodesEnum,
      AlertState,
      AlertType,
      ABC
    },
    couplesIdsData,
    initialValues
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doClose: () => {
      ownProps.showAddAlertForm(false);
    },
    onSubmit: (values, dispatch, props) => {
      const requiredFields = ['ABC', 'DetectionDateTime', 'AlertType', 'AlertState', 'Coordinate'];

      requiredFields.forEach((fieldKey) => {
        if (values[fieldKey] == null || values[fieldKey] === '') {
          throw new SubmissionError({
            [fieldKey]: 'Require',
            _error: fieldKey + ' is Require'
          });
        }
      });
      if (values.Coordinate == null || values.Coordinate.length == 0) {
        throw new SubmissionError({
          Coordinate: 'Require',
          _error: 'No Coordinate'
        });
      } else {
        dispatch(alertsActions.createNewAlert(values));
      }
    },
    getAddressByLatLong: () => {
      dispatch(getAddressByLatLong('add-alert', 'StreetAddress'));
    },
    onCreateNewValue: (valueKey, value) => {
      dispatch(alertsActions.addLeakOption(valueKey, value));
    }
  };
};

const CAddAlert = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAlertForm);

export default CAddAlert;
