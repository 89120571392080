import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../components/TextCell/TextCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

class ProjectsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      goToRow: 0,
    };
    //this.goToRow = this.goToRow.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { manageItem, items, showDetails } = this.props;
    if (manageItem != null && manageItem.item != null) {
      if (prevProps.manageItem == null || prevProps.manageItem.item == null || manageItem.item.ID !== prevProps.manageItem.item.ID || showDetails !== prevProps.showDetails || items.length !== prevProps.items.length) {
        const selectedRow = items.filter(function (item) {
          return item.ID == manageItem.item.ID;
        })[0];
        this.setState({
          goToRow: items.indexOf(selectedRow),
        });
      }
    }
  }

  render() {
    const { items, pHeight, pWidth, rowClickEvent, sortProp, rowClassNameGetter, optionList } = this.props;
    const parsedItems = items.map(item =>{
      const option = optionList.options.ProjectMode.find (option => option.value === item.PilotMode)
      return  {
        ...item,
        PilotModeLabel: option?.label
      }
    })
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={parsedItems.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={rowClassNameGetter}
          scrollToRow={this.state.goToRow}
          {...this.props}
        >
          <Column
            columnKey="ShortName"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('Customer Name')}
            cell={<TextCell data={parsedItems} field="ShortName" />} />
          <Column
            columnKey="SensorActivityRatioDaysBack"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('ID')}
            cell={<TextCell data={parsedItems} field="SensorActivityRatioDaysBack" />} />

          <Column
            columnKey="PilotModeLabel"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('Name')}
            cell={<TextCell data={parsedItems} field="PilotModeLabel" />} />
          <Column
            columnKey="CustomerName"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('Customer Name')}
            cell={<TextCell data={parsedItems} field="CustomerName" />} />

        </Table>

        {false && parsedItems && parsedItems.length > 0 &&
          <CTableActions
            type="alerts"
            notify={this.props.notify}
          />
        }
        {parsedItems && parsedItems.length > 0 &&
          <span style={totalItemsStyle}>{this.props.t('total2')} {parsedItems.length}</span>
        }
      </div>
    );
  }
}

ProjectsTable = TableMixin(ProjectsTable, null);
export default ProjectsTable;
