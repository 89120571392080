/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';

import CSOPsTab from '../../containers/CSopsTab/CSOPsTab';
import CSOPsOperationsTab from '../../containers/CSopsTab/CSOPsOperationsTab';

const classNames = require('classnames');
const _ = require('lodash');

export default class SOPsTabs extends React.Component {

  constructor(props) {
    super();

    this.state = {
      nTabIndex: 0,
      bShowInstallTab: true,
    };

    this.closePanel = this.closePanel.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { installMode, showDetails, featureType, selectedFeature } = nextProps;
    if (!_.isEmpty(installMode.newInstallItem)) {
      if (!showDetails) {
        this.props.toggleDetailsVisibility(true);
      }
      // @TODO Interference
    } else if (featureType == 'sop' || featureType == 'sensor') {
      if (selectedFeature !== "") {
        if (!showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (showDetails) {
        this.props.toggleDetailsVisibility(false);
      }
    }

    const bNextShowInstallTab = selectedFeature != null && selectedFeature != "";
    let nNextTabIndex = this.state.nTabIndex;
    if (bNextShowInstallTab != this.state.bShowInstallTab) {
      if (bNextShowInstallTab == false) {
        nNextTabIndex = 0;
      } else if (this.state.bNextShowInstallTab == false) {

        nNextTabIndex = 1;
      }
      this.setState({
        bShowInstallTab: bNextShowInstallTab,
        nTabIndex: nNextTabIndex,
      });
    }
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.selectSample({});
    this.props.clearSelectedFeature();
    //this.props.installMode.newInstallItem = null;
  }

  setSelectedTab(selectedIndex) {
    this.setState({
      nTabIndex: selectedIndex,
    });
  }

  render() {
    const {
      selectedFeature,
      uiInfo,
      showDetails,
      notify
    } = this.props;
    const { bShowInstallTab } = this.state;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
      //(isFilesFetching ? 'fetching' : '')
    );

    const panelStyle = { height: 0.493 * uiInfo.pageHeight };
    const title = (this.props.installMode.newInstallItem == null) ? this.context.t('installation_point_id', { id: selectedFeature}) : this.context.t('create_new_installation_point');
    //const bShowInstallTab = selectedFeature != null && selectedFeature != "";

    const tabsList = [];
    if (bShowInstallTab) {
      tabsList.push({
        label: this.context.t('sensor_install'),
        component: <CSOPsOperationsTab notify={notify} />
      });
    }

    tabsList.push({
      label: this.context.t('installation_points'),
      component: <CSOPsTab notify={notify} />
    });

    return (
      <div className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={title}
          handleClose={this.closePanel}
          tabs={tabsList}
        />
      </div>
    );
  }

}

SOPsTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
