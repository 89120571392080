import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setLanguage } from "redux-i18n"
import { LANGUAGE } from '../../constants/SettingsKeys';

require('./UserSettings.scss');

class LangSelect extends React.Component {
  constructor(props, context) {
    super(props);

    // Moved hhere since we need context.
    this.options = [
      {ID: 'en', Name: context.t('english')},
      {ID: 'he', Name: context.t('hebrew')},
    ]

  }

  onChange(lang) {
      this.props.dispatch(setLanguage(lang));
      localStorage.setItem(LANGUAGE, lang);
  }

  render() {

    const { lang } = this.props;
    return (
      <div className="lang-select">
        <select onChange={e => this.onChange(e.target.value)}
                value={lang}>
          {this.options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.Name}
            </option>)
          }
        </select>
      </div>
    );
  }
}

LangSelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  lang: state.i18nState.lang,
}))(LangSelect)
