import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import CommentField from '../CommentField/CommentField';
import Input from '../Input/Input';
import WorkOrderModal from '../WorkOrderModal/WorkOrderModal';
function NoiseAlertDetails(props, context) {
  const {
    selectedFeature,
    alertStateOptions,
    alertTypeOptions,
    user,
    timeZone,
    onEdit,
    onCopy,
    distributionListsOptions,
    handleSubmit,
    error,
    pristine,
    submitting,
  } = props;

  return (
    <div className='form-horizontal sticky-actions'>
      <form
        onSubmit={handleSubmit(props.updateNoiseAlert)}
        style={{ height: '300px' }}
      >
        <CommentField
          fieldName='Comment'
          featureID={selectedFeature}
          placeholder={context.t('alert_comment')}
          onEdit={() => onEdit('noise-alert-form', 'Comment')}
          onCopy={() => onCopy('noise-alert-form', 'Comment')}
          disabled={!user.editMode}
        />

        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {context.t('event_date')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              className='form-control'
              name='CreateDate'
              component={Input}
              type='date'
              timeZone={timeZone}
            />
          </div>
        </div>

        <div className='inline-group'>
          <label className='col-xs-4 control-label'>
            {context.t('end_date')}:
          </label>
          <div className='col-xs-8 control-input'>
            <Field
              className='form-control'
              name='EndDate'
              component={Input}
              type='date'
              timeZone={timeZone}
              disabled
            />
          </div>
        </div>

        <div className='form-actions'>
          <WorkOrderModal
            alertId={selectedFeature}
            sendNotification={props.sendNotification}
            distributionLists={distributionListsOptions}
            getMapCapture={props.getMapCapture}
            contextType='noise'
          />

          <button
            className='btn btn-success btn-submit'
            disabled={pristine || submitting}
            type='submit'
          >
            {context.t('save')}
          </button>
          <div className='form-feedback text-danger'>{error}</div>
        </div>
      </form>
    </div>
  );
}

NoiseAlertDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'noise-alert-form', // a unique name for this form
  enableReinitialize: true,
  // validate
})(NoiseAlertDetails);
