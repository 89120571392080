import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';

export default function
  AuthenticateMfaCode(props) {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState();
  return (
    <div className='login-input-field'>
      { error && <Alert style={{position: 'absolute', top: '-35%', fontSize: 'large'}} severity="error">{'invalid code'}</Alert>}

      <h2 style={{fontSize: '20px', borderBottom: '1px solid'}}>{props.context.t('multifactor_authentication')}</h2>
      <p>{props.context.t('enter_an_mfa_code_to_complete_signin')}</p>
      <label htmlFor="input-mfa-code">{props.context.t('mfa_code')}</label>
      <input id='input-mfa-code' type="text" value={code} onChange={(e) => setCode(e.target.value)}/>
      <div className="form-group form-actions">
        <div className="col-xs-6 col-xs-offset-3 login-field">
          <button
            className="login-submit btn btn-success btn-block"
            onClick={() => {
              setError(undefined);
              props.setLoading(true);
              props.signInFunc(code, (error) => {
                props.setLoading(false);
                setError(error);
              });
            }}>
              {props.context.t('submit')}
          </button>
          </div>
        </div>
      <a href='#' onClick={() => props.backTo('signIn')}>{props.context.t('cancel')}</a>
    </div>
  );
}

AuthenticateMfaCode.propTypes = {
  backTo: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  signInFunc: PropTypes.func.isRequired,
};
