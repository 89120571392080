import * as types from '../constants/ActionTypes';

const initialState = {
  users: [],
  selectedUser: {},
  isFetching: false
}

const management = (state = initialState, action) => {
  switch (action.type) {
    case types.SORT_USERS_LOCALY:
    case types.RECEIVE_USERS:
      return Object.assign({}, state, {
        users: action.users || [],
        usersIndexMap: action.indexMap,
        isFetching: false
      });
    case types.REQUEST_USERS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case types.EDIT_USER:
      return Object.assign({}, state, {
        selectedUser: action.user
      });
    default:
      return state
  }
}

export default management;
