import { connect } from 'react-redux';
import * as actionsAlerts from '../../actions/AlertsActions';
import * as actionsMobileAlerts from '../../actions/MobileAlertsActions';
import { copyComment, editComment, getAddressByLatLong } from '../../actions/GlobalsActions';
import { DRAW_TYPES } from '../../constants/Misc';

import { getPcaForAlert } from "../../actions/actions";
import { setMapDrawMode } from "../../actions/setters";
import MobileDetailsTab from '../../components/Mobile/MobileAlertsTabs/MobileDetailsTab/MobileDetailsTab';

const diff = require('deep-diff').diff;
const _ = require('lodash');

const mapStateToProps = (state, ownProps) => {
  const { selectedProject, user } = state.leaksList;
  var workAreaOptions = state.leaksList.optionList.options.WorkAreas || [];
  var serviceProviderOptions = state.leaksList.optionList.options.ContractorID || [];
  var repairOptions = state.leaksList.optionList.options.PCARepairCodesEnum || [];
  var images = state.leaksList.leaksByProject[selectedProject].images || [];

  const distributionListsCustomers = process.env.REACT_APP_DISTRIBUTION_LISTS_CUSTOMERS === 'true';
  const distributionLists = distributionListsCustomers ? state.distributionLists.lists : state.distributionLists.projectsDLists || [];
  const distributionListsOptions = distributionLists.map((listItem) => ({
    label: distributionListsCustomers ? listItem.Name : listItem.name,
    value: distributionListsCustomers ? listItem.ID : listItem.id,
  }));


  if (selectedProject) {
    const mobile = state.mobile;
    const selectedFeature = mobile.alerts.selectedAlert;
    const selectedAlertObj = mobile.alerts.indexMap[selectedFeature?.alert_id];
    const alertDetails = mobile.alerts?.selectedAlert;

    if (alertDetails != null) {
      const initialValues = Object.assign({}, alertDetails, selectedAlertObj, {
        Comment: alertDetails.comment
      });

      if (initialValues.latitude != null) {
        initialValues.latitude = initialValues.latitude.toFixed(6);
      }
      if (initialValues.longitude != null) {
        initialValues.longitude = initialValues.longitude.toFixed(6);
      }

      return Object.assign({}, {
        timeZone: state.local.timeZone,
        user,
        serviceProviderOptions,
        workAreaOptions,
        repairOptions,
        selectedFeature,
        selectedProject,
        initialValues,
        alertDetails,
        // images,
        distributionListsOptions
      }, ownProps);
    }
  }

  return {
    timeZone: state.local.timeZone,
    workAreaOptions,
    repairOptions,
    serviceProviderOptions,
    user,
    initialValues: null,
    // images
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: (values, dispatch, props) => {

      const differences = diff(values, props.initialValues);
      if (differences != null) {
        let res = [];
        differences.forEach((diff) => {
          res.push({ field: diff.path[0], value: diff.lhs });
        });
        console.log('res in mapdispatch', res);

        dispatch(actionsMobileAlerts.updateMobileAlert(res));
        //dispatch(updateLeakData(selectedProject, row, key, val))
      }
    },

    openPCA: (alertId) => {
      dispatch(getPcaForAlert(alertId));
      dispatch(actionsAlerts.setOpenPCA(true, false));
    },

    openActualLeakLocation: () => {
      dispatch(actionsAlerts.openActualLeakLocation(true));
      dispatch(setMapDrawMode(true, DRAW_TYPES.POINT));
    },

    onEdit: (proxi, object, event) => {
      dispatch(editComment('details', 'Comment'));
      return true;
    },

    onCopy: (proxi, object, event) => {
      dispatch(copyComment('details', 'Comment'));
      return true;
    },

    sendNotification: (distributionListId, image) => {
      dispatch(actionsAlerts.notify(distributionListId, image, (err) => {
        if (err) {
          // console.log(err);
        } else {
          const level = 'success';
          const subject = (distributionListId == 'sms' ? 'SMS' : 'EMail');
          const message = `${subject} sent seccessfully`;
          ownProps.pageNotification(message, level);
        }
      }));
    },


    getAddressByLatLong: (proxi, object, event) => {
      dispatch(getAddressByLatLong('mobile-details', 'address'));
      return true;
    },

    getMapCapture: () => ownProps.getMapCapture()
  };
}

const CMobileDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileDetailsTab);

export default CMobileDetailsTab;
