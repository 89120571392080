import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';
import { SubmissionError } from 'redux-form';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Input from "../Input/Input";
require('./StatusModalSettings.scss');

const classNames = require('classnames');

const isValidCoordinate = (value) => {
  // console.log('Coordinate value:', value);

  if (value) {
    const coords = Array.isArray(value) ? value : value.split(',').map(coord => coord.trim());
    console.log('coords', coords);


    if (coords.length !== 2) {
      return 'Invalid coordinates format';
    }

    const [lat, lon] = coords.map(coord => parseFloat(coord));

    if (
      isNaN(lat) || isNaN(lon) ||
      lat < -90 || lat > 90 ||
      lon < -180 || lon > 180
    ) {
      return 'Invalid coordinates';
    }
  }

  return undefined;
};


class CreateMobileAlertModal extends React.Component {
  constructor(props) {
    super(props);

    // Methods declaration
    this.onClose = this.onClose.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
  }

  // Handling the close dialog action
  onClose() {
    this.props.setOpenStatusDialog(false);
    // console.log('onClose function opened');
  }

  // Handling the save dialog data action
  onSaveData(values, dispatch, props) {
    const { alertState, alertType, alertClassification, comment } = values;
    const requiredFields = ['alertState', 'alertType', 'alertClassification'];

    requiredFields.forEach((fieldKey) => {
      if (values[fieldKey] == null || values[fieldKey] === '') {
        throw new SubmissionError({
          [fieldKey]: 'Require',
          _error: fieldKey + ' is Require',
        });
      }
    });
    const { selectedFeature } = this.props;
    const [taskId] = selectedFeature;
    dispatch(
      this.props.createNewMobileAlert(taskId, {
        ...values,
        samplesIds: this.props.checkedSamples,
      })
    );
    this.props.setOpenStatusDialog(false);

    this.props.resetForm();


  }

  render() {
    const {
      selectedFeature,
      leaksIds,
      handleSubmit,
      onCopy,
      onEdit,
      options,
      isOpen,
    } = this.props;

    const formClass = 'form-group auto-center';
    const labelsClass = 'col-xs-3 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    return (
      <AppModal
        open={isOpen}
        onClose={this.onClose}
        title={this.context.t('add_alert')}
        content={
          <form
            className="form-horizontal sticky-actions"
            onSubmit={handleSubmit(this.onSaveData)}
          >
            <div style={{width: '100%'}}>
              {/* Comment Field */}
              <div className="col-xs-12 text-dir-button">
                <Field
                  name="comment"
                  component="textarea"
                  placeholder={this.context.t('comment')}
                  maxLength={225}
                  className="form-control"
                />
              </div>
              {/* Shadow ('Leak Boss') Field */}
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('alert_state')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertState"
                    component={(props) => (
                      <Select {...props} options={options.AlertStateNoise}/>
                    )}
                  />
                </div>
              </div>
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('alert_type')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertType"
                    component={(props) => (
                      <Select {...props} options={options.AlertType}/>
                    )}
                  />
                </div>
              </div>
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('alert_classification')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertClassification"
                    component={(props) => (
                      <Select
                        {...props}
                        options={options.AudioClassificationEnum}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className={'col-xs-2 control-label'}>{this.context.t('coordinate')}:</label>
                <div className={fieldsClass}>
                  <Field name="Coordinate"
                         placeholder={this.context.t('click_on_map_to_set_coordinate')}
                         component={Input}
                         type="text"
                         className="form-control"
                    // validate={[isValidCoordinates(value[0], value[1])]}
                         validate={[isValidCoordinate]}
                  />
                </div>
              </div>
            </div>
            <Button
              style={{margin: '0 5px'}}
              variant="contained"
              color="primary"
              endIcon={<SendIcon/>}
              type="submit"
            >
              {this.context.t('submit')}
            </Button>
            <Button
              style={{margin: '0 5px'}}
              variant="contained"
              color="secondary"
              endIcon={<CancelIcon/>}
              onClick={this.onClose}
            >
              {this.context.t('cancel')}
            </Button>
          </form>
        }
      />
    );
  }
}

 const validate = (values) => {
   const errors = {};

   return errors;
 }



// StatusModal.propTypes = {
//     onValueChange: PropTypes.func.isRequired,
//     options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
// };

CreateMobileAlertModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const createMobileAlertModal = reduxForm({
  form: 'add-mobile-alert', // a unique name for this form
  enableReinitialize: true,
  validate,
})(CreateMobileAlertModal);

export default createMobileAlertModal;
