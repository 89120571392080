import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AlertsValveSamples from './AlertsValveSamples';

import Paper from '@mui/material/Paper';
import TabsContainer from '../Custom/Tabs/TabsContainer';

import ValveAlertHistory from './ValveAlertHistory';

const AlertsValveTabs = (props, context) => {
  const {
    uiInfo,
    selectedFeature,
    selectedItem,
    featureType,
    showDetails,
    timeZone,
    selectedAlertSample,
    alertStateOptions,
    alertTypeOptions,
    valveAlertHistory,
  } = props;

  const panelStyle = { height: 0.495 * uiInfo.pageHeight };

  const panelClasses = classNames('alert-details', { show: showDetails });

  function closePanel() {
    props.toggleDetailsVisibility(false);
    props.clearSelectedFeature();
  }

  React.useEffect(() => {
    if (selectedItem?.id) {
      props.toggleDetailsVisibility(true);
    } else {
      props.toggleDetailsVisibility(false);
    }
  }, [featureType, selectedItem, showDetails]);

  return (
    <Paper className={panelClasses} style={panelStyle}>
      <TabsContainer
        title={context.t('sensor_history', { alertId: selectedFeature })}
        handleClose={closePanel}
        tabs={[
          {
            label: context.t('samples'),
            component: (
              <AlertsValveSamples
                selectedFeature={selectedItem}
                selectedAlertSample={selectedAlertSample}
                timeZone={timeZone}
                uiInfo={uiInfo}
                options={props.options}
                samples={props.valveAlertsSamples}
                notify={props.notify}
              />
            ),
          },
          {
            label: context.t('history'),
            component: (
              <ValveAlertHistory
                options={alertStateOptions}
                history={valveAlertHistory}
                timeZone={timeZone}
                context={context}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

AlertsValveTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlertsValveTabs;
