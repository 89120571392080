import { GRAPHS as types } from '../constants/ActionTypes';

const initialState = {
  rawData: {},
  params: { freq: {}, factor: 40 },
  distancesData: [],
  distancesDataSensorsOnly: [],
  sensorsPositions: [],
  maxProbabilityPositions: [],
  maxAveCorrelation: [],
  histogramInstance: {},
  histogramAve: [],
  correlationInstance: [],
  correlationAve: [],
  bias: {},
  signals: [],
  trend: {},
  radioCorrilation: {},
  history: [],
  historyNoiseData: [],
  maxProbabilityGraphs: [{}, {}, {}],
};

function graphs(state = initialState, action) {
  switch (action.type) {
    case types.CLEAN:
      return initialState;

    case types.SET_RAW_DATA:
      return {
        ...state,
        rawData: action.payload.rawData,
        params: action.payload.params,
        bias: action.payload.bias,
      };

    case types.SET_GRAPH_DISTANCES_DATA:
      return { ...state, distancesData: action.payload };

    case types.SET_GRAPH_DISTANCES_DATA_SENSORS_ONLY:
      return { ...state, distancesDataSensorsOnly: action.payload };

    case types.SET_SENSORS_POSITIONS:
      return { ...state, sensorsPositions: action.payload };

    case types.SET_MAX_PROBABILITIES:
      return { ...state, maxProbabilityPositions: action.payload };

    case types.SET_INSTANCE_HISTOGRAM:
      return { ...state, histogramInstance: action.payload };

    case types.SET_AVE_HISTOGRAM:
      return { ...state, histogramAve: action.payload };

    case types.SET_INSTANCE_CORRELATION:
      return { ...state, correlationInstance: action.payload };

    case types.SET_AVE_CORRELATION:
      return { ...state, correlationAve: action.payload };

    case types.SET_BIAS:
      return { ...state, bias: action.payload };

    case types.SET_SIGNALS:
      return { ...state, signals: action.payload };

    case types.SET_TREND:
      return { ...state, trend: action.payload };

    case types.SET_RADIO_CORRELATION:
      return { ...state, radioCorrilation: action.payload };

    case types.SET_HISTORY:
      return { ...state, history: action.payload };

    case types.SET_HISTORY_NOISE_DATA:
      return { ...state, historyNoiseData: action.payload };

    case types.SET_MAX_PROB_GRAPH: {
      const { index, data } = action.payload;
      const graphs = [...state.maxProbabilityGraphs];
      graphs[index] = data;
      return { ...state, maxProbabilityGraphs: graphs };
    }

    default: return state;
  }
}

export default graphs;
